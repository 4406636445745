import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { useNavigate } from '@/router';

import { useOverlayType } from './useOverlayType';

export const useOverlayState = () => {
  const { url } = useRouteMatch();
  const navigate = useNavigate();
  const location = useLocation();
  const overlayType = useOverlayType();

  const closeOverlay = React.useCallback(() => {
    if (!overlayType) {
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    const overlayParamsKeys = Array.from(searchParams.keys()).filter((key) =>
      key.startsWith('overlay-')
    );

    for (const key of overlayParamsKeys) {
      searchParams.delete(key);
    }

    navigate.push(
      {
        pathname: url,
        search: searchParams.toString(),
      },
      null,
      { removeOldQueryParams: true, removeOldState: false }
    );
  }, [location, navigate, overlayType, url]);

  return React.useMemo(() => {
    return {
      showOverlay: !!overlayType,
      closeOverlay,
    };
  }, [closeOverlay, overlayType]);
};
