// @ts-strict-ignore
import { useRouteMatch } from 'react-router-dom';

import type { RouteParam } from '@/types';

import { useGetBoard } from './boards-hooks';
import { useGetChannel } from './query/useChannel';
import { useGetPublicSource } from './query/usePublicSource';

export const useGetParams = () => {
  const { params } = useRouteMatch<RouteParam>([
    '/:category/:source/:subCategory',
  ]) || { params: { category: '', subCategory: '', source: '' } };
  return params;
};

export const useGetSourceFromId = () => {
  const { category, source } = useGetParams();

  const { getBoard } = useGetBoard();
  const { getChannel } = useGetChannel();
  const { getPublicSource } = useGetPublicSource();

  switch (category) {
    case 'needlbox':
      return { id: source, name: source };
    case 'apps': {
      const publicSource = getPublicSource(source);

      if (publicSource) {
        return { id: publicSource.key, name: publicSource.label }; // public apps
      }
      return { id: source, name: source }; // private apps
    }
    case 'feeds': {
      const board = getBoard(source);
      return { id: board.board_id, name: board.board_name };
    }
    case 'channels': {
      const channel = getChannel(source);
      return { id: channel.id, name: channel.name };
    }
    case 'notebooks': {
      return { id: source, name: 'Notebook feed' };
    }
    default:
      return { id: 'home', name: 'home' };
  }
};
