// @ts-strict-ignore
import { Button } from '@/components/Button/Button';
import { Modal, ModalBody, ModalFooter } from '@/components/Modal/Modal';
import { ModalHeader } from '@/components/Modal/ModalHeader';
import { ContactSalesButton } from '@/features/Pricing/components';
import type {
  PricingFeatureKeys,
  PricingModalContentType,
} from '@/features/Pricing/hooks/useFetchMessaging';
import { PricingCTA } from '@/features/Pricing/hooks/useFetchMessaging';
import { useFetchStripeCustomerPortal } from '@/features/Pricing/hooks/useFetchStripeCustomerPortal';
import { SETTINGS_FEEDS_ROUTES, SETTINGS_ROUTES } from '@/nav-routes';
import { useNavigate } from '@/router';
import { multipleStringHighlights } from '@/utils/multipleStringHighlight';

export const PricingModal = ({
  data,
  feature,
  showModal,
  closeModal,
}: PricingModalType) => {
  const navigate = useNavigate();
  const { isLoading, refetch } = useFetchStripeCustomerPortal({
    mixpanelLocation: feature,
  });

  const { header, body, footer_cta, subtext, highlighted_terms } = data;

  const handleClick = () => {
    if (footer_cta === PricingCTA.upgrade) {
      refetch();
    } else if (footer_cta === PricingCTA.storage) {
      navigate.push(SETTINGS_ROUTES.STORAGE);
      closeModal();
    } else {
      navigate.push(SETTINGS_FEEDS_ROUTES.ROOT);
      closeModal();
    }
  };

  const buttonText =
    footer_cta === PricingCTA.upgrade
      ? 'Upgrade'
      : footer_cta === PricingCTA.storage
      ? 'Storage'
      : 'Feed Manager';

  const formatedSubtext = multipleStringHighlights(subtext, highlighted_terms);

  return (
    <Modal
      ariaLabel='pricing-modal'
      onDismiss={closeModal}
      showDialog={showModal}
      closeOnOutsideClick
    >
      <ModalHeader title={header} onDismiss={closeModal} />
      <ModalBody className='flex flex-col gap-y-2'>
        <p className='sub-heading-sm-bold text-gray-900'>{body}</p>
        <p className='body-xs text-gray-700'>{formatedSubtext}</p>
      </ModalBody>
      <ModalFooter>
        <Button variant='secondary' size='small' onClick={closeModal}>
          Cancel
        </Button>
        {buttonText === 'Upgrade' ? (
          <ContactSalesButton size='small' />
        ) : (
          <Button
            variant='primary'
            size='small'
            onClick={handleClick}
            loading={isLoading}
          >
            {buttonText}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

type PricingModalType = {
  data: PricingModalContentType;
  showModal: boolean;
  closeModal: () => void;
  feature: PricingFeatureKeys;
};
