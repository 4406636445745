import * as React from 'react';

export const UploadContext = React.createContext<UploadContextType | null>(
  null
);

export const useUploadContext = () => {
  const value = React.useContext(UploadContext);

  if (!value) {
    throw new Error('useUploadContext can only be used inside UploadProvider');
  }

  return value;
};

export type UploadContextType = {
  addUploadFiles: (files: FileList, location: UploadLocation) => void;
  clearMap: () => void;
  retryUpload: () => void;
  setFileStatus: (id: string, status: FileStatusType) => void;
  uploadStatus: UploadStatusType;
  isLoading: boolean;
};

export type FileStatusType =
  | 'DONE'
  | 'FAIL'
  | 'PENDING'
  | 'RETRY'
  | 'TOO_LARGE'
  | 'UNSUPPORTED';

export type UploadStatusType = 'FAIL' | 'PENDING' | 'UPLOADED' | 'UPLOADING';

export type UploadLocation = 'CONNECT' | 'MY_DATA' | 'NEEDL_DRIVE';
