import { PlaceholderCard } from '@/features/ChatBotV2/components/PlaceholderCard';
import { useAskNeedlContext } from '@/features/ChatBotV2/context';
import type { MessagingType } from '@/features/ChatBotV2/hooks/useFetchAskNeedlMessaging';
import {
  PLACEHOLDER_ASK_NEEDL_MESSAGES,
  useFetchAskNeedlMessaging,
} from '@/features/ChatBotV2/hooks/useFetchAskNeedlMessaging';
import { getLogo } from '@/utils/getIcons';

const DEFAULT_MESSAGING_TYPE = 'all';
const FEED_MESSAGING_TYPE = 'feed';
const DOCUMENT_MESSAGING_TYPE = 'document';

export const Placeholder = ({ handleAskQuestion }: PlaceholderType) => {
  const {
    data: { searchWithin, currentFeedOrDocumentId },
  } = useAskNeedlContext();

  const { data: messages, isLoading, isError } = useFetchAskNeedlMessaging();
  const StarGradient = getLogo('ask_needl_star_gradient');

  let messagesType: MessagingType = DEFAULT_MESSAGING_TYPE;

  if (
    searchWithin === 'feed' &&
    currentFeedOrDocumentId !== 'needl-universe' &&
    currentFeedOrDocumentId != 'home'
  ) {
    messagesType = FEED_MESSAGING_TYPE;
  }

  if (searchWithin === 'document') {
    messagesType = DOCUMENT_MESSAGING_TYPE;
  }

  const handleClick = ({ message }: ClickHandlerType) => {
    handleAskQuestion(message);
  };

  if (isError) {
    return null;
  }

  return (
    <div className='flex flex-col justify-center items-center gap-12 h-full'>
      <div className='w-6 h-6 text-black'>
        <StarGradient />
      </div>
      <div className='flex flex-wrap justify-center items-center gap-5'>
        {isLoading
          ? PLACEHOLDER_ASK_NEEDL_MESSAGES[messagesType]?.map(
              (message, index) => (
                <PlaceholderCard
                  key={index}
                  handleClick={() => null}
                  message={message}
                />
              )
            )
          : messages &&
            messages[messagesType]?.map((messageInfo) => (
              <PlaceholderCard
                key={messageInfo.question}
                handleClick={() =>
                  handleClick({ message: messageInfo.question })
                }
                message={messageInfo}
              />
            ))}
      </div>
    </div>
  );
};

type PlaceholderType = {
  handleAskQuestion: (message: string) => void;
};

type ClickHandlerType = {
  message: string;
};
