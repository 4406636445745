import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

import { AnimationLoader } from './Animation';

type LazyLoadingFallbackProps = {
  className?: string;
  growToFill?: boolean;
  variant?: 'circular' | 'sparkle';
};

export type LazyLoadingSpinnerVarient = 'circular' | 'sparkle';

export const LazyLoadingFallback = ({
  className = '',
  growToFill = true,
  variant = 'circular',
}: LazyLoadingFallbackProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleResize() {
      if (ref.current && growToFill) {
        const { top, height } = ref.current.getBoundingClientRect();
        ref.current.style.height = `${window.innerHeight - top - height}px`;
      }
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [growToFill]);

  return (
    <div
      ref={ref}
      className={`grid w-full place-content-center col-span-full ${className}`.trim()}
    >
      {variant === 'sparkle' ? <AnimationLoader /> : <CircularProgress />}
    </div>
  );
};
