import type { Configuration } from '@azure/msal-browser';
import { LogLevel } from '@azure/msal-browser';

const getAuthorities = (): string[] => {
  const tenants = import.meta.env.VITE_AZURE_TENANT_ID || '';

  const authorities = tenants
    .trim()
    .split(',')
    .map((tenant_id: string): string => {
      return `https://login.microsoftonline.com/${tenant_id.trim()}`;
    });

  return authorities;
};

const getAuthConfigAzure = (): Configuration => {
  const authConfig: Configuration = {
    auth: {
      clientId: import.meta.env.VITE_AZURE_CLIENT_ID,
      redirectUri: import.meta.env.VITE_OAUTH_REDIRECT_SIGNOUT,
      postLogoutRedirectUri: import.meta.env.VITE_WEB_URL,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Trace,
        loggerCallback: (level, message) => {
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };

  const authorities = getAuthorities();

  if (authorities.length > 1) {
    authConfig.auth.knownAuthorities = authorities;
  } else if (authorities.length === 1) {
    authConfig.auth.authority = authorities[0];
  }
  return authConfig;
};

export { getAuthConfigAzure };
