import * as React from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import { CONNECT_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import type { NotificationTypes } from '@/constants/notificationTypes';

export type NotificationActionType = 'RETRY';

export type NotificationJsonDataType = {
  /**
   * notification action path
   */
  action_path?: string;
  /**
   * notification action text
   */
  action_text?: string;
  /**
   * notification message
   */
  message?: string;
  /**
   * notification message title
   */
  message_title?: string;
  /**
   * notification type
   */
  notification_type?: string;
  /**
   * notification alert type
   */
  alertType?: string;
  /**
   * if notification is dismissable
   */
  dismissable?: boolean;
  /**
   * if notification is of an alert type
   */
  isAlert?: boolean;
  /**
   * notitification icon
   */
  notificationIcon?: React.ReactNode;
  /**
   * highlight terms
   */
  highlightTerms?: string[];
  /**
   * comment document in string format
   */
  comment?: string;
  /**
   * name of the comment creator
   */
  commentCreator?: string;
  /**
   * source of the card for which the comment was created
   */
  source?: string;
  /**
   * category name to which card belongs
   */
  category_tab?: string;
  /**
   * feed name on which comment was made
   */
  feedName?: string;
  /**
   * file name for table extraction notification
   */
  fileName?: string;
  /**
   * channel id (required for fetching document to download)
   */
  channel_id?: string;
  /**
   * board id (required for fetching document to download)
   */
  board_id?: string;
  /**
   * document deeplink
   */
  deeplink?: string;
  /**
   * icon mapping (pricing notification)
   */
  icon?: string;
  /**
   * note id (summary notification)
   */
  note_id?: string;
  /**
   * notebook id (summary notification)
   */
  notebook_id?: string;
  /**
   * cta url
   */
  cta_url?: string;
  /**
   * error true if summarisation fails
   */
  error?: boolean;
  /**
   * access key (required for fetching document to download)
   */
  public_access_key?: string;
  /**
   * action type
   */
  action_type?: NotificationActionType;
  /**
   * notification's feature
   */
  feature?: string;
  /**
   * comment id
   */
  comment_id?: string;
  /**
   * suggestion ids
   */
  suggestion_ids?: string;
};

export type NotificationResponseType = {
  /**
   * json data for notification ui
   */
  json_data: NotificationJsonDataType;
  /**
   * notification id
   */
  notification_id: number;
  /**
   * notification read status
   */
  read_status: boolean;
  /**
   * notification source
   */
  source: string;
  /**
   * notification timestamp
   */
  unix: number;
  /**
   * notification type (APP or COMMENT or PRICING or NEEDLDRIVE or SUMMARIZATION)
   */
  notification_type: NotificationTypes;
  /**
   * access key (required for redirection)
   */
  access_key?: string;
};

type UseNotificationsDataType = {
  /**
   * notifications data
   */
  data: NotificationResponseType[][];
  /**
   * is query loading?
   */
  isLoading: boolean;
  /**
   * is fetching data?
   */
  isFetching: boolean;
  /**
   * is fetching next page?
   */
  isFetchingNextPage: boolean;
  /**
   * has next page?
   */
  hasNextPage: boolean;
  /**
   * method to fetch next page data
   */
  fetchNextPage: () => void;
};

const fetcher = async ({ pageParam = { page: 1 } } = {}) => {
  const { data } = await axiosInstance.get<{
    results: NotificationResponseType[];
  }>(CONNECT_API_ROUTES.notification(), {
    params: { ...pageParam },
  });

  return data.results;
};

export const QUERY_KEY = ['notification'];

export const useInvalidateNotifications = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY);
  }, [queryClient]);
};

export const useNotificationsData = (): UseNotificationsDataType => {
  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [...QUERY_KEY],
    (ctx) => {
      return fetcher(ctx);
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage && lastPage.length > 0
          ? {
              page: pages.length + 1,
            }
          : undefined;
      },
      staleTime: 0,
    }
  );

  return {
    data: data?.pages ?? [],
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage: hasNextPage ?? false,
  };
};

/**
 * @todo: add back prefetching (only prefetch the notification status)
 */
export const usePrefetchNotificationsData = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(
      QUERY_KEY,
      async () => {
        const data = await fetcher();

        return { pages: [data] };
      },
      {
        staleTime: 0,
      }
    );
  }, [queryClient]);
};
