import * as React from 'react';

import { formatForShare } from '@/components/ChatBot/utils';
import type {
  AskNeedlState,
  SearchWithinType,
} from '@/features/ChatBotV2/context';
import type { SessionState } from '@/features/ChatBotV2/types';

import type { AskNeedlResponseType } from './useAskNeedl';

export const useAskNeedlState = (initialState: AskNeedlState) => {
  return React.useReducer(askNeedlReducer, initialState);
};

export const askNeedlReducer = (
  state: AskNeedlState,
  action: AskNeedlActions
) => {
  switch (action.type) {
    case 'SET_SEARCH_WITHIN':
      return {
        ...state,
        searchWithin: action.payload,
      };

    case 'SET_SESSION': {
      const { newSession, searchWithin, id } = action.payload;

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [searchWithin]: {
            [id]: newSession as SessionState,
          },
        },
      };
    }

    case 'SET_CURRENT_FEED_OR_DOCUMENT_ID': {
      const { searchWithin, currentFeedOrDocumentId } = action.payload;

      return {
        ...state,
        searchWithin,
        currentFeedOrDocumentId,
        sessions: {
          ...state.sessions,
          [searchWithin]: {
            ...state.sessions[searchWithin],
            [currentFeedOrDocumentId]: state.sessions[searchWithin][
              currentFeedOrDocumentId
            ] || {
              messages: [],
            },
          },
        },
      };
    }

    case 'SET_SESSION_AFTER_COMPLETE': {
      const { botMessageId, prompt, askNeedlData } = action.payload;
      const currentFeedOrDocumentId = state.currentFeedOrDocumentId;
      const searchWithin = state.searchWithin;

      const botMessageIndex = state.sessions[searchWithin][
        currentFeedOrDocumentId
      ].messages.findIndex(
        (message) =>
          message.prompt === prompt &&
          message.type === 'bot' &&
          message.id === botMessageId
      );

      const answerFragments = askNeedlData.generated_answer.sentences.reduce(
        (acc: string[], sentenceInfo) => {
          const { sentence, citations } = sentenceInfo;
          return [
            ...acc,
            sentence,
            ...citations.map((citation) => {
              const urlSearchParams = new URLSearchParams();
              if (citation.document_id) {
                urlSearchParams.append('document_id', citation.document_id);
                urlSearchParams.append('access_key', citation.access_key);
                urlSearchParams.append('source', citation.source);
                urlSearchParams.append('category_tab', citation.category_tab);
              } else if (citation.url) {
                urlSearchParams.append(
                  'link',
                  encodeURIComponent(citation.url)
                );
              }

              urlSearchParams.append('title_label', citation.title_label);
              urlSearchParams.append('source_label', citation.source_label);
              urlSearchParams.append(
                'content_only',
                citation.content_only ||
                  'Click the "View Source" button to view the original content.'
              );
              urlSearchParams.append(
                'highlight_indexes',
                JSON.stringify(citation.highlight_indexes || [])
              );
              urlSearchParams.append(
                'context',
                encodeURIComponent(citation.context)
              );

              const route = citation.document_id ? '/preview' : '/redirect';
              return `[${
                citation.id
              }](${`${route}?${urlSearchParams.toString()}`})`;
            }),
          ];
        },
        []
      ) as string[];

      const answerMarkdown = answerFragments.join(' ');

      if (botMessageIndex !== -1) {
        return {
          ...state,
          sessions: {
            ...state.sessions,
            [searchWithin]: {
              ...state.sessions[searchWithin],
              [currentFeedOrDocumentId]: {
                ...state.sessions[searchWithin][currentFeedOrDocumentId],
                sessionId: askNeedlData.session_id,
                messages: state.sessions[searchWithin][
                  currentFeedOrDocumentId
                ].messages.map((message) => {
                  if (message.id === botMessageId) {
                    return {
                      ...message,
                      data: askNeedlData,
                      streamingAnswer: '',
                      answerMarkdown,
                      formattedData: formatForShare(
                        prompt,
                        askNeedlData,
                        'text'
                      ),
                    };
                  }
                  return message;
                }),
              } as SessionState,
            },
          },
        };
      }

      return state;
    }

    case 'SET_SESSION_WHILE_STREAMING': {
      const { botMessageId, prompt, streamingAnswer } = action.payload;
      const session = state.searchWithin;
      const currentFeedOrDocumentId = state.currentFeedOrDocumentId;
      const botMessageIndex = state.sessions[session][
        currentFeedOrDocumentId
      ].messages.findIndex(
        (message) =>
          message.prompt === prompt &&
          message.type === 'bot' &&
          message.id === botMessageId
      );

      if (botMessageIndex !== -1) {
        return {
          ...state,
          sessions: {
            ...state.sessions,
            [session]: {
              ...state.sessions[session],
              [currentFeedOrDocumentId]: {
                ...state.sessions[session][currentFeedOrDocumentId],
                messages: state.sessions[session][
                  currentFeedOrDocumentId
                ].messages.map((message) => {
                  if (message.id === botMessageId) {
                    return {
                      ...message,
                      streamingAnswer,
                    };
                  }
                  return message;
                }),
              } as SessionState,
            },
          },
        };
      }
      return state;
    }

    case 'SET_SESSION_ON_CLEAR': {
      return {
        ...state,
        sessions: {
          ...state.sessions,
          [state.searchWithin]: {
            ...state.sessions[state.searchWithin],
            [state.currentFeedOrDocumentId]: {
              ...state.sessions[state.searchWithin][
                state.currentFeedOrDocumentId
              ],
              messages: [],
              sessionId: undefined,
              promptEnabled: false,
              searchWithin: state.searchWithin,
            } as SessionState,
          },
        },
      };
    }

    case 'SET_SESSION_ON_QUESTION': {
      const prompt = action.payload as string;
      const userMessageId = crypto.randomUUID() as string;
      const botMessageId = crypto.randomUUID() as string;

      return {
        ...state,
        sessions: {
          ...state.sessions,
          [state.searchWithin]: {
            ...state.sessions[state.searchWithin],
            [state.currentFeedOrDocumentId]: {
              ...state.sessions[state.searchWithin][
                state.currentFeedOrDocumentId
              ],
              messages: [
                ...state.sessions[state.searchWithin][
                  state.currentFeedOrDocumentId
                ].messages,
                {
                  type: 'user',
                  id: userMessageId,
                  botMessageId: botMessageId,
                  prompt,
                },
                {
                  type: 'bot',
                  id: botMessageId,
                  data: null,
                  formattedData: '',
                  prompt,
                  streamingAnswer: '',
                },
              ],
            } as SessionState,
          },
        },
      };
    }

    default:
      return state;
  }
};

export type AskNeedlActions =
  | {
      type: 'SET_CURRENT_FEED_OR_DOCUMENT_ID';
      payload: {
        searchWithin: SearchWithinType;
        currentFeedOrDocumentId: string;
      };
    }
  | {
      type: 'SET_SEARCH_WITHIN';
      payload: SearchWithinType;
    }
  | {
      type: 'SET_SESSION_AFTER_COMPLETE';
      payload: {
        botMessageId: string;
        prompt: string;
        askNeedlData: AskNeedlResponseType;
      };
    }
  | {
      type: 'SET_SESSION_ON_CLEAR';
      payload: {
        searchWithin: SearchWithinType;
      };
    }
  | {
      type: 'SET_SESSION_ON_QUESTION';
      payload: string;
    }
  | {
      type: 'SET_SESSION_WHILE_STREAMING';
      payload: {
        botMessageId: string;
        prompt: string;
        streamingAnswer: string;
      };
    }
  | {
      type: 'SET_SESSION';
      payload: {
        newSession: SessionState;
        searchWithin: SearchWithinType;
        id: string;
      };
    };

export const ASK_NEEDL_ACTIONS = {
  SET_SEARCH_WITHIN: 'SET_SEARCH_WITHIN',
  SET_SESSION: 'SET_SESSION',
  SET_SESSION_AFTER_COMPLETE: 'SET_SESSION_AFTER_COMPLETE',
  SET_SESSION_WHILE_STREAMING: 'SET_SESSION_WHILE_STREAMING',
  SET_SESSION_ON_CLEAR: 'SET_SESSION_ON_CLEAR',
  SET_SESSION_ON_QUESTION: 'SET_SESSION_ON_QUESTION',
  SET_CURRENT_FEED_OR_DOCUMENT_ID: 'SET_CURRENT_FEED_OR_DOCUMENT_ID',
} as const;
