import * as React from 'react';

type UseHandleClickOutsideArgs<T> = {
  neglectElement?: React.MutableRefObject<T | null>;
  callback?: () => void;
};

export const useHandleClickOutside = <T extends HTMLElement>({
  neglectElement,
  callback,
}: UseHandleClickOutsideArgs<T>) => {
  const ref = React.useRef<T | null>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const commonCheck = () =>
        ref.current && !ref.current.contains(event.target as Node);

      if (neglectElement && neglectElement.current) {
        if (
          commonCheck() &&
          !neglectElement.current.contains(event.target as Node)
        ) {
          event.preventDefault();
          event.stopPropagation();
          callback?.();
        }
      } else {
        if (commonCheck()) {
          event.preventDefault();
          event.stopPropagation();
          callback?.();
        }
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [callback, neglectElement]);

  return { ref };
};
