import * as React from 'react';

/**
 * https://github.com/mui-org/material-ui/blob/5fc45752d8f9583cc782ae04065845c9772509ff/packages/material-ui-utils/src/setRef.ts#L16
 */
export const setRef = <T>(
  ref:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  value: T | null
): void => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

/**
 * ref
 * https://github.com/mui-org/material-ui/blob/5fc45752d8f9583cc782ae04065845c9772509ff/packages/material-ui-utils/src/useForkRef.ts#L4
 */
export const useForkRef = <Instance>(
  refA: React.Ref<Instance> | null | undefined,
  refB: React.Ref<Instance> | null | undefined
): ((ref: Instance) => void) | null => {
  /**
   * This will create a new function if the ref props change and are defined.
   * This means react will call the old forkRef with `null` and the new forkRef
   * with the ref. Cleanup naturally emerges from this behavior.
   */
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
};
