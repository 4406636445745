// @ts-strict-ignore
import * as React from 'react';

import { axiosInstance } from '@/axios';
import { axiosWithRetryInstance } from '@/axiosWithRetry';
// import { LazyLoadingFallback } from '@/components/Loaders/LazyLoadingFallback';
import type {
  PricingFeatureKeys,
  PricingModalContentType,
} from '@/features/Pricing/hooks/useFetchMessaging';
import {
  PRICING_FEATURES,
  useFetchMessaging,
  useInvalidatePricingMessaging,
} from '@/features/Pricing/hooks/useFetchMessaging';
import { PricingModal } from '@/features/Pricing/PricingModal';
import { useFeatureFlag } from '@/hooks/query/useFeatureFlag';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import { useDevices } from '@/hooks/useDevices';

export const PricingContext = React.createContext<PricingContextType | null>(
  null
);

export const usePricingContext = () => {
  const value = React.useContext(PricingContext);

  if (!value) {
    throw new Error(
      'usePricingContext must be used within a PricingContextProvider'
    );
  }

  return value;
};

export const PricingContextProvider: React.FunctionComponent<
  PricingContextProviderType
> = ({ children }) => {
  const [pricingFeature, setPricingFeature] =
    React.useState<PricingFeatureKeys | null>(null);

  const { data: features, isLoading: isFeaturesLoading } = useFeatureFlag();
  const { data: messaging, isLoading: isMessagingLoading } =
    useFetchMessaging();
  const invalidatePricingMessaging = useInvalidatePricingMessaging();

  const { native } = useDevices();
  const { mobile } = useResponsiveConstants();

  const isLoading = isMessagingLoading || isFeaturesLoading;
  const data =
    !isLoading && pricingFeature ? messaging?.[pricingFeature]?.message : null;
  const isMobile = mobile || native;
  const showModal = !isMobile && !isLoading && features?.is_pricing && data;

  const getActionInfo = React.useCallback(
    (pricingFeature: PricingFeatureKeys) => {
      if (!isFeaturesLoading && !features?.is_pricing) {
        return {
          isEligible: true,
          message: null,
        };
      }

      if (messaging && !(pricingFeature in messaging)) {
        throw new Error(`Unknown ${pricingFeature}`);
      }

      return {
        isEligible: messaging?.[pricingFeature]?.isEligible,
        message: messaging?.[pricingFeature]?.message,
      };
    },
    [features, isFeaturesLoading, messaging]
  );

  const withPricing = React.useCallback(
    (
      pricingFeature: PricingFeatureKeys,
      callback: () => void,
      options?: { event?: React.MouseEvent<HTMLElement, MouseEvent> }
    ) => {
      if (!features?.is_pricing || messaging?.[pricingFeature]?.isEligible) {
        callback();
      } else {
        if (options && options.event) {
          options.event.preventDefault();
        }
        setPricingFeature(pricingFeature);
      }
    },
    [features, messaging]
  );

  const withClick = React.useCallback(
    (pricingFeature: PricingFeatureKeys) => (callback: () => void) => {
      if (!features?.is_pricing || getActionInfo(pricingFeature).isEligible) {
        callback();
      } else {
        setPricingFeature(pricingFeature);
      }
    },
    [features, getActionInfo]
  );

  const PRICING_VIEW_KEYS = React.useMemo(
    () => ({
      clipped_reports: withClick(PRICING_FEATURES.view_clipped),
      view_notebook: withClick(PRICING_FEATURES.view_notebook),
      needldrive: withClick(PRICING_FEATURES.view_needldrive),
    }),
    [withClick]
  );

  const value = React.useMemo(
    () => ({
      withPricing,
      getActionInfo,
      withClick,
      PRICING_VIEW_KEYS,
      isLoading,
    }),
    [PRICING_VIEW_KEYS, getActionInfo, withClick, isLoading, withPricing]
  );

  React.useEffect(() => {
    const interceptor = axiosWithRetryInstance.interceptors.response.use(
      (response) => {
        if (response?.status === 200 && response?.config?.method !== 'get') {
          invalidatePricingMessaging();
        }
        return response;
      },
      (response) => Promise.reject(response)
    );

    return () => {
      axiosWithRetryInstance.interceptors.response.eject(interceptor);
    };
  }, [invalidatePricingMessaging]);

  React.useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => {
        if (response?.status === 200 && response?.config?.method !== 'get') {
          invalidatePricingMessaging();
        }
        return response;
      },
      (response) => Promise.reject(response)
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [invalidatePricingMessaging]);

  return (
    <PricingContext.Provider value={value}>
      {children}
      {/* TODO : raise a PR to add this back */}
      {/* {isLoading ? <LazyLoadingFallback /> : children} */}
      {showModal ? (
        <PricingModal
          data={data}
          feature={pricingFeature}
          showModal={!!pricingFeature}
          closeModal={() => setPricingFeature(null)}
        />
      ) : null}
    </PricingContext.Provider>
  );
};

type PricingContextType = {
  withPricing: (
    pricingFeature: PricingFeatureKeys,
    callback: () => void,
    options?: { event?: React.MouseEvent<HTMLElement, MouseEvent> }
  ) => void;
  withClick: (
    pricingFeature: PricingFeatureKeys
  ) => (callback: () => void) => void;
  getActionInfo: (pricingFeature: PricingFeatureKeys) => {
    isEligible: boolean;
    message: PricingModalContentType;
  };
  PRICING_VIEW_KEYS: {
    clipped_reports: (callback: () => void) => void;
  };
  isLoading: boolean;
};

type PricingContextProviderType = {
  children: React.ReactNode;
};
