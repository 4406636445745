import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export type SourceIconType = Omit<
  React.HTMLAttributes<HTMLSpanElement>,
  'css'
> & {
  children?: React.ReactNode;
  /**
   * Size of the Icon
   */
  size?: 'large' | 'medium' | 'small' | 'xs' | 'xxs';
  /**
   * icon fallback component
   */
  icon?: React.ReactNode;
};

const IconContainer = styled.span<Pick<SourceIconType, 'size'>>`
  display: block;
  ${({ size = 'medium' }) => {
    switch (size) {
      case 'xxs':
        return tw`w-3 h-3`;
      case 'xs':
        return tw`w-4 h-4`;
      case 'small':
        return tw`w-5 h-5`;
      case 'medium':
        return tw`w-6 h-6`;
      case 'large':
        return tw`w-8 h-8`;
    }
  }}

  & svg,
  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Icon = React.forwardRef<HTMLSpanElement, SourceIconType>(
  ({ children, icon, size = 'medium', ...rest }, ref) => {
    return (
      <IconContainer ref={ref} size={size} {...rest}>
        {children ? children : icon}
      </IconContainer>
    );
  }
);
