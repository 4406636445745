import * as React from 'react';
import { useParams } from 'react-router-dom';

import type { ChannelType } from '@/features/Channels/common';
import { NEEDL_ORGANIZATION_ID } from '@/features/Channels/common';
import { useFetchChannel } from '@/features/Channels/hooks/useFetchChannel';
import { useFetchChannels } from '@/features/Channels/hooks/useFetchChannels';
import { useFetchOrganization } from '@/features/Channels/hooks/useFetchOrganization';

type RouteParams = {
  channelId?: string;
};

type UsePublishChannelReturnType = {
  showPublishCheckbox: boolean;
  publishTitle: string;
};

export const useGetChannel = () => {
  const { channels, isError, isLoading } = useFetchChannels();

  const getChannel = React.useCallback(
    (channelId: string): ChannelType | undefined => {
      if (isLoading || isError || !channelId) {
        return { id: '', name: '' };
      }
      return channels.find((channel) => channel.id === channelId);
    },
    [channels, isError, isLoading]
  );

  return { getChannel };
};

export const useNewChannel = (channelIdParam = ''): boolean => {
  const { channelId = channelIdParam } = useParams<RouteParams>();
  return channelId === 'create group';
};

export const useIsChannelConfigEditable = (channelId: string): boolean => {
  const isNewChannel = useNewChannel(channelId);
  const { channel } = useFetchChannel(channelId);
  return isNewChannel || channel?.role === 'owner';
};

export const usePublishChannel = (
  channelId: string
): UsePublishChannelReturnType => {
  const isNewChannel = useNewChannel();
  const editable = useIsChannelConfigEditable(channelId);
  const { channel } = useFetchChannel(channelId);
  const { organization, permissions } = useFetchOrganization();

  const showPublishCheckbox =
    (isNewChannel
      ? permissions?.canPublishChannels
      : channel?.publish || (editable && permissions?.canPublishChannels)) ??
    false;

  const channelOrganization = isNewChannel
    ? organization
    : channel?.organization;

  const publishTitle =
    channelOrganization?.id === NEEDL_ORGANIZATION_ID
      ? 'ALL'
      : channelOrganization?.domain || channelOrganization?.name || 'your';

  return {
    showPublishCheckbox,
    publishTitle: showPublishCheckbox ? publishTitle : '',
  };
};
