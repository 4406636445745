import { NEEDL_PULSE_ROUTES } from '@/nav-routes';

const APP_TYPES = {
  APP: 'app',
  PULSE: 'pulse',
};

export const APP_SOURCE_TYPE_CONFIG: AppTypesConfigurationsType = {
  pulse: {
    baseRoute: NEEDL_PULSE_ROUTES.BASE_ROUTE,
    afterLogin: NEEDL_PULSE_ROUTES.PROFILE_PAGE_ROUTE,
    afterLogout: NEEDL_PULSE_ROUTES.SIGN_IN_PAGE_ROUTE,
  },
  app: {
    baseRoute: '/',
    afterLogin: '/',
    afterLogout: '/',
  },
};

export const extractAppType = (): AppType => {
  const featuredAppPath = '/' + window.location.pathname.split('/')[1]; // /app or /pulse

  let appType: AppType = 'app';
  Object.keys(APP_SOURCE_TYPE_CONFIG).forEach((key) => {
    const appTypeConfig = APP_SOURCE_TYPE_CONFIG[key as AppType];
    if (featuredAppPath === appTypeConfig.baseRoute) {
      appType = key as AppType;
    }
  });

  return appType;
};

export const setAppTypeInLS = () => {
  const appType = extractAppType();
  if (appType === APP_TYPES.APP) {
    return;
  }

  localStorage.setItem('appType', appType);
};

export type AppType = 'app' | 'pulse';

type AppTypesConfigurationsType = {
  [key in AppType]: {
    baseRoute: string;
    afterLogin: string;
    afterLogout: string;
  };
};
