import * as React from 'react';

export const useHideHubspot = () => {
  React.useEffect(() => {
    document.body.classList.add('comments-tab-open');

    return () => {
      document.body.classList.remove('comments-tab-open');
    };
  }, []);
};
