import { useMediaQuery } from 'react-responsive';

type ScreenSize = 'lg' | 'md' | 'sm' | 'xl' | 'xxl' | 'xxxl';
type ScreenSizeCSSVariable = `--screen-${ScreenSize}`;

export function getMediaCSSVariable(
  variableName: ScreenSizeCSSVariable
): string {
  return getComputedStyle(document.documentElement).getPropertyValue(
    variableName
  );
}

export function getMediaCSSVariableValue(
  variableName: ScreenSizeCSSVariable
): number {
  const value = getComputedStyle(document.documentElement).getPropertyValue(
    variableName
  );

  return parseInt(value, 10);
}

export const useResponsiveConstants = () => {
  const mobile = useMediaQuery({
    query: `(max-width: ${getMediaCSSVariable('--screen-sm')})`,
  });
  const tabletAndAbove = useMediaQuery({
    query: `(min-width: ${getMediaCSSVariable('--screen-md')})`,
  });
  const tabletAndBelow = useMediaQuery({
    query: `(max-width: ${getMediaCSSVariableValue('--screen-md') - 1}px)`,
  });
  const laptopAndAbove = useMediaQuery({
    query: `(min-width: ${getMediaCSSVariable('--screen-xl')})`,
  });
  const laptopAndBelow = useMediaQuery({
    query: `(max-width: ${getMediaCSSVariableValue('--screen-xl') - 1}px)`,
  });
  const desktop = useMediaQuery({
    query: `(min-width: ${getMediaCSSVariable('--screen-lg')})`,
  });
  const desktopAndBelow = useMediaQuery({
    query: `(max-width: ${getMediaCSSVariableValue('--screen-lg') - 1}px)`,
  });

  const touchScreen = useMediaQuery({ query: '(hover: none)' });

  return {
    mobile,
    tabletAndBelow,
    tabletAndAbove,
    laptopAndAbove,
    laptopAndBelow,
    desktop,
    desktopAndBelow,
    touchScreen,
  };
};
