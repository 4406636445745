// @ts-strict-ignore
import type {
  NavigateHistoryToType,
  NavigateOptionsType,
  NavigateStateType,
} from '@/router';

import { attachOverlayParams } from './attachOverlayParams';
import { getCombinedQueryParams } from './getCombinedQueryParams';

export const extractRouterFields = ({
  to,
  removeOldQueryParams = false,
  removeOldState = true,
  preserveOldQueryParams = false,
  oldQueryParams = '',
  oldState = {},
  newState = {},
}: UseExtractType) => {
  const [pathname, newQueryParams] =
    typeof to === 'string' ? [to, ''] : [to.pathname, to.search];

  const search = removeOldQueryParams
    ? newQueryParams
    : preserveOldQueryParams
    ? getCombinedQueryParams(oldQueryParams, newQueryParams)
    : attachOverlayParams(newQueryParams, oldQueryParams);
  const state = removeOldState ? newState : { ...oldState, ...newState };

  return { pathname, search, state };
};

type UseExtractType = NavigateOptionsType & {
  to: NavigateHistoryToType;
  oldQueryParams?: string;
  oldState?: NavigateStateType;
  newState?: NavigateStateType;
};
