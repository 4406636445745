import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { STRIPE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import { useFeatureFlag } from '@/hooks/query/useFeatureFlag';

const QUERY_KEY = ['STRIPE-MESSAGING'];

export const useFetchMessaging = () => {
  const { data: features, isLoading } = useFeatureFlag();

  return useQuery(QUERY_KEY, fetchPricingMessaging, {
    select: (data) => {
      return data.data.results;
    },
    enabled: !!(!isLoading && features?.is_pricing),
  });
};

export const usePrefetchPricingMessaging = () => {
  const queryClient = useQueryClient();

  const { data: features, isLoading } = useFeatureFlag();

  React.useEffect(() => {
    if (!isLoading && features?.is_pricing) {
      queryClient.prefetchQuery(QUERY_KEY, fetchPricingMessaging, {
        staleTime: timeInMs.FIFTEEN_SECONDS,
      });
    }
  }, [features, isLoading, queryClient]);
};

const fetchPricingMessaging = async ({ signal }: QueryFunctionContext) =>
  await axiosInstance.get<{ results: MessagingType }>(
    STRIPE_API_ROUTES.messaging(),
    {
      signal,
    }
  );

export const useInvalidatePricingMessaging = () => {
  const queryClient = useQueryClient();

  return React.useCallback(async () => {
    await queryClient.cancelQueries(QUERY_KEY);
    return queryClient.invalidateQueries(QUERY_KEY);
  }, [queryClient]);
};

export const PricingCTA = {
  upgrade: 'upgrade',
  storage: 'storage',
} as const;

export const PRICING_FEATURES = {
  view_clipped: 'view_clipped',
  view_notebook: 'view_notebook',
  add_feed: 'add_feed',
  add_notebook: 'add_notebook',
  save_clip: 'save_clip',
  view_needldrive: 'view_needldrive',
  connect_app: 'connect_app',
  add_to_needldrive: 'add_to_needldrive',
  enable_feed: 'enable_feed',
  view_myfeeds: 'view_myfeeds',
} as const;

export const PRICING_SOURCE_MAP = {
  clipped_reports: PRICING_FEATURES.view_clipped,
} as const;

export const SOURCES_UNDER_PRICING = new Set(Object.keys(PRICING_SOURCE_MAP));

type MessagingType = {
  [key in PricingFeatureKeys]: ContentType;
};

export type PricingFeatureKeys = keyof typeof PRICING_FEATURES;

type ContentType = {
  message: PricingModalContentType;
  isEligible: boolean;
};

export type PricingModalContentType = {
  header?: string;
  body: string;
  subtext?: string;
  footer_cta?: keyof typeof PricingCTA;
  highlighted_terms?: string[];
};

export type PricingSources = keyof typeof PRICING_SOURCE_MAP;
