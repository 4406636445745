const BOARDS_BASE_ROUTE = '/boards';

export const BOARDS_API_ROUTES = {
  index: () => BOARDS_BASE_ROUTE,
  indexV2: () => `${BOARDS_BASE_ROUTE}/v2`,
  aiSmarts: () => `${BOARDS_BASE_ROUTE}/ai_smarts`,
  boardChannels: (boardId: string) =>
    `${BOARDS_BASE_ROUTE}/${boardId}/channels`,
  feedChoices: () => `${BOARDS_BASE_ROUTE}/feed_choices`,
  boardSources: (boardId: string) => `${BOARDS_BASE_ROUTE}/${boardId}/sources`,
  boardInfo: (boardId: string) => `${BOARDS_BASE_ROUTE}/${boardId}`,
  boardInfoV2: (boardId: string) => `${BOARDS_BASE_ROUTE}/${boardId}/v2`,
  enable: (feedId: string) => `${BOARDS_BASE_ROUTE}/enable/${feedId}`,
  boardDuplicate: (boardId: string) =>
    `${BOARDS_BASE_ROUTE}/${boardId}/duplicate`,
  previewUpdation: (boardId: string) =>
    `${BOARDS_BASE_ROUTE}/${boardId}/preview`,
  postSuggestion: () => `${BOARDS_BASE_ROUTE}/suggestions`,
  getSuggestions: (boardId: string) =>
    `${BOARDS_BASE_ROUTE}/${boardId}/suggestions`,
  reviewSuggestion: (boardId: string) =>
    `${BOARDS_BASE_ROUTE}/${boardId}/suggestion`,
  getMetadata: (boardId: string) => `${BOARDS_BASE_ROUTE}/${boardId}/metadata`,
} as const;
