import { AxiosError } from 'axios';
import ReactHotToast from 'react-hot-toast';
import type { QueryFunctionContext } from 'react-query';
import { useQuery } from 'react-query';

import { trackEvent } from '@/analytics/trackEvent';
import { STRIPE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

import { PRICING_FEATURES } from './useFetchMessaging';

const QUERY_KEY = 'STRIPE-CUSTOMER-PORTAL';

export const useFetchStripeCustomerPortal = ({
  mixpanelLocation,
}: UseFetchParamsType) =>
  useQuery(
    [QUERY_KEY],
    (params) => fetchStripeCustomerPortal({ ...params, mixpanelLocation }),
    {
      onSuccess: (portal) => {
        window.open(portal.data.results.session_url, '_self');
      },
      enabled: false,
      onError: (error: unknown) => {
        if (error instanceof AxiosError) {
          const errorMessage =
            error?.response?.data?.error ||
            'Something went wrong. Please try again';
          ReactHotToast.error(errorMessage);
        }
      },
    }
  );

const fetchStripeCustomerPortal = async ({
  mixpanelLocation,
  signal,
}: QueryFunctionContext & UseFetchParamsType) => {
  if (mixpanelLocation) {
    const isPricingModal = mixpanelLocation in PRICING_FEATURES;
    const trackEventProperty = `${
      isPricingModal ? 'upgrade' : 'manage'
    }_CTA_pricing`;

    trackEvent(trackEventProperty, { location: mixpanelLocation });
  }

  return await axiosInstance.get<{ results: CustomerPortal }>(
    STRIPE_API_ROUTES.customerPortal(),
    {
      signal,
    }
  );
};

export type CustomerPortal = {
  session_url: string;
};

type UseFetchParamsType = {
  mixpanelLocation?: string;
};
