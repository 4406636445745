import * as yup from 'yup';

import type { AIFeedNavigationType } from './components/FeedTypeModal/hooks/useFeedOptionNavigation';

const PUBLIC_DATA_CATGEGORIES = ['rss_news', 'exchanges'];

const CUSTOMFEED_PAGES = {
  SelectFeedType: 'select-feed-type',
  FeedTemplateReadonly: 'feed-template-readonly',
  FeedTemplateCustomize: 'feed-template-customize',
  CreateFeed: 'create-feed',
  EditFeed: 'edit-feed',
  CreateAIFeed: 'create-ai-feed',
  EditAIFeed: 'edit-ai-feed',
  SelectAIFeedType: 'select-ai-feed-type',
  FeedSuggestion: 'feed-suggestion',
  ReviewFeedSuggestion: 'review-feed-suggestion',
} as const;

type CustomFeedPage = typeof CUSTOMFEED_PAGES[keyof typeof CUSTOMFEED_PAGES];

type CustomFeedParamsType = {
  type: 'custom-feed';
  page: CustomFeedPage;
  'template-id'?: string;
  'board-id'?: string;
  'search-template'?: string;
  'ai-feed-type'?: AIFeedNavigationType;
};

const CustomFeedParamsYupSchema = yup.object<CustomFeedParamsType>().shape({
  type: yup.string().oneOf(['custom-feed']).required(),
  page: yup
    .string<CustomFeedPage>()
    .oneOf(Object.values(CUSTOMFEED_PAGES))
    .required(),
  'template-id': yup.string(),
  'board-id': yup.string(),
  'search-template': yup.string(),
  'suggestion-ids': yup.string(),
  // new params will be added here
});

export { PUBLIC_DATA_CATGEGORIES, CUSTOMFEED_PAGES, CustomFeedParamsYupSchema };
export type { CustomFeedPage, CustomFeedParamsType };
