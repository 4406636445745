/* eslint-disable no-restricted-imports */
import { DialogContent, DialogContentText } from '@material-ui/core';

import { CommonDialog } from '../CommonDialog';
import { Transition } from '../DialogSlideTransition';

export const LoginRedirectDialog = ({
  open,
  handleOk,
}: LoginRedirectDialogType) => {
  return (
    <CommonDialog
      confirmationDialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      handleOk={handleOk}
      title='Session Expired'
      TransitionComponent={Transition}
    >
      <DialogContent>
        <DialogContentText id='confirmation-dialog-description'>
          Your session has expired. Would you like to be redirected to the login
          page?
        </DialogContentText>
      </DialogContent>
    </CommonDialog>
  );
};

type LoginRedirectDialogType = {
  open: boolean;
  handleOk: () => void;
};
