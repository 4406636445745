import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { NOTEBOOK_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import { QUERY_KEY } from '@/features/ManageTags/common';
import type { Notebook } from '@/features/Notes/types';

export type UseFetchNotebooksResponse = {
  results: Notebook[];
};

export const ALL_NOTEBOOKS_QUERY_KEY = [QUERY_KEY, 'all'];

const fetcher = ({ signal }: QueryFunctionContext) =>
  axiosInstance.get<UseFetchNotebooksResponse>(NOTEBOOK_API_ROUTES.list(), {
    signal,
  });

export const useFetchNotebooks = () => {
  return useQuery(ALL_NOTEBOOKS_QUERY_KEY, fetcher, {
    select: (data) => data.data.results,
    staleTime: timeInMs.FIFTEEN_SECONDS,
  });
};

export const useRefetchAllNotebooks = () => {
  const queryClient = useQueryClient();

  return React.useCallback(async () => {
    await queryClient.invalidateQueries(ALL_NOTEBOOKS_QUERY_KEY);
  }, [queryClient]);
};

export const usePrefetchAllNotebooks = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(ALL_NOTEBOOKS_QUERY_KEY, {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};
