import { AUTH_ROUTES } from '@/nav-routes';
import { postLoginMessage } from '@/utils/broadcastMessages';

const isFirstAuthenticatedAppLoad = () => {
  const isFirstAuthenticatedAppLoadDone = sessionStorage.getItem(
    'isFirstAuthenticatedAppLoadDone'
  );
  if (!isFirstAuthenticatedAppLoadDone) {
    sessionStorage.setItem('isFirstAuthenticatedAppLoadDone', 'done');
    return true;
  }
  return false;
};

const getRedirectUri = () => {
  const redirectUri = sessionStorage.getItem('redirectUri');
  if (redirectUri) {
    return redirectUri;
  }
  return null;
};

const clearRedirectUri = () => {
  sessionStorage.removeItem('redirectUri');
};

const storeRedirectUri = () => {
  const fullUri = window.location.href;
  const urlPath = window.location.pathname;
  const existingRedirectUri = sessionStorage.getItem('redirectUri');
  if (existingRedirectUri || urlPath.startsWith(AUTH_ROUTES.BASE_ROUTE)) {
    return;
  }
  sessionStorage.setItem('redirectUri', fullUri);
};

const loadRedirections = (isAuthenticated: boolean) => {
  if (isAuthenticated && isFirstAuthenticatedAppLoad()) {
    const redirectUri = getRedirectUri();
    if (redirectUri) {
      clearRedirectUri();
      window.location.href = redirectUri;
    }
  }
  if (!isAuthenticated) storeRedirectUri();
};

const postAuthMessage = (isAuthenticated: boolean) => {
  const oauthSignInInitiated = localStorage.getItem('oauth-signin');

  if (isAuthenticated && oauthSignInInitiated) {
    localStorage.removeItem('oauth-signin');
    postLoginMessage();
  }
};

export { loadRedirections, postAuthMessage };
