import styled from 'styled-components';
import tw from 'twin.macro';

import { StickyTableHeader } from '@/components/Atoms/StickyTableHeader';
import { Breadcrumb as BreadcrumbImpl } from '@/components/Breadcrumb';

type Tag = {
  tag: string;
  user_tag_id: string;
  count: string;
  isLoading?: boolean;
};

export const QUERY_KEY = 'manage-tags';

const BREADCRUMB_MAPPING = {
  tags: 'Tags Manager',
};

const Container = styled.section`
  ${tw`my-4 space-y-6 col-span-full xl:col-4/-1`}
`;

const ActionHeader = styled(StickyTableHeader)`
  ${tw`top-[161px]`}
`;

const Breadcrumb = (): JSX.Element => (
  <BreadcrumbImpl
    mapping={BREADCRUMB_MAPPING}
    skipPathFragment={['settings']}
  />
);

export type { Tag };
export { ActionHeader, Breadcrumb, Container };
