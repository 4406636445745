import * as React from 'react';

import {
  type AskNeedlActions,
  useAskNeedlState,
} from '@/features/ChatBotV2/hooks/useAskNeedlState';
import type { SessionState } from '@/features/ChatBotV2/types';

export const AskNeedlContext = React.createContext<AskNeedlContextType | null>(
  null
);

export const AskNeedlDispatchContext =
  React.createContext<React.Dispatch<AskNeedlActions> | null>(null);

export const useAskNeedlContext = () => {
  const value = React.useContext(AskNeedlContext);

  if (!value) {
    throw new Error(
      'useAskNeedlContext must be used within a AskNeedlProvider'
    );
  }

  return value;
};

export const useAskNeedlDispatchContext = () => {
  const value = React.useContext(AskNeedlDispatchContext);

  if (!value) {
    throw new Error(
      'useAskNeedlDispatchContext must be used within a AskNeedlProvider'
    );
  }

  return value;
};

export const AskNeedlContextProvider: React.FunctionComponent<
  AskNeedlProviderType
> = ({
  initialState = {
    searchWithin: 'feed' as SearchWithinType,
    currentFeedOrDocumentId: 'home',
    sessions: {
      feed: {
        'needl-universe': {
          messages: [],
        },
        home: {
          messages: [],
        },
        feed_id: {
          messages: [],
        },
      },
      document: {
        document_id: {
          messages: [],
        },
      },
    },
  },
  children,
}) => {
  const [data, dispatch] = useAskNeedlState(initialState);

  const value = React.useMemo(() => ({ data }), [data]);

  return (
    <AskNeedlContext.Provider value={value}>
      <AskNeedlDispatchContext.Provider value={dispatch}>
        {children}
      </AskNeedlDispatchContext.Provider>
    </AskNeedlContext.Provider>
  );
};

export type AskNeedlContextType = {
  data: AskNeedlState;
};

export type AskNeedlState = {
  searchWithin: SearchWithinType;
  currentFeedOrDocumentId: string;
  sessions: {
    [key in SearchWithinType]: {
      [key: string]: SessionState;
    };
  };
};

type AskNeedlProviderType = {
  initialState?: AskNeedlState;
};

export type SearchWithinType = 'document' | 'feed';
