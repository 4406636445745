// @ts-strict-ignore
import { useRouteMatch } from 'react-router-dom';

import { SETTINGS_ROUTES } from '@/nav-routes';

const SETTINGS_HEADERS = {
  storage: 'Needl Storage',
  tags: 'Tags Manager',
  feeds: 'Feeds Manager',
  contacts: 'Manage Contacts',
  portfolios: 'Portfolio/Watchlist',
  'user-settings': 'User Profile',
};

export const useGetSettingsHeader = () => {
  const { params } = useRouteMatch<RouteParam>([SETTINGS_ROUTES.PAGE]) || {
    params: { page: '' },
  };

  return SETTINGS_HEADERS[params.page];
};

type SettingsType =
  | 'contacts'
  | 'feeds'
  | 'portfolios'
  | 'storage'
  | 'tags'
  | 'user-settings';

type RouteParam = {
  page: SettingsType;
};
