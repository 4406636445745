import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { FEATURE_API_ROUTE } from '@/api-routes';
import { axiosInstance } from '@/axios';

const QUERY_KEYS = ['feature_config'];

const FEATURES = {
  /** core_features */
  assistants: 'assistants',
  ask_needl: 'ask_needl',
  search: 'search',

  /** data_management */
  my_data: 'my_data',
  clipped_reports: 'clipped_reports',
  bookmarks: 'bookmarks',
  tags: 'tags',
  notebooks: 'notebooks',
  needldrive: 'needldrive',

  /** connected_sources */
  apps: 'apps',
  websites: 'websites',
  capital_markets: 'capital_markets',

  /** collaboration */
  comments: 'comments',
  group_feeds: 'group_feeds',

  /** others */
  explore_menu: 'explore_menu',
  my_feeds: 'my_feeds',
  needl_feeds: 'needl_feeds',

  //add new features under respective feature groups
};

export const useConfigUI = () => {
  return useQuery(QUERY_KEYS, fetchFeaturesConfig, {
    select: (response) => response.features,
    staleTime: Infinity,
  });
};

export const useFeatureConfigUI = (feature: FeaturesConfigKey) => {
  const { data, isLoading } = useConfigUI();
  return React.useMemo(() => {
    return {
      isLoading,
      feature: data?.[feature] ?? false,
    };
  }, [isLoading, data, feature]);
};

export const usePrefetchConfigUI = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEYS, {
      queryFn: fetchFeaturesConfig,
      staleTime: Infinity,
    });
  }, [queryClient]);
};

const fetchFeaturesConfig = async () => {
  const { data } = await axiosInstance.get<Response>(
    FEATURE_API_ROUTE.config()
  );

  return data;
};

export type FeaturesConfigKey = keyof typeof FEATURES;

export type FeaturesConfigType = {
  [k in FeaturesConfigKey]: boolean;
};

type Response = {
  features: FeaturesConfigType;
};
