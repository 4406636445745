import type { TooltipProps } from '@reach/tooltip';
import { TooltipPopup, useTooltip } from '@reach/tooltip';
import * as React from 'react';
import { animated, useTransition } from 'react-spring';

import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';

const AnimatedTooltip = animated(TooltipPopup);

export const Tooltip = ({ children, ...rest }: TooltipProps): JSX.Element => {
  const [trigger, tooltip, isVisible] = useTooltip();
  const { touchScreen } = useResponsiveConstants();
  const transitions = useTransition(isVisible ? tooltip : false, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      mass: 1,
      tension: 500,
      friction: 50,
    },
  });

  if (touchScreen || !rest.label) {
    return <>{children}</>;
  }

  return (
    <>
      {React.isValidElement(children)
        ? React.cloneElement(children, trigger)
        : children}
      {transitions.map(
        ({ item, key, props: style }) =>
          item && (
            <AnimatedTooltip
              as='div'
              key={key}
              {...tooltip}
              {...(typeof item === 'object' && { item })}
              {...rest}
              style={{
                ...style,
                // styled components wouldn't work :'(
                background: '#2C2C2C',
                borderRadius: '4px',
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.25)',
                color: '#fff',
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.5rem',
                maxWidth: '40vw',
                overflowWrap: 'break-word',
                whiteSpace: 'normal',
                zIndex: 1,
              }}
            />
          )
      )}
    </>
  );
};
