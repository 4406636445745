import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { getAvatarLettersFromName } from '@/utils/getAvatarLettersFromName';

export type AvatarType = {
  size: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  shape: 'circular' | 'rounded';
  image?: string;
  name?: string;
  className?: string;
};

const StyledContainer = styled.div<Pick<AvatarType, 'shape' | 'size'>>`
  ${tw`bg-gray-200 flex items-center justify-center overflow-hidden`}
  ${({ size }) => size === 'xs' && tw`h-6 w-6 `}
  ${({ size }) => size === 'sm' && tw`h-8 w-8 `}
  ${({ size }) => size === 'md' && tw`h-10 w-10 `}
  ${({ size }) => size === 'lg' && tw`h-12 w-12 `}
  ${({ size }) => size === 'xl' && tw`h-14 w-14 `}
  ${({ shape }) => shape === 'circular' && tw`rounded-full `}
  ${({ shape }) => shape === 'rounded' && tw`rounded-md `}
`;
const StyledImage = styled.img<Pick<AvatarType, 'shape' | 'size'>>`
  ${tw`inline-block object-contain object-center`}
  ${({ size }) => size === 'xs' && tw`h-6 w-6 `}
  ${({ size }) => size === 'sm' && tw`h-8 w-8 `}
  ${({ size }) => size === 'md' && tw`h-10 w-10 `}
  ${({ size }) => size === 'lg' && tw`h-12 w-12 `}
  ${({ size }) => size === 'xl' && tw`h-14 w-14 `}
  ${({ shape }) => shape === 'circular' && tw`rounded-full `}
  ${({ shape }) => shape === 'rounded' && tw`rounded-md `}
`;

const TextAvatar = ({
  name,
  size,
  className = '',
}: Pick<AvatarType, 'name' | 'size'> & { className?: string }) => (
  <div
    className={`w-full h-full grid place-content-center bg-secondary-blue-900 text-white ${
      size === 'xs' ? 'body-xs' : 'body-normal'
    } ${className}`}
  >
    {name ? getAvatarLettersFromName(name) : null}
  </div>
);

export const Avatar: React.FunctionComponent<AvatarType> = ({
  size,
  image,
  shape = 'circular',
  children,
  name,
  className,
}) => {
  return (
    <StyledContainer size={size} shape={shape}>
      {image ? (
        <StyledImage
          size={size}
          shape={shape}
          src={image}
          alt='profile picture'
        />
      ) : (
        children || <TextAvatar name={name} size={size} className={className} />
      )}
    </StyledContainer>
  );
};
