import * as React from 'react';

export type LinkTextSize = 'lg' | 'normal' | 'sm' | 'xl' | 'xs' | 'xxl';
export type LinkType = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> & {
  href: string;
  bold?: boolean;
  size?: LinkTextSize;
  shouldStyle?: boolean;
  underline?: boolean;
};

const getFontClass = (size: LinkTextSize, bold?: boolean) => {
  switch (size) {
    case 'xxl':
      return bold ? 'body-xxl-bold' : 'body-xxl';
    case 'xl':
      return bold ? 'body-xl-bold' : 'body-xl';
    case 'lg':
      return bold ? 'body-lg-bold' : 'body-lg';
    case 'normal':
      return bold ? 'body-normal-bold' : 'body-normal';
    case 'sm':
      return bold ? 'body-sm-bold' : 'body-sm';
    case 'xs':
      return bold ? 'body-xs-bold' : 'body-xs';
    default:
      return bold ? 'body-normal-bold' : 'body-normal';
  }
};

export const Link = React.forwardRef<HTMLAnchorElement, LinkType>(
  (
    {
      href,
      shouldStyle = true,
      bold = false,
      size = 'sm',
      children,
      underline = false,
      onClick,
      ...rest
    },
    ref
  ) => {
    return (
      <a
        ref={ref}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className={
          shouldStyle
            ? `text-emerald-900 ${getFontClass(size, bold)} ${
                underline ? 'underline' : 'no-underline hover:underline'
              } `
            : 'no-underline'
        }
        onClick={onClick}
        {...rest}
      >
        {children}
      </a>
    );
  }
);
