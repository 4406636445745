import { Avatar } from '@/components/Avatar';
import { MessageWrapper } from '@/features/ChatBotV2/components/MessageWrapper';
import { useUserData } from '@/hooks/query/useUserData';

export const UserMessage = ({ prompt }: UserMessageType) => {
  const { data: user } = useUserData();
  return (
    <MessageWrapper
      avatar={
        <Avatar
          size='xs'
          shape='circular'
          className='bg-emerald-900'
          {...(user &&
            user.name && {
              name: user.name,
            })}
        />
      }
      prompt={prompt}
    />
  );
};

type UserMessageType = {
  prompt: string;
};
