import styled from 'styled-components';
import type { TwStyle } from 'twin.macro';
import tw from 'twin.macro';

import type { CommonTextdecorationType } from '@/types';

const getSizeClassname = (
  size: CommonTextdecorationType['size'],
  bold: CommonTextdecorationType['bold']
): TwStyle => {
  switch (size) {
    case 'xs':
      return bold ? tw`body-xs-bold` : tw`body-xs`;
    case 'normal':
      return bold ? tw`body-normal-bold` : tw`body-normal`;
    case 'lg':
      return bold ? tw`body-lg-bold` : tw`body-lg`;
    case 'xl':
      return bold ? tw`body-xl-bold` : tw`body-xl`;
    case 'xxl':
      return bold ? tw`body-xxl-bold` : tw`body-xxl`;
    case 'sm':
    default:
      return bold ? tw`body-sm-bold` : tw`body-sm`;
  }
};

const getColorClassname = (
  color: CommonTextdecorationType['color'],
  dark: CommonTextdecorationType['dark']
): TwStyle => {
  switch (color) {
    case 'black':
      return tw`text-black`;
    case 'white':
      return tw`text-white`;
    case 'blue':
      return dark ? tw`text-blue-900` : tw`text-blue-700`;
    case 'emerald':
      return dark ? tw`text-emerald-900` : tw`text-emerald-700`;
    case 'green':
      return dark ? tw`text-green-900` : tw`text-green-700`;
    case 'indigo':
      return dark ? tw`text-indigo-900` : tw`text-indigo-700`;
    case 'pink':
      return dark ? tw`text-pink-900` : tw`text-pink-700`;
    case 'purple':
      return dark ? tw`text-purple-900` : tw`text-purple-700`;
    case 'red':
      return dark ? tw`text-secondary-red-900` : tw`text-secondary-red-700`;
    case 'yellow':
      return dark ? tw`text-secondary-yellow-900` : tw`text-yellow-700`;
    case 'gray':
    default:
      return dark ? tw`text-gray-900` : tw`text-gray-700`;
  }
};

export const CommonTextdecorationStyles = ({
  bold,
  dark,
  color = 'gray',
  size = 'sm',
}: CommonTextdecorationType): TwStyle[] => {
  return [getSizeClassname(size, bold), getColorClassname(color, dark)];
};

export const CommonPreviewGridContainer = styled.div`
  ${tw`h-full relative grid max-w-screen-xxl mx-auto grid-cols-home-container`}
`;
