import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

import { trackEvent } from '@/analytics/trackEvent';
import { Button } from '@/components/Button/Button';
import { UploadInput, useUploadContext } from '@/components/FileUpload';
import { usePricingContext } from '@/features/Pricing/context';
import { PRICING_FEATURES } from '@/features/Pricing/hooks/useFetchMessaging';
import Upload from '@/icons/upload-page.svg?react';

const UploadIcon = styled(Upload)`
  ${tw`text-white fill-current`}
`;

export const FileUploadButton = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { isLoading } = useUploadContext();
  const { withPricing, isLoading: isPricingLoading } = usePricingContext();

  const appRoute = useRouteMatch('/apps/needldrive');
  const myDataRoute = useRouteMatch('/feeds/home/drives');

  if (!(appRoute || myDataRoute)) {
    return null;
  }

  const handleClick = () => {
    withPricing(PRICING_FEATURES.add_to_needldrive, () => {
      trackEvent(
        appRoute
          ? 'needldrive_apppage_upload_clicked'
          : 'needldrive_mydata_upload_clicked'
      );
      inputRef.current?.click();
    });
  };

  return (
    <>
      <Button
        disabled={isLoading || isPricingLoading}
        startIcon={<UploadIcon />}
        solidIcon={true}
        variant='primary'
        size='small'
        onClick={handleClick}
      >
        Upload File(s)
      </Button>
      <UploadInput
        ref={inputRef}
        location={appRoute ? 'NEEDL_DRIVE' : 'MY_DATA'}
      />
    </>
  );
};
