import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { CommonTextdecorationStyles } from '@/atoms/common';

export type IconContainerType = {
  /**
   * is bold?
   */
  bold?: boolean;
  /**
   * custom classes
   */
  className?: string;
  /**
   * color
   */
  color?:
    | 'black'
    | 'blue'
    | 'emerald'
    | 'gray'
    | 'green'
    | 'indigo'
    | 'pink'
    | 'purple'
    | 'red'
    | 'white'
    | 'yellow';
  /**
   * is dark?
   */
  dark?: boolean;
};

export const StyledContainer = styled.div<IconContainerType>`
  ${tw`fill-current text-gray-700`}
  ${({ bold = false, dark = false, color = 'gray' }) => [
    bold && tw`text-gray-900`,
    dark && tw`text-black`,
    ...CommonTextdecorationStyles({ bold, color, dark }),
  ]}
  & > svg,
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const IconContainer: React.FunctionComponent<IconContainerType> = ({
  className,
  children,
  bold,
  color,
  dark,
}) =>
  React.isValidElement(children) ? (
    <StyledContainer
      bold={bold}
      dark={dark}
      color={color}
      className={className}
    >
      {children}
    </StyledContainer>
  ) : (
    <>{children}</>
  );
