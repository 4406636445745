import { useFeatureConfigUI } from '@/hooks/query/useFeatureConfig';
import { useFeature } from '@/hooks/query/useFeatureFlag';
import { useLocationMatch } from '@/hooks/useLocationMatch';

export const useShowAskNeedl = () => {
  const { flag, isLoading } = useFeature('is_ask_needl_v2');
  const { feature, isLoading: isFeatureConfigLoading } =
    useFeatureConfigUI('ask_needl');
  const { notebooksMatch, sourcePreviewMatch } = useLocationMatch();
  const { category } = sourcePreviewMatch?.params ?? {};

  return (
    !isLoading &&
    flag &&
    !notebooksMatch &&
    category !== 'needl_notebooks' &&
    !isFeatureConfigLoading &&
    feature
  );
};
