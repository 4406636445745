import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { ORGANIZATIONS_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import type {
  OrganizationType,
  PermissionsType,
} from '@/features/Channels/common';

type ResponseType = {
  organization: OrganizationType | undefined;
  permissions: PermissionsType | undefined;
};

type ReturnType = ResponseType & {
  isLoading: boolean;
  isError: boolean;
};

const QUERY_KEY = 'organization';
const fetchOrganizationInfo = ({ signal }: QueryFunctionContext) => {
  return axiosInstance.get<ResponseType>(ORGANIZATIONS_API_ROUTES.self(), {
    signal,
  });
};

export const useFetchOrganization = (): ReturnType => {
  const { data, isLoading, isError } = useQuery(
    [QUERY_KEY],
    fetchOrganizationInfo,
    { staleTime: Infinity } // organization data don't get update frequently
  );

  const organization = data?.data?.organization;
  const permissions = data?.data?.permissions;
  return { isLoading, isError, organization, permissions };
};

export const usePrefetchOrganizationInfo = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery([QUERY_KEY], {
      queryFn: fetchOrganizationInfo,
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  }, [queryClient]);
};
