export const isDefined = <T>(value: T | null | undefined): value is T =>
  value !== undefined && value !== null;

export const isString = (value: unknown): value is string =>
  typeof value === 'string';

export const isKey = <T extends Record<string, unknown>>(
  key: unknown,
  obj: T
): key is keyof T => typeof key === 'string' && key in obj;
