import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';

export const TrackRouteChange = () => {
  const location = useLocation();

  React.useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return null;
};
