import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { ONBOARDING_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

export const ONBOARDING_QUERY_KEY = ['onboarding_flags'];

const fetcher = async ({ signal }: QueryFunctionContext) => {
  return axiosInstance.get<OnboardingFlagsResponseType>(
    ONBOARDING_API_ROUTES.flags(),
    {
      signal,
    }
  );
};

export const useFetchUserOnboardingFlags = () => {
  return useQuery(ONBOARDING_QUERY_KEY, fetcher, {
    select: (data) => {
      return {
        showAttributesForm: data.data.show_attributes_form,
        showSearchModal: data.data.show_search_modal,
      };
    },
    staleTime: 1000 * 20,
    cacheTime: 1000 * 20, // allow for prefetch, but don't cache after 20 seconds
  });
};

export const usePrefetchUserOnboardingFlags = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(ONBOARDING_QUERY_KEY, {
      queryFn: fetcher,
      staleTime: 1000 * 20, // 20 seconds
    });
  }, [queryClient]);
};

export const useInvalidateUserOnboardingFlags = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(ONBOARDING_QUERY_KEY, {
      refetchInactive: true,
    });
  }, [queryClient]);
};

export type OnboardingFlagsResponseType = {
  show_attributes_form: boolean;
  show_search_modal: boolean;
};
