let authenticationStatusChannel: BroadcastChannel | null = null;

export const getAuthenticationStatusChannel = () => {
  if (!authenticationStatusChannel) {
    authenticationStatusChannel = new BroadcastChannel(
      'authentication-status-channel'
    );
  }

  return authenticationStatusChannel;
};

export const postLoginMessage = () => {
  const authenticationStatusChannel = getAuthenticationStatusChannel();
  authenticationStatusChannel.postMessage({ action: 'login' });
};

export const postLogoutMessage = () => {
  const authenticationStatusChannel = getAuthenticationStatusChannel();
  authenticationStatusChannel.postMessage({ action: 'logout' });
};
