import { ChatList } from '@/components/ChatBot/components/ChatList';
import {
  BotError,
  BotLoader,
  BotMessage,
  UserMessage,
} from '@/features/ChatBotV2/components/Message';
import { Placeholder } from '@/features/ChatBotV2/components/Placeholder';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import type { SessionState } from '@/features/ChatBotV2/types';

const ChatContainerImpl = ({
  listContainerRef,
  isError,
  isLoading,
  handleAskQuestion,
  sessionState,
}: ChatContainerImplType) => {
  return (
    <ChatList ref={listContainerRef}>
      {sessionState.messages.length === 0 ? (
        <Placeholder handleAskQuestion={handleAskQuestion} />
      ) : (
        <>
          {sessionState.messages.map((message) =>
            message.type === 'user' ? (
              <UserMessage key={message.id} prompt={message.prompt} />
            ) : (
              <>
                {message.error ? (
                  <BotError key={message.id} />
                ) : message.data || message.streamingAnswer ? (
                  <BotMessage
                    key={message.id}
                    streamingAnswer={message.streamingAnswer ?? ''}
                    answerMarkdown={message.answerMarkdown}
                    data={message.data}
                    formattedData={message.formattedData}
                    prompt={message.prompt}
                  />
                ) : null}
              </>
            )
          )}
          {isLoading ? <BotLoader /> : null}
          {isError ? <BotError /> : null}
        </>
      )}
    </ChatList>
  );
};

export const ChatContainer = withAskNeedlStateSlice<
  Record<string, unknown>,
  ChatContainerImplType
>(ChatContainerImpl, (props, state) => ({
  ...props,
  listContainerRef: props.listContainerRef as React.RefObject<HTMLDivElement>,
  isLoading: props.isLoading as boolean,
  isError: props.isError as boolean,
  handleAskQuestion: props.handleAskQuestion as () => void,
  sessionState:
    state.data.sessions[state.data.searchWithin][
      state.data.currentFeedOrDocumentId
    ],
}));

type ChatContainerImplType = {
  listContainerRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  isError: boolean;
  handleAskQuestion: () => void;
  sessionState: SessionState;
};
