// @ts-strict-ignore
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useOverlayContext } from '@/context/OverlayContext';
import { useQueryParams } from '@/hooks/useQueryParams';
import { decodeUrl } from '@/utils/decodeUrl';

import type { ParamsType } from './useFetchQueryParams';

export function useFetchAppParams(): ParamsType {
  const isOverlay = useOverlayContext();
  const [query] = useQueryParams();

  const { category, deeplink, subCategory, previewSource } = useParams<{
    category: string;
    deeplink: string;
    subCategory: string;
    previewSource: string;
  }>();

  const appParams = React.useMemo(
    () => ({
      deeplink: deeplink ?? '',
      board_id: formatQueryParams(query.board_id) ?? '',
      channel_id: formatQueryParams(query.channel_id) ?? '',
      global_channel_id: formatQueryParams(query.global_channel_id) ?? '',
      comments: formatQueryParams(query.comments) ?? '',
      comment_id: formatQueryParams(query.comment_id) ?? '',
      redirect: decodeUrl(formatQueryParams(query.redirect)) ?? undefined,
      source:
        subCategory === 'links' || query.category === 'links'
          ? 'links'
          : previewSource ?? category,
      category_tab: formatQueryParams(query.category_tab) ?? '',
      subCategory: subCategory ?? '',
    }),
    [query, category, deeplink, subCategory, previewSource]
  );

  if (isOverlay) {
    return null;
  }

  return appParams;
}

const formatQueryParams = (param: string[] | string) => {
  return typeof param === 'string' ? param : param?.[0];
};
