import * as yup from 'yup';

type PortfolioParamsType = {
  type: 'portfolio';
};

const PortfolioParamsYupSchema = yup.object<PortfolioParamsType>().shape({
  type: yup.string().oneOf(['portfolio']).required(),
});

const PORTFOLIO_PAGES = {
  CreatePortfolio: 'create-portfolio',
} as const;

export { PortfolioParamsYupSchema, PORTFOLIO_PAGES };
