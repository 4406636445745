import * as React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route, useLocation } from 'react-router-dom';

import { isAuthenticated } from '@/components/AuthProvider';
import { FullPageLazyLoadingFallback } from '@/components/Loaders/FullPageLazyLoadingFallback';
import { usePrefetchInitialData } from '@/hooks/query/usePrefetchInitialData';
import { AUTH_ROUTES } from '@/nav-routes';
import { NeedlRedirect } from '@/router';
import { type AppType, APP_SOURCE_TYPE_CONFIG } from '@/utils/appTypes';
import { nativeEvent } from '@/utils/native-event';

const AuthenticatedWrapperImpl = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  usePrefetchInitialData();

  return <>{children}</>;
};
const AuthenticatedWrapper = React.memo(AuthenticatedWrapperImpl);

export const ProtectedRoute: React.FunctionComponent<ProtectedRoutePropsType> =
  function ({ children, redirectRoute, ...rest }) {
    const [isUserAuthenticated, setIsUserAuthenticated] = React.useState<
      boolean | undefined
    >(undefined);
    const { pathname } = useLocation();

    React.useEffect(() => {
      async function Auth() {
        const isAuth = await isAuthenticated();
        setIsUserAuthenticated(isAuth);

        nativeEvent(['auth', JSON.stringify(isAuth)]);
      }
      Auth();
    }, [pathname]);

    if (isUserAuthenticated === undefined) {
      return <FullPageLazyLoadingFallback />;
    }

    return (
      <Route
        {...rest}
        render={({ location }) => {
          if (isUserAuthenticated) {
            return <AuthenticatedWrapper>{children}</AuthenticatedWrapper>;
          }

          // after aws logout -> aws will redirect to the / then go back to the original page of featured app
          const appType = localStorage.getItem('appType') as AppType;
          if (appType && APP_SOURCE_TYPE_CONFIG[appType]) {
            const redirectLocation =
              APP_SOURCE_TYPE_CONFIG[appType].afterLogout;
            localStorage.removeItem('appType');
            return <NeedlRedirect to={redirectLocation} />;
          }

          return (
            <NeedlRedirect
              to={{
                pathname: redirectRoute || AUTH_ROUTES.SIGNIN,
                ...(!redirectRoute && { state: { from: location } }),
              }}
            />
          );
        }}
      />
    );
  };

type ProtectedRoutePropsType = RouteProps & {
  /**
   * for the new app types, we can be able to configure the default redirect route
   * Eg:- For Needl Pulse, when an unauthenticated user access a protected route page, we will redirect to Needl Pulse Sign In page (not the default app sign in page)
   */
  redirectRoute?: string;
};
