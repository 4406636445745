// @ts-strict-ignore
import * as React from 'react';

import { ChatContainer } from '@/features/ChatBotV2/components/ChatContainer';
import { Footer } from '@/features/ChatBotV2/components/Footer';
import { SaveToNoteButton } from '@/features/ChatBotV2/components/SaveToNoteButton';
import type { SearchWithinType } from '@/features/ChatBotV2/context';
import { useChatPanelLogic } from '@/features/ChatBotV2/hooks/useChatPanelLogic';
import { withAskNeedlStateSlice } from '@/features/ChatBotV2/hooks/withStateSlice';
import type { SessionState } from '@/features/ChatBotV2/types';

const ChatPanelImpl = ({
  currentFeedOrDocumentId,
  searchWithin,
  sessionState,
}: ChatPanelImplType) => {
  const listContainerRef = React.useRef<HTMLDivElement>(null);

  const {
    isLoading,
    isError,
    handleAskQuestion,
    handleClear,
    saveToNoteHandler,
    snipNoteMutation,
  } = useChatPanelLogic({
    currentFeedOrDocumentId,
    searchWithin,
    sessionState,
  });

  return (
    <section className='flex-1 flex flex-col pb-2 overflow-hidden z-10 h-full p-2 max-w-4xl mx-auto'>
      <ChatContainer
        listContainerRef={listContainerRef}
        isLoading={isLoading}
        isError={isError}
        handleAskQuestion={handleAskQuestion}
      />
      {!sessionState.messages.length ||
      sessionState.messages.length < 2 ? null : (
        <SaveToNoteButton
          disabled={isLoading || isError}
          loading={snipNoteMutation.isLoading}
          saveToNoteHandler={saveToNoteHandler}
        />
      )}
      <Footer
        listContainerRef={listContainerRef}
        onSubmit={handleAskQuestion}
        handleClear={handleClear}
        isLoading={snipNoteMutation.isLoading}
        hasMessages={sessionState.messages.length > 0}
      />
    </section>
  );
};

export const ChatPanel = withAskNeedlStateSlice<
  Record<string, unknown>,
  ChatPanelImplType
>(ChatPanelImpl, (props, state) => {
  return {
    ...props,
    searchWithin: state.data.searchWithin,
    currentFeedOrDocumentId: state.data.currentFeedOrDocumentId,
    sessionState:
      state.data.sessions[state.data.searchWithin][
        state.data.currentFeedOrDocumentId
      ],
  };
});

type ChatPanelImplType = {
  currentFeedOrDocumentId: string;
  searchWithin: SearchWithinType;
  sessionState: SessionState;
};
