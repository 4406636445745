import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import type {
  NavigateHistoryToType,
  NavigateOptionsType,
  NavigateStateType,
} from '@/router';
import { extractRouterFields } from '@/router/utils/extractRouterFields';

export const useNavigate = () => {
  const history = useHistory();
  const { search: oldQueryParams, state: oldState } = useLocation();

  const navigateTo = React.useCallback(
    (
      to: NavigateHistoryToType,
      newState: NavigateStateType = {},
      options: NavigateOptionsType = {},
      replace = false
    ) => {
      const { pathname, search, state } = extractRouterFields({
        ...options,
        to,
        oldQueryParams,
        oldState,
        newState,
      });

      if (replace) {
        history.replace({ pathname, search }, state);
      } else {
        history.push({ pathname, search }, state);
      }
    },
    [history, oldQueryParams, oldState]
  );

  const goBack = React.useCallback(() => {
    history.go(-1);
  }, [history]);

  return React.useMemo(() => {
    return {
      push: (
        to: NavigateHistoryToType,
        state?: NavigateStateType,
        options?: NavigateOptionsType
      ) => navigateTo(to, state, options, false),
      replace: (
        to: NavigateHistoryToType,
        state?: NavigateStateType,
        options?: NavigateOptionsType
      ) => navigateTo(to, state, options, true),
      goBack,
    };
  }, [goBack, navigateTo]);
};
