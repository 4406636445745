import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '@/axios';
import type { FacetType } from '@/types';

const QUERY_KEY = 'companies-facets';

const fetchCompanyFilter = ({
  signal,
  country,
}: FetchCompanyFilterParamsType) => {
  return axiosInstance.post<FacetType>(
    '/search/exchanges',
    {
      type: 'filter',
      search_type: 'needl_web',
      num_docs: 0,
      board_id: 'home',
      ...(country && {
        filter: {
          exchanges: {
            Country: [country],
          },
        },
      }),
    },
    {
      signal,
    }
  );
};

export const useFetchCompanyFilter = (country?: string) => {
  return useQuery(
    [QUERY_KEY, country],
    async ({ signal }) => {
      return await fetchCompanyFilter({ country, signal });
    },

    {
      select: (data) => {
        const facets = data.data['facets'];
        return facets?.exchanges?.Companies || [];
      },
      staleTime: Infinity,
    }
  );
};

export const usePrefetchCompanyFilter = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: [QUERY_KEY],
      queryFn: fetchCompanyFilter,
      staleTime: Infinity,
    });
  }, [queryClient]);
};

type FetchCompanyFilterParamsType = {
  signal?: AbortSignal;
  country?: string;
};
