import { useMutation } from 'react-query';

import { ML_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

export const useFeedbackMutation = () => {
  return useMutation(async ({ feedback, message_id }: MutationData) => {
    return axiosInstance.post(ML_API_ROUTES.askNeedlFeedback(), {
      feedback,
      message_id,
    });
  });
};

type MutationData = {
  feedback: string;
  message_id: string;
};
