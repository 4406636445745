export const capitalize = <T extends string>(val: T) => {
  if (!val?.length) {
    return val;
  }
  return `${val.charAt(0).toUpperCase()}${val.slice(1)}`;
};

export const capitalizeWords = <T extends string>(val: T) => {
  return val.split(' ').map(capitalize).join(' ');
};
