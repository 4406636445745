import styled from 'styled-components';
import tw from 'twin.macro';

import { Alert } from '@/atoms/Alerts/Alert';
import { Spinner } from '@/atoms/Spinner';
import { StickyHeader } from '@/atoms/StickyHeader';
import { StickyTableHeader } from '@/atoms/StickyTableHeader';
import { Text } from '@/atoms/Text/Text';
import { Breadcrumb as BreadcrumbImpl } from '@/components/Breadcrumb';

import { useFetchChannels } from './hooks/useFetchChannels';

type CommonMemberType = {
  username: string;
  email_id: string;
  user_id: string;
};

type ChannelMemberType = CommonMemberType & {
  role: string;
  isLoading?: boolean;
  disableActions?: boolean;
};

type ChannelBoardType = {
  board_id: string;
  board_name: string;
};

type BasicChannelType = {
  id: string;
  name: string;
  description?: string;
  share_outside_org?: boolean;
  publish?: boolean;
  created_at?: number;
  isNew?: boolean;
  no_of_members?: number;
  singlefeed?: boolean;
  needl_feed?: boolean;
  role?: 'member' | 'owner';
  group?: 'default' | 'starred';
};

type BasicOrganizationType = {
  id: number;
  name: string;
  domain: string;
  role: string;
  allow_sharing_outside_org: boolean;
  type: string;
};

type ChannelType = BasicChannelType & {
  organization?: BasicOrganizationType;
  members?: ChannelMemberType[];
  boards?: ChannelBoardType[];
  owner?: ChannelMemberType[];
  needl_feed?: boolean;
  is_tag_shared?: boolean;
};

type OrganizationType = BasicOrganizationType & {
  members: CommonMemberType[];
};

type PermissionsType = {
  canPublishChannels: boolean;
  canPublishFeeds: boolean;
};

type BoardChannelType = BasicChannelType & {
  share: boolean;
};

type RouteParams = {
  channelId?: string;
  boardId?: string;
  source?: string;
};

const Breadcrumb = (): JSX.Element => {
  const { channels } = useFetchChannels();

  const channelNames = channels.reduce(
    (acc, channel) => ({
      ...acc,
      [channel.id]: channel.name,
    }),
    {}
  );

  return (
    <BreadcrumbImpl
      mapping={{
        channels: 'Groups',
        members: 'Members',
        feeds: 'Feeds',
        ...channelNames,
      }}
      skipPathFragment={['settings']}
      showRawLabel
    />
  );
};

const PageTitle = styled.h1`
  ${tw`headline-lg-bold text-gray-700`}
`;

const PageHeader = styled.div`
  ${tw`flex items-center justify-between`}
`;

const StyledAlert = styled(Alert)`
  ${tw`mt-8`}
`;

const StyledText = styled(Text)`
  ${tw`mt-8`}
`;

const MessageWrapper = styled.div`
  ${tw`my-4`}
`;

const NavbarContainer = styled.div`
  ${tw`sticky bg-white`}
`;

const StickyContainer = styled(StickyHeader)`
  ${tw`flex flex-col space-y-2`}
`;

const ConfigTableHeaderWrapper = styled(StickyTableHeader)`
  ${tw`top-[190px]`}
`;

const AccessTableHeaderWrapper = styled(StickyTableHeader)`
  ${tw`top-[145px]`}
`;

const ShareTableHeaderWrapper = styled(StickyTableHeader)`
  ${tw`top-[109px]`}
`;

const ShareTableFooterWrapper = styled.div`
  ${tw`sticky bottom-0 bg-white z-10`}
`;

const DEFAULT_ORGANIZATION_ID = 1;
const NEEDL_ORGANIZATION_ID = 2;

export {
  Breadcrumb,
  PageTitle,
  PageHeader,
  StyledAlert,
  StyledText,
  Spinner,
  MessageWrapper,
  NavbarContainer,
  StickyContainer,
  DEFAULT_ORGANIZATION_ID,
  NEEDL_ORGANIZATION_ID,
  ConfigTableHeaderWrapper,
  AccessTableHeaderWrapper,
  ShareTableHeaderWrapper,
  ShareTableFooterWrapper,
};

export type {
  ChannelMemberType,
  ChannelBoardType,
  ChannelType,
  OrganizationType,
  PermissionsType,
  BoardChannelType,
  CommonMemberType,
  RouteParams,
};
