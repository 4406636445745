import type { AxiosResponse } from 'axios';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { axiosInstance } from '@/axios';

type UseSearch = {
  /**
   * API path
   */
  path?: string;
  /**
   * queyr enabled
   */
  enabled: boolean;
  /**
   * query param object
   */
  queryParams?: Record<string, unknown>;
};

export const useFetch = <T extends AxiosResponse>({
  path = '/search',
  enabled,
  queryParams = {},
}: UseSearch): UseQueryResult<T> => {
  return useQuery(
    [path, queryParams],
    ({ signal }) => axiosInstance.post(path, { ...queryParams }, { signal }),
    { enabled }
  );
};
