// @ts-strict-ignore
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as React from 'react';

import { FullPageLazyLoadingFallback } from '@/components/Loaders/FullPageLazyLoadingFallback';
import type { ReactChildrenType } from '@/types';
import { postDebugLogs } from '@/utils/postDebugLogs';

import { getAuthConfigAzure } from './config';

let msalInstance: PublicClientApplication;

let acquireTokenPopupLock = false;

const AuthProviderAzure: React.FunctionComponent<ReactChildrenType> = ({
  children,
}) => {
  const [isAuthInitialized, setIsAuthInitialized] = React.useState(false);

  React.useEffect(() => {
    const initAuth = async () => {
      if (import.meta.env.VITE_CLOUD_PROVIDER === 'azure') {
        msalInstance = new PublicClientApplication(getAuthConfigAzure());
        await msalInstance.handleRedirectPromise();
      }

      setIsAuthInitialized(true);
    };

    initAuth();
  }, []);

  if (!isAuthInitialized) {
    return <FullPageLazyLoadingFallback />;
  }
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

const loginUserAzure = async () => {
  const loginRequest = {
    scopes: ['user.read'],
  };
  await msalInstance.loginRedirect(loginRequest);
};

const lockedAcquireTokenPopup = async () => {
  if (acquireTokenPopupLock) {
    while (acquireTokenPopupLock) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    return await getIdTokenAzure();
  }
  acquireTokenPopupLock = true;

  try {
    const accounts = msalInstance.getAllAccounts();
    const { idToken } = await msalInstance.acquireTokenPopup({
      account: accounts[0],
      scopes: ['user.read'],
    });
    return idToken;
  } catch (e) {
    //previously unhandled
    await postDebugLogs({
      type: 'AZURE_LOGOUT_DEBUG:[lockedAcquireTokenPopup] - acquireTokenPopup',
      error: e,
    });
  } finally {
    acquireTokenPopupLock = false;
  }
};

const getIdTokenAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      const { idToken } = await msalInstance.acquireTokenSilent({
        account: accounts[0],
        scopes: ['user.read'],
      });
      return idToken;
    } catch (e) {
      if (e.name === 'InteractionRequiredAuthError') {
        await postDebugLogs({
          type: 'AZURE_LOGOUT_DEBUG:[getIdTokenAzure] - InteractionRequiredAuthError',
          error: e,
        });
        return await lockedAcquireTokenPopup();
      } else {
        await postDebugLogs({
          type: 'AZURE_LOGOUT_DEBUG:[getIdTokenAzure] - acquireTokenSilent',
          error: e,
        });
        throw e;
      }
    }
  }
};

const isAuthenticatedAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  return accounts.length > 0;
};

const signOutAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    await msalInstance.logoutRedirect({
      account: accounts[0],
    });
  }
};

const forceRefreshTokensAzure = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    try {
      await msalInstance.acquireTokenSilent({
        account: accounts[0],
        scopes: ['user.read'],
        forceRefresh: true,
      });
    } catch (e) {
      if (e.name === 'InteractionRequiredAuthError') {
        await postDebugLogs({
          type: 'AZURE_LOGOUT_DEBUG:[forceRefreshTokensAzure] - acquireTokenSilent - InteractionRequiredAuthError',
          error: e,
        });
        await lockedAcquireTokenPopup();
      } else {
        await postDebugLogs({
          type: 'AZURE_LOGOUT_DEBUG:[forceRefreshTokensAzure] - acquireTokenSilent',
          error: e,
        });
        throw e;
      }
    }
  }
};

export {
  AuthProviderAzure,
  getIdTokenAzure,
  isAuthenticatedAzure,
  signOutAzure,
  forceRefreshTokensAzure,
  loginUserAzure,
};
