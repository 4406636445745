import type { Variants } from 'framer-motion';

export const variants = {
  'fade-in-default': {
    initial: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  } as Variants,
  'fade-in': {
    initial: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  } as Variants,
  'fade-in-fast': {
    initial: {
      opacity: 0,
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.1,
      },
    },
  } as Variants,
  'drop-in': {
    initial: {
      opacity: 0,
      y: '-3%',
    },
    exit: {
      opacity: 0,
      y: '5%',
      transition: {
        mass: 2,
        damping: 50,
        stiffness: 350,
        type: 'spring',
      },
    },
    animate: {
      opacity: 1,
      y: '0%',
      transition: {
        mass: 2,
        damping: 60,
        stiffness: 500,
        type: 'spring',
      },
    },
  } as Variants,
  'layout-right': {
    initial: {
      opacity: 0,
      x: '100%',
      scaleX: 0,
    },
    exit: {
      opacity: 0,
      scaleX: 0,
      x: '50%',
      transition: {
        type: 'spring',
        mass: 2,
        damping: 30,
        stiffness: 400,
      },
    },
    animate: {
      opacity: 1,
      x: '0%',
      scaleX: 1,
      transition: {
        type: 'spring',
        mass: 2,
        damping: 50,
        stiffness: 350,
      },
    },
  } as Variants,
  shrink: {
    initial: {
      scale: 0,
      transformOrigin: 'center center',
    },
    exit: {
      scale: 0,
      transformOrigin: 'center center',
      transition: {
        delay: 0.5,
      },
    },
    animate: {
      scale: 1,
      transformOrigin: 'center center',
      transition: {
        delay: 0.2,
        type: 'spring',
        mass: 2,
        damping: 16,
        stiffness: 100,
      },
    },
  } as Variants,
} as const;

export type VariantKeys = keyof typeof variants;
