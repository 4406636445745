import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { SOURCE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import type { FeedNavDataType, UseFetchNavReturnType } from '@/types';

import { useFeatureFlag } from './useFeatureFlag';

const fetcher = async ({ signal }: QueryFunctionContext) => {
  const { data } = await axiosInstance.get(SOURCE_API_ROUTES.needlBox(), {
    signal,
  });
  return data.sources || [];
};

export const NAVIGATION_NEEDLBOX = ['navigation', 'needlbox'];

const useFetchNeedlboxImpl = (): UseFetchNavReturnType => {
  const { data, isLoading, isError, isFetching, error } = useQuery(
    NAVIGATION_NEEDLBOX,
    fetcher,
    {
      staleTime: timeInMs.FIFTEEN_SECONDS,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
    error,
  };
};

export const useFetchNeedlbox = (): UseFetchNavReturnType => {
  const {
    data: needlBoxData,
    isLoading: isNeedlboxLoading,
    isError,
    isFetching,
    error,
  } = useFetchNeedlboxImpl();
  const { data: features, isLoading: isFeaturesLoading } = useFeatureFlag();

  const data = React.useMemo(() => {
    return needlBoxData?.filter((needlBoxItem: FeedNavDataType) => {
      switch (needlBoxItem.key) {
        case 'comments':
          return features?.is_comments;
        default:
          return true;
      }
    });
  }, [needlBoxData, features]);

  return {
    data,
    isLoading: isNeedlboxLoading || isFeaturesLoading,
    isError,
    isFetching,
    error,
  };
};

export const useInvalidateNeedlbox = (): (() => void) => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(NAVIGATION_NEEDLBOX);
  }, [queryClient]);
};

export const usePrefetchNeedlbox = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(NAVIGATION_NEEDLBOX, {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};
