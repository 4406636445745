// @ts-strict-ignore
import * as React from 'react';

import { IconButton } from '@/components/IconButton/IconButton';
import {
  type ClearButtonType,
  ClearButton,
} from '@/features/ChatBotV2/components/ClearButton';
import {
  AdornmentContainer,
  Input,
} from '@/features/ChatBotV2/components/Input';
import { getLogo } from '@/utils/getIcons';

export const Footer = ({
  listContainerRef,
  isLoading,
  onSubmit,
  handleClear,
  hasMessages,
}: FooterType) => {
  const [showShadow, setShowShadow] = React.useState(false);

  const formRef = React.useRef<HTMLFormElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleTextareResize = React.useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, []);

  // TODO : confirm & clean this up later
  React.useEffect(() => {
    const listContainer = listContainerRef?.current;

    const resizeObserver = new ResizeObserver(() => {
      if (
        listContainer &&
        listContainer.scrollHeight > listContainer.clientHeight
      ) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    });

    const scrollHandler = () => {
      if (
        listContainer &&
        listContainer.scrollHeight <=
          Math.round(listContainer.scrollTop + listContainer.clientHeight)
      ) {
        setShowShadow(false);
      } else {
        setShowShadow(true);
      }
    };

    const mutaionObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          for (const node of mutation.addedNodes) {
            resizeObserver.observe(node as Element);
          }
          for (const node of mutation.removedNodes) {
            resizeObserver.unobserve(node as Element);
          }
        }
      }
    });

    if (listContainer) {
      mutaionObserver.observe(listContainer, { childList: true });
      for (const child of listContainer.children) {
        resizeObserver.observe(child);
      }
      listContainer.addEventListener('scroll', scrollHandler, {
        passive: true,
      });
    }

    return () => {
      resizeObserver.disconnect();
      mutaionObserver.disconnect();
      listContainer?.removeEventListener('scroll', scrollHandler);
    };
  }, [listContainerRef]);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    formRef.current?.reset();
    textareaRef.current?.focus();
    handleTextareResize();
  }, [isLoading, handleTextareResize]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.currentTarget;
    const formData = new FormData(form);
    const prompt = formData.get('botPrompt');

    if (!prompt) {
      return;
    }
    if (typeof prompt !== 'string') {
      return;
    }

    if (textareaRef.current) {
      textareaRef.current.value = '';
    }

    onSubmit(prompt);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      formRef.current?.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
      return;
    }
  };

  const handleInput = () => {
    handleTextareResize();
  };

  const clearButtonProps: ClearButtonType = {
    onClick: () => {
      handleClear();
      if (textareaRef.current) {
        textareaRef.current.value = '';
        textareaRef.current.focus();
      }
    },
    disabled: !hasMessages || isLoading,
  };

  const SendIcon = getLogo('ask_needl_send_icon');

  return (
    <footer
      className={`px-3 py-4 flex-shrink-0 flex flex-col gap-2 transition-shadow duration-300 ease-out ${
        showShadow ? 'shadow-bot-input' : ''
      }`.trim()}
    >
      <div className='flex-1 flex items-start gap-2'>
        <ClearButton {...clearButtonProps} />
        <form className='flex-1' ref={formRef} onSubmit={handleSubmit}>
          <Input
            ref={React.useCallback((ref) => {
              if (ref) {
                ref.focus();
              }
              textareaRef.current = ref;
            }, [])}
            disabled={isLoading}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            placeholder={
              hasMessages ? 'Ask follow up...' : 'Ask me anything...'
            }
            name='botPrompt'
            required
            label='Promt Needl bot'
            id='bot-promt'
            endAdornment={
              <AdornmentContainer>
                <IconButton type='submit' size='small' disabled={isLoading}>
                  <SendIcon
                    className={`absolute right-2 bottom-2 ${
                      isLoading ? 'text-gray-400' : 'text-emerald-900'
                    }`}
                  />
                </IconButton>
              </AdornmentContainer>
            }
          />
        </form>
      </div>
    </footer>
  );
};

type FooterType = {
  isLoading?: boolean;
  onSubmit: (prompt: string) => void;
  hasMessages: boolean;
  handleClear: () => void;
  listContainerRef?: React.RefObject<HTMLDivElement>;
};
