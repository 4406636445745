import { motion } from 'framer-motion';
import type * as React from 'react';
import { styled } from 'twin.macro';

import type { AnimatedNavItemType } from '@/components/Atoms/AnimatedNavLink/common';
import {
  ItemStyles,
  NumberOfTabResult,
  SelectedIndicator,
} from '@/components/Atoms/AnimatedNavLink/common';
import type { NeedlLinkType } from '@/router';
import { NeedlLink } from '@/router';

export type AnimatedNavLinkType = AnimatedNavItemType & NeedlLinkType;

const StyledLink = styled(NeedlLink)<AnimatedNavLinkType>(ItemStyles);

const AnimatedIndicator = motion(SelectedIndicator);

export const AnimatedNavLink: React.FunctionComponent<AnimatedNavLinkType> = ({
  children,
  selected,
  count,
  disabled = false,
  textDark = true,
  replace = false,
  variant,
  to,
  ...rest
}) => {
  return (
    <StyledLink
      {...rest}
      to={to}
      replace={replace}
      selected={selected}
      textDark={textDark}
      disabled={disabled}
      variant={variant}
    >
      {children}
      {count ? <NumberOfTabResult>{count}</NumberOfTabResult> : null}
      {selected ? (
        <AnimatedIndicator
          transition={{ type: 'spring', damping: 50, stiffness: 600, mass: 2 }}
          layoutId='nav-selected'
        />
      ) : null}
    </StyledLink>
  );
};
