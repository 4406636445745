import { useParams } from 'react-router-dom';

import { useFetchQueryParams } from '@/hooks/useFetchQueryParams';
import type { RouteParam } from '@/types';

export const useGetSearchId = () => {
  const { source, category } = useParams<RouteParam>();
  const { channel_id, board_id } = useFetchQueryParams();

  if (channel_id) {
    return {
      board_id: undefined,
      channel_id,
      notebook_id: undefined,
      isDefault: false,
    };
  } else if (board_id) {
    return {
      board_id,
      channel_id: undefined,
      notebook_id: undefined,
      isDefault: false,
    };
  }

  switch (category) {
    case 'feeds':
      return {
        board_id: source,
        channel_id: undefined,
        notebook_id: undefined,
        isDefault: false,
      };
    case 'channels':
      return {
        board_id: undefined,
        channel_id: source,
        notebook_id: undefined,
        isDefault: false,
      };
    case 'notebooks':
      return {
        board_id: undefined,
        channel_id: undefined,
        notebook_id: source,
        isDefault: false,
      };
    default:
      return {
        board_id: 'home',
        channel_id: undefined,
        notebook_id: undefined,
        isDefault: true,
      };
  }
};
