import type { RedirectProps } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router-dom';

import type { NavigateOptionsType, NeedlLinkToType } from '@/router';
import { extractRouterFields } from '@/router/utils/extractRouterFields';

export const NeedlRedirect = ({ to, options = {} }: NeedlRedirectType) => {
  const { search: oldQueryParams, state: oldState } = useLocation();

  const { pathname, search, state } = extractRouterFields({
    ...options,
    to,
    oldQueryParams,
    oldState,
    newState: typeof to === 'string' ? {} : to.state,
  });

  return <Redirect to={{ pathname, search, state }} />;
};

export type NeedlRedirectType = Omit<RedirectProps, 'to'> & {
  to: NeedlLinkToType;
  options?: NavigateOptionsType;
};
