import { useRouteMatch } from 'react-router-dom';

import type { RouteParam } from '@/types';

export const APP_FEED_PAGES = ['/apps/:source/:subCategory'];

export const USER_FEED_PAGES = [
  '/feeds/:source/:subCategory',
  '/feeds/:source',
];

export const CHANNEL_PAGES = [
  '/channels/:source/:subCategory',
  '/channels/:source',
];

export const NEEDL_BOX_PAGES = ['/needlbox/:source/:subCategory'];

const SHOW_DROPDOWN_PAGES = [
  ...APP_FEED_PAGES,
  ...USER_FEED_PAGES,
  ...CHANNEL_PAGES,
  ...NEEDL_BOX_PAGES,
];

export const useHomeFeedMatch = () =>
  useRouteMatch<Pick<RouteParam, 'subCategory'>>('/feeds/home/:subCategory');

export const useUserFeedMatch = () =>
  useRouteMatch<RouteParam>(USER_FEED_PAGES);

export const useChannelFeedMatch = () =>
  useRouteMatch<RouteParam>(CHANNEL_PAGES);

export const useAppFeedMatch = () => useRouteMatch<RouteParam>(APP_FEED_PAGES);

export const useNeedlBoxFeedMatch = () =>
  useRouteMatch<RouteParam>(NEEDL_BOX_PAGES);

export const useNeedlNotebookFeedMatch = () => useRouteMatch('/notebooks');

export const useNeedlNotebookPreviewMatch = () =>
  useRouteMatch('/preview/needl_notebooks');

export const useCurrentFeedOption = () => {
  const routeMatch = useRouteMatch<{ source?: string; subCategory?: string }>(
    SHOW_DROPDOWN_PAGES
  );

  const showCurrentFeedOption = !useHomeFeedMatch() && !!routeMatch;

  return { SHOW_DROPDOWN_PAGES, routeMatch, showCurrentFeedOption };
};
