// @ts-strict-ignore
import moment from 'moment';
import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { BOARDS_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import type { CustomFeedNavDataType } from '@/types';

import { useFeature } from './useFeatureFlag';

export type FeedsResponse = {
  results: Array<CustomFeedNavDataType>;
};

const fetcher = async ({ signal }: QueryFunctionContext) => {
  return await axiosInstance.get<FeedsResponse>(BOARDS_API_ROUTES.index(), {
    signal,
  });
};

export const NAVIGATION_FEEDS = ['navigation', 'feeds'];

export const useFetchFeeds = () => {
  return useQuery(NAVIGATION_FEEDS, fetcher, {
    staleTime: timeInMs.FIFTEEN_SECONDS,
    select: (data) => {
      const results = data.data.results;
      const currentEpoch = moment().unix();
      const currentDate = moment.unix(currentEpoch);

      return results.map((result) => {
        const resultDate = moment.unix(result.created_at);
        return {
          ...result,
          isNew: resultDate.isSame(currentDate, 'day'),
        };
      });
    },
  });
};

export const useInvalidateFetchFeeds = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(NAVIGATION_FEEDS);
  }, [queryClient]);
};

export const usePrefetchFeeds = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(NAVIGATION_FEEDS, {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};

/**
 * filters home feed and disabled feeds (when pricing is enabled)
 */
export const useGetFilteredFeeds = () => {
  const { isLoading: isPricingLoading, flag: isPricingEnabled } =
    useFeature('is_pricing');
  const queryData = useFetchFeeds();

  const filteredFeedData = React.useMemo(() => {
    return queryData.data?.filter(
      (data) =>
        data.board_name !== 'home' && (isPricingEnabled ? data.enabled : true)
    );
  }, [queryData.data, isPricingEnabled]);

  return {
    ...queryData,
    isLoading: isPricingLoading || queryData.isLoading,
    data: filteredFeedData,
  };
};
