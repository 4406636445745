import {
  Button as MaterialButton,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    width: '100%',
    color: (props) => (props.type ? theme.palette.text.primary : 'white'),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const Button = ({
  variant = 'contained',
  color = 'primary',
  disabled = false,
  size = 'medium',
  loading = false,
  children,
  removeMargin = '',
  ...rest
}) => {
  const classes = useStyles({ type: variant !== 'contained' });
  return (
    <div className={[classes.wrapper, removeMargin].join(' ')}>
      <MaterialButton
        variant={variant}
        color={color}
        size={size}
        disabled={disabled}
        className={classes.button}
        {...rest}
      >
        {children}
      </MaterialButton>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,
  removeMargin: PropTypes.string,
};
