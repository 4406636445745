import type { LinkTextSize } from '@/atoms/Link';
import { Link } from '@/atoms/Link';

import { getHttpRegex } from './getHttpRegex';

const isString = (value: unknown): value is string => value !== '';

export const formatLinkString = (
  str: string,
  linkSize?: LinkTextSize,
  linkBold?: boolean
) => {
  const pattern = getHttpRegex();
  const split = str.split(pattern).map((p) => (p === 'www.' ? undefined : p));
  const splitText = split.filter(isString);

  if (splitText.length <= 1) {
    return str;
  }

  const matches = str.match(pattern) ?? [];

  let rollingIndex = 0;

  return splitText.reduce<Array<JSX.Element | string>>(
    (arr, element) =>
      !element
        ? [
            ...arr,
            element,
            <Link
              size={linkSize}
              bold={linkBold}
              href={matches[rollingIndex]}
              key={rollingIndex}
            >
              {matches[rollingIndex++]}
            </Link>,
          ]
        : [...arr, element],
    []
  );
};
