export const getPlatform = () => {
  // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
  if (
    typeof navigator.userAgentData !== 'undefined' &&
    navigator.userAgentData != null
  ) {
    return navigator.userAgentData.platform;
  }
  // Deprecated but still works for most of the browser
  if (typeof navigator.platform !== 'undefined') {
    if (
      typeof navigator.userAgent !== 'undefined' &&
      /android/.test(navigator.userAgent.toLowerCase())
    ) {
      // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
      return 'android';
    }
    return navigator.platform;
  }
  return 'unknown';
};

export const isApple = () => {
  const platform = getPlatform().toLowerCase();

  const isOSX = /mac/.test(platform); // Mac desktop
  const isIOS = ['iphone', 'ipad', 'ipod'].indexOf(platform); // Mac iOs

  return isOSX || isIOS; // Apple device (desktop or iOS)
};

export const isAndroid = () => {
  const platform = getPlatform().toLowerCase();

  return /android/.test(platform); // Android device
};

export const isWindows = () => {
  const platform = getPlatform().toLowerCase();

  return /win/.test(platform); // Windows device
};

export const isLinux = () => {
  const platform = getPlatform().toLowerCase();

  return /linux/.test(platform); // Linux device
};
