import ReactHotToast from 'react-hot-toast';

export const DisableOpenNewTabToast = () => {
  ReactHotToast.error(
    'If the download hasn\'t started or a new tab doesn\'t open, please disable the "Block Pop-ups" option in your device settings!',
    { duration: 5000 }
  );
};

export const OpenNewTabToast = () => {
  ReactHotToast.success('Opening in New Tab :)', { duration: 2000 });
};

export const DownloadToast = () => {
  ReactHotToast.success('Downloading', {
    duration: 2000,
  });
};

export const DownloadToastSuccess = () => {
  ReactHotToast.success('Saved to Pictures/Needl.ai folder', {
    duration: 2000,
  });
};

export const NativeDownloadSuccessToast = () => {
  ReactHotToast.success('Downloaded Successfully', { duration: 2000 });
};

export const DownloadToastError = () => {
  ReactHotToast.error('Download failed', {
    duration: 2000,
  });
};

export const CommonErrorToast = () => {
  ReactHotToast.error('Oops! Something Went Wrong. Please Try Again Later :(');
};

export const LoadingToast = (message: string) => {
  return ReactHotToast.loading(message, {
    duration: 5000,
    style: {
      maxWidth: '100%',
    },
  });
};

export const ClipboardCopyToast = () => {
  ReactHotToast.success('Copied to clipboard');
};

export const SourceFollowToast = (label: string) => {
  return ReactHotToast.success(
    <span>
      <strong>{label}</strong> is successfully followed
    </span>
  );
};

export const SourceUnfollowToast = (label: string) => {
  return ReactHotToast.success(
    <span>
      <strong>{label}</strong> is successfully unfollowed
    </span>
  );
};
