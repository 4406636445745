// @ts-strict-ignore
import * as React from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';
import Lottie from 'react-lottie';

import { Icon } from '@/components/Atoms/Icon';
import { Tooltip } from '@/components/Atoms/Tooltip/Tooltip';
import {
  BotError as BotErrorImpl,
  BotStreamedMessage,
} from '@/components/ChatBot/components/Message/BotMessage/BotStreamedMessage';
import {
  type AskNeedlResponseType,
  type AskNeedlSentenceType,
  useScrollIntoView,
} from '@/components/ChatBot/hooks';
import { useBotMessageHook } from '@/components/ChatBot/hooks/useBotMessageHook';
import { IconButton } from '@/components/IconButton/IconButton';
import { DEFAULT_LOTTIE_OPTIONS } from '@/constants/defaultLottieOptions';
import { Citation } from '@/features/ChatBotV2/components/Citation';
import { MessageWrapper } from '@/features/ChatBotV2/components/MessageWrapper';
import CopyIcon from '@/icons/copy.svg?react';
import LikeIcon from '@/icons/thumbs-up.svg?react';
import { formatLinkString } from '@/utils/formatLinkString';
import { getLogo } from '@/utils/getIcons';

import animationData from './animationData.json';
import { RichMessage } from './RichMessage';

export const BotLoader = () => {
  return <AIMessageContainer prompt={null} animate />;
};

export const BotError = () => {
  return <AIMessageContainer prompt={<BotErrorImpl />} />;
};

export const BotMessage = ({
  streamingAnswer,
  data,
  formattedData,
  prompt,
  answerMarkdown,
}: BotMessageType) => {
  if (streamingAnswer) {
    return (
      <AIMessageContainer
        animate
        prompt={<BotStreamedMessage streamingAnswer={streamingAnswer} />}
      />
    );
  }

  return (
    <AIMessageContainer
      prompt={
        <MessageGroup
          answerMarkdown={answerMarkdown}
          formattedData={formattedData}
          prompt={prompt}
          sentences={
            data?.generated_answer?.sentences as AskNeedlSentenceType[]
          }
          messageId={data?.message_id as string}
        />
      }
    />
  );
};

const MessageGroup = ({
  answerMarkdown,
  formattedData,
  prompt,
  sentences,
  messageId,
}: MessageGroupType) => {
  const {
    handleSubmit,
    handleCopy,
    selectedFeedback,
    setSelectedFeedback,
    feedbackMutation,
  } = useBotMessageHook({ messageId, prompt });

  return (
    <div className='flex flex-col gap-2' ref={useScrollIntoView()}>
      {answerMarkdown ? (
        <RichMessage markdown={answerMarkdown} />
      ) : (
        <p className='text-sm text-gray-900 whitespace-pre-wrap'>
          {sentences?.map((sentence) => (
            <React.Fragment key={sentence.sentence}>
              {formatLinkString(sentence.sentence, 'normal')}
              {sentence.citations?.length > 0
                ? sentence.citations.map((citation) => (
                    <Citation key={citation.id} {...citation} />
                  ))
                : null}
            </React.Fragment>
          ))}
        </p>
      )}
      <div className='flex gap-2'>
        <Tooltip label='Copy'>
          <div>
            <ReactCopyToClipboard
              text={formattedData ?? ''}
              onCopy={handleCopy}
            >
              <IconButton size='small'>
                <CopyIcon className='text-emerald-900' />
              </IconButton>
            </ReactCopyToClipboard>
          </div>
        </Tooltip>
        <form onSubmit={handleSubmit}>
          <div className='flex gap-2'>
            <IconButton
              name='feedback'
              value='CORRECT'
              type='submit'
              size='small'
              disabled={feedbackMutation.isLoading}
              onClick={() => setSelectedFeedback('correct')}
            >
              <LikeIcon
                className={`stroke-emerald-900 ${
                  selectedFeedback === 'correct'
                    ? 'text-emerald-500'
                    : 'text-transparent'
                }`.trim()}
              />
            </IconButton>
            <IconButton
              name='feedback'
              value='INCORRECT'
              type='submit'
              size='small'
              disabled={feedbackMutation.isLoading}
              onClick={() => setSelectedFeedback('incorrect')}
            >
              <LikeIcon
                className={`rotate-180 stroke-emerald-900 ${
                  selectedFeedback === 'incorrect'
                    ? 'text-emerald-500'
                    : 'text-transparent'
                }`.trim()}
              />
            </IconButton>
          </div>
        </form>
      </div>
    </div>
  );
};

const AIMessageContainer = ({
  prompt,
  animate = false,
}: {
  prompt: React.ReactNode;
  animate?: boolean;
}) => {
  const defaultOptions = {
    ...DEFAULT_LOTTIE_OPTIONS,
    animationData: animationData,
  };
  const AskNeedlStarGradient = getLogo('ask_needl_star_gradient');

  return (
    <MessageWrapper
      avatar={
        animate ? (
          <Lottie options={defaultOptions} height={25} width={25} />
        ) : (
          <Icon size='medium'>
            <AskNeedlStarGradient />
          </Icon>
        )
      }
      prompt={prompt}
      variant='bot'
    />
  );
};

type BotMessageType = {
  answerMarkdown?: string;
  streamingAnswer: string;
  data: AskNeedlResponseType | undefined;
  formattedData: string | null;
  prompt: string;
};

type MessageGroupType = {
  answerMarkdown?: string;
  formattedData: string | null;
  prompt: string;
  sentences: AskNeedlSentenceType[];
  messageId: string;
};
