import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const UploadProviderImpl = React.lazy(() => import('./UploadController'));

export const UploadProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary fallbackRender={() => <>{children}</>}>
      <React.Suspense fallback={null}>
        <UploadProviderImpl>{children}</UploadProviderImpl>
      </React.Suspense>
    </ErrorBoundary>
  );
};
