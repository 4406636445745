const STRIPE_BASE_ROUTE = '/stripe';

export const STRIPE_API_ROUTES = {
  baseRoute: () => STRIPE_BASE_ROUTE,
  messaging: () => `${STRIPE_BASE_ROUTE}/messaging`,
  limitStorage: () => `${STRIPE_BASE_ROUTE}/limit/storage`,
  customer: () => `${STRIPE_BASE_ROUTE}/customer`,
  customerPortal: () => `${STRIPE_BASE_ROUTE}/customer-portal`,
  updatePortal: () => `${STRIPE_BASE_ROUTE}/update-subscription`,
  addPaymentPortal: () => `${STRIPE_BASE_ROUTE}/add-payment`,
} as const;
