// @ts-strict-ignore
import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { axiosInstance } from '@/axios';
import { useBoardFromURL } from '@/hooks/useBoardFromURL';
import type { CategoryTabsDataType, CategoryTabsResponseType } from '@/types';

const QUERY_KEYS = ['chatbot', 'categories'];

const fetcher = async (
  signal: AbortSignal,
  { fetchURL, source, ...board }: FetchOptions = {
    board_id: 'home',
    fetchURL: '/search',
  }
) => {
  const response = await axiosInstance.post<CategoryTabsResponseType>(
    fetchURL,
    {
      type: 'category',
      num_docs: 0,
      ...board,
    },
    {
      signal,
    }
  );

  const responseData = response.data;

  const exchangesExist =
    responseData.category_tabs.some(
      ({ key, data }) => key === EXCHANGE_KEY && !!data
    ) /** we don't want to manually add exchanges when asking within a feed if the feed does not already have an exchanges category */ ||
    (board.board_id !== 'home' && (!!board.board_id || !!board.channel_id)) ||
    !!source;

  const sortedCategories = responseData.category_tabs
    .filter(({ data, icon }) => !!data && !icon)
    .sort((a) => {
      if (a.key === EXCHANGE_KEY) {
        return -1;
      }
      return 0;
    });

  return ([] as CategoryTabsDataType[])
    .concat(
      ...(exchangesExist
        ? []
        : ([
            { key: EXCHANGE_KEY, label: EXCHANGE_KEY },
          ] as CategoryTabsDataType[])),
      sortedCategories
    )
    .reduce((accumulate, currentVal) => {
      if (IGNORE_CATEGORIES.includes(currentVal.key)) {
        return accumulate;
      }

      if (currentVal.key === EXCHANGE_KEY) {
        accumulate.push(...EXCHANGE_CATEGORIES);
      } else {
        accumulate.push(currentVal);
      }

      return accumulate;
    }, [] as CategoryTabsDataType[]);
};

export const useCategories = (
  { enabled, fetchURL, source, ...board }: CategoryTabsData = {
    board_id: 'home',
    enabled: true,
    fetchURL: '/search',
  }
) => {
  const data: FetchOptions = {
    fetchURL,
    source,
    ...board,
  };

  return useQuery(
    [...QUERY_KEYS, data],
    async ({ signal }) => fetcher(signal, data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled,
    }
  );
};

export const useFeedCategories = () => {
  const { board_id, channel_id, source } = useBoardFromURL() ?? {};
  const fetchURL = source ? `/search/${source}` : '/search';

  return useCategories({
    fetchURL,
    board_id,
    channel_id,
    source,
  });
};

export const useRefetchBotCategories = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEYS, {
      refetchInactive: true,
    });
  }, [queryClient]);
};

export const usePrefetchCategories = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(
      QUERY_KEYS,
      async ({ signal }) => fetcher(signal),
      { staleTime: Infinity, cacheTime: Infinity }
    );
  }, [queryClient]);
};

export const EXCHANGE_KEY = 'exchanges';
export const EXCHANGE_CATEGORIES = [
  { key: 'india_regulatory_data', label: 'Indian Exchange', data: true },
  { key: 'us_regulatory_data', label: 'US Exchange', data: true },
] as const;

export const NEEDL_WEB_KEY = 'needl_web';
export const NEEDL_WEB_CATEGORY = {
  key: NEEDL_WEB_KEY,
  label: 'Needl Web',
  data: true,
} as const;

export const IGNORE_CATEGORIES = ['today', 'links', 'media'];

export const formatCategory = (category: string, searchWithin?: string) => {
  if (searchWithin && searchWithin === 'needlweb') {
    return NEEDL_WEB_KEY;
  }

  if (category === 'india_regulatory_data') {
    return 'india_capital_markets';
  } else if (category === 'us_regulatory_data') {
    return 'us_capital_markets';
  }

  return category;
};

export type EXCHANGE_CATEGORIES_TYPE =
  typeof EXCHANGE_CATEGORIES[number]['key'];

type FetchOptions = {
  board_id?: string;
  channel_id?: string;
  fetchURL?: string;
  source?: string;
};

type CategoryTabsData = FetchOptions & { enabled?: boolean };
