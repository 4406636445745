import { AxiosError } from 'axios';
import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        if (error instanceof AxiosError) {
          if (!error.response) {
            return failureCount < 2;
          }

          return (
            ![401, 404].includes(error.response.status) && failureCount < 2
          );
        }
        return failureCount < 2;
      },
    },
  },
});
