import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { useNavigate } from '@/router';

export const useToggleAskNeedl = () => {
  const navigate = useNavigate();
  const { url } = useRouteMatch();
  const location = useLocation();

  const isOpen = React.useMemo(() => {
    const overlayParams = new URLSearchParams(location.search);
    return (
      overlayParams.get('overlay-type') === 'ask-needl' ||
      overlayParams.get('overlay-preview_askneedl') === 'true'
    );
  }, [location.search]);

  const toggleAskNeedl = React.useCallback(
    (type: 'ask-needl' | 'preview') => {
      const overlayParams = new URLSearchParams(location.search);

      if (isOpen) {
        if (type === 'ask-needl') {
          overlayParams.delete('overlay-type');
        } else if (type === 'preview') {
          overlayParams.set('overlay-preview_askneedl', 'false');
        }
      } else {
        if (type === 'ask-needl') {
          overlayParams.set('overlay-type', 'ask-needl');
        } else {
          overlayParams.set('overlay-preview_askneedl', 'true');
        }
      }

      navigate.push(
        {
          pathname: url,
          search: overlayParams.toString(),
        },
        null,
        {
          removeOldState: false,
        }
      );
    },
    [location.search, isOpen, navigate, url]
  );

  return { toggleAskNeedl, isOpen };
};
