import 'neuicons';
import './index.css';
import 'focus-visible';
import './analytics/initAnalytics';

import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReachPortal from '@reach/portal';
import * as ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundary as ErrorBoundaryImpl } from '@/components/ErrorBoundaries/ErrorBoundary';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';

import { AuthenticationStatusListener } from './components/AuthenticationStatusListener';
import { AuthProvider } from './components/AuthProvider';
import { queryClient } from './query-client';
import App from './routes';
import { theme } from './theme/theme';

async function prepare() {
  if (import.meta.env.DEV) {
    return import('./mocks/browser').then(({ default: mocks }) => {
      return mocks.start({ onUnhandledRequest: 'bypass' });
    });
  }
}

const Main = () => {
  const { mobile } = useResponsiveConstants();

  return (
    <AuthProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App />
              <AuthenticationStatusListener />
              <ReachPortal>
                <Toaster
                  position='bottom-center'
                  reverseOrder={false}
                  toastOptions={{
                    duration: 8000,
                    ...(mobile && { style: { marginBottom: '70px' } }),
                    className: 'font-medium',
                    style: {
                      backgroundColor: 'var(--gray-900)',
                      color: 'white',
                    },
                  }}
                />
              </ReachPortal>
            </MuiPickersUtilsProvider>
            {import.meta.env.DEV ? (
              <ReactQueryDevtools initialIsOpen={false} />
            ) : null}
          </QueryClientProvider>
        </ThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  );
};

prepare().then(() => {
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorBoundaryImpl}>
      <Main />
    </ErrorBoundary>,
    document.getElementById('root')
  );
});
