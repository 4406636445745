import { axiosDebugInstance } from '@/axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postDebugLogs = async (payload: any) => {
  try {
    await axiosDebugInstance.post('/debug-logs', {
      logData: payload,
    });
  } catch (e) {
    console.error('Error in logging to the backend', e);
  }
};
