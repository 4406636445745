const CHANNELS_BASE_ROUTE = '/channels';

export const CHANNELS_API_ROUTES = {
  index: () => CHANNELS_BASE_ROUTE,
  boards: (channelId: string) => `${CHANNELS_BASE_ROUTE}/${channelId}/boards`,
  users: (channelId: string) => `${CHANNELS_BASE_ROUTE}/${channelId}/users`,
  allUsers: (channelId: string) =>
    `${CHANNELS_BASE_ROUTE}/${channelId}/users/all`,
  createAndShareBoard: () => `${CHANNELS_BASE_ROUTE}/create_and_share_board`,
  channelInfo: (channelId: string) => `${CHANNELS_BASE_ROUTE}/${channelId}`,
  userSelfExit: (channelId: string) =>
    `${CHANNELS_BASE_ROUTE}/${channelId}/users/self`,
  deeplinkComments: (channelId: string, deeplink: string) =>
    `${CHANNELS_BASE_ROUTE}/${channelId}/comments/${deeplink}`,
  commentInfo: (channelId: string, deeplink: string, commentId: string) =>
    `${CHANNELS_BASE_ROUTE}/${channelId}/comments/${deeplink}/${commentId}`,
  deeplinkCommentsCount: (channelId: string, deeplink: string) =>
    `${CHANNELS_BASE_ROUTE}/${channelId}/comments/${deeplink}/count`,
  deeplinkLastSeen: (channelId: string, deeplink: string) =>
    `${CHANNELS_BASE_ROUTE}/${channelId}/comments/${deeplink}/last_seen`,
  starredStatus: (channelId: string) =>
    `${CHANNELS_BASE_ROUTE}/${channelId}/users/me`,
} as const;
