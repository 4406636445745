import styled from 'styled-components';
import tw from 'twin.macro';

export const StickyViewportHeader = styled.div<{
  spacing?: boolean | 'bottom' | 'top';
}>`
  ${tw`sticky top-0 z-10`}
  isolation: isolate;

  &::after,
  &::before {
    content: '';
    inset: 0;
    z-index: -1;
    ${tw`bg-white absolute`}
  }

  &::after {
    ${({ spacing = false }) =>
      typeof spacing === 'boolean' && spacing && tw`sm:-top-4 sm:-bottom-4`}
    ${({ spacing }) =>
      typeof spacing !== 'boolean' && spacing === 'top' && tw`sm:-top-4`}
    ${({ spacing }) =>
      typeof spacing !== 'boolean' && spacing === 'bottom' && tw`sm:-bottom-4`}
  }
`;

export const StickyHeader = styled(StickyViewportHeader)`
  ${tw`top-14 sm:top-[4.5rem]`}
`;
