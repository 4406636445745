import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { SOURCE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import type { FeedNavDataType, UseFetchNavReturnType } from '@/types';

const fetcher = async ({ signal }: QueryFunctionContext) => {
  const { data } = await axiosInstance.get<{ sources: FeedNavDataType[] }>(
    SOURCE_API_ROUTES.private(),
    { signal }
  );
  return data.sources || [];
};

export const NAVIGATION_PRIVATEAPPS = ['navigation', 'private-apps'];

export const useFetchPrivateApps = (): UseFetchNavReturnType => {
  return useQuery(NAVIGATION_PRIVATEAPPS, fetcher, {
    staleTime: timeInMs.FIFTEEN_SECONDS,
  });
};

export const useInvalidatePrivateApps = (): (() => void) => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    queryClient.invalidateQueries(NAVIGATION_PRIVATEAPPS);
  }, [queryClient]);
};

export const usePrefetchPrivateApps = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(NAVIGATION_PRIVATEAPPS, {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};
