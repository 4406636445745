// @ts-strict-ignore
import * as React from 'react';
import * as yup from 'yup';

import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import { useFeedRoute } from '@/hooks/useFeedRoute';
import { getComputedCSSVariable } from '@/utils/getComputedCSSVariable';

const STATES = ['open', 'opened', 'close', 'closed', 'peek'] as const;
type NavState = typeof STATES[number];

type Action =
  | { type: 'close' }
  | { type: 'closed' }
  | { type: 'hide'; mouseX: number }
  | { type: 'open'; source?: 'overlay' }
  | { type: 'opened' }
  | { type: 'peek' }
  | { type: 'toggle' };

type NavContextState = {
  state: NavState;
};

const SIDE_NAV_WIDTH = Number(
  /\d+/.exec(getComputedCSSVariable('--side-nav-width'))?.[0] ?? 0
);

const NavContext = React.createContext<NavContextState | null>(null);
const NavDispatchContext = React.createContext<React.Dispatch<Action> | null>(
  null
);

export const NavContextProvider: React.FunctionComponent = ({ children }) => {
  const { laptopAndBelow, mobile, desktop } = useResponsiveConstants();
  const feedRoute = useFeedRoute();
  const [state, dispatch] = React.useReducer(
    (state: NavState, action: Action) => {
      switch (action.type) {
        case 'close':
          if (feedRoute) {
            return state === 'closed' ? 'closed' : 'close';
          }
          return state;
        case 'closed':
          return 'closed';
        case 'open':
          return (action.source === 'overlay' && laptopAndBelow) || !feedRoute
            ? state
            : state === 'opened'
            ? 'opened'
            : 'open';
        case 'opened':
          return 'opened';
        case 'toggle':
          return state === 'opened' || state === 'open' ? 'close' : 'open';
        case 'peek':
          return state === 'closed' ? 'peek' : state;
        case 'hide':
          return state === 'peek' && action.mouseX > SIDE_NAV_WIDTH + 16
            ? 'closed'
            : state;
        default:
          return state;
      }
    },
    undefined,
    (): NavState => {
      // devices between mobile and desktop should have left nav closed initially
      if (!mobile && !desktop) {
        return 'closed';
      }

      if ('localStorage' in window) {
        const navState = localStorage.getItem('navState');
        if (navState) {
          try {
            const value = yup
              .string<NavState>()
              .oneOf(STATES)
              .validateSync(navState);

            if (value === 'open') return 'opened';
            if (value === 'close') return 'closed';
            return value;
          } catch {
            return 'opened';
          }
        }
      }

      return 'opened';
    }
  );

  const timerRef = React.useRef<NodeJS.Timer | null>(null);

  React.useEffect(() => {
    // @todo: pick up next week based on final discussion with product
    // const laptopAndAbove = window.matchMedia(
    //   `(min-width: ${getMediaCSSVariable('--screen-xl')})`
    // );
    // const betweenLaptopAndDesktop = window.matchMedia(
    //   `(min-width: ${getMediaCSSVariable(
    //     '--screen-xl'
    //   )}) and (max-width: ${getMediaCSSVariable('--screen-xxl')})`
    // );
    // const touchDevice = window.matchMedia('(hover: none)');

    // const mouseMoveHandler = (e: MouseEvent) => {
    //   if (touchDevice.matches || !laptopAndAbove.matches) {
    //     return;
    //   }
    //   if (betweenLaptopAndDesktop.matches ? e.clientX < 10 : e.clientX < 150) {
    // dispatch({ type: 'peek' });
    //   } else {
    //     dispatch({ type: 'hide', mouseX: e.clientX });
    //   }
    // };

    // window.addEventListener('mousemove', mouseMoveHandler, { passive: true });

    return () => {
      // window.removeEventListener('mousemove', mouseMoveHandler);
      clearTimeout(timerRef.current);
    };
  }, []);

  React.useEffect(() => {
    if (state !== 'close') {
      clearTimeout(timerRef.current);
      return;
    }
    if (state === 'close') {
      timerRef.current = setTimeout(() => {
        dispatch({ type: 'closed' });
      }, 500);
    }
  }, [state]);

  React.useEffect(() => {
    if ('localStorage' in window) {
      localStorage.setItem('navState', state);
    }
  }, [state]);

  return (
    <NavDispatchContext.Provider value={dispatch}>
      <NavContext.Provider value={React.useMemo(() => ({ state }), [state])}>
        {children}
      </NavContext.Provider>
    </NavDispatchContext.Provider>
  );
};

export const useNavState = () => {
  const context = React.useContext(NavContext);

  if (!context) {
    throw new Error('useNavState must be used within a NavContextProvider');
  }

  return context;
};

export const useNavActionDispatch = () => {
  const context = React.useContext(NavDispatchContext);

  if (!context) {
    throw new Error(
      'useNavActionDispatch must be used within a NavContextProvider'
    );
  }

  return context;
};
