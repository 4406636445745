// @ts-strict-ignore
import { useRouteMatch } from 'react-router-dom';

const CONNECT_HEADERS = {
  app: 'Apps',
  website: 'Websites',
  exchange: 'Capital Markets',
  templates: 'Needl Templates',
};

export const useGetConnectPageHeader = () => {
  const { params } = useRouteMatch<RouteParam>(['/connect/:page']) || {
    params: { page: '' },
  };

  return CONNECT_HEADERS[params.page];
};

type ConnectType = 'app' | 'exchange' | 'templates' | 'website';
type RouteParam = {
  page: ConnectType;
};
