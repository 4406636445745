import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { SOURCE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import type { FeedNavDataType, UseFetchNavReturnType } from '@/types';

const fetcher = async (
  params: Record<string, unknown>,
  signal?: AbortSignal
) => {
  const { data } = await axiosInstance.get(SOURCE_API_ROUTES.public(), {
    params,
    signal,
  });
  return data.sources || [];
};

export const NAVIGATION_PUBLICAPPS = ['navigation', 'public-apps'];

export const useFetchPublicApps = (
  priorityWebsites = false
): UseFetchNavReturnType => {
  const { data, isLoading, isError, isFetching, error } = useQuery(
    [...NAVIGATION_PUBLICAPPS, priorityWebsites],

    ({ signal }) =>
      fetcher(
        {
          priority_websites: priorityWebsites,
        },
        signal
      ),
    {
      staleTime: timeInMs.FIFTEEN_SECONDS,
    }
  );

  return { data, isLoading, isError, isFetching, error };
};

export const useInvalidatePublicApps = () => {
  const queryClient = useQueryClient();
  return React.useCallback(async () => {
    return await Promise.all([
      queryClient.invalidateQueries([...NAVIGATION_PUBLICAPPS, true]),
      queryClient.invalidateQueries([...NAVIGATION_PUBLICAPPS, false]),
    ]);
  }, [queryClient]);
};

export const usePrefetchPublicApps = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery([...NAVIGATION_PUBLICAPPS, true], {
      queryFn: ({ signal }) =>
        fetcher(
          {
            priority_websites: true,
          },
          signal
        ),
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);

  React.useEffect(() => {
    queryClient.prefetchQuery([...NAVIGATION_PUBLICAPPS, false], {
      queryFn: ({ signal }) =>
        fetcher(
          {
            priority_websites: false,
          },
          signal
        ),
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};

/**
 * segregate the data into rss and exchanges
 */
export const useGetFilteredPublicApps = (priorityWebsites = false) => {
  const queryData = useFetchPublicApps(priorityWebsites);

  const { rssData, exchangesData } = React.useMemo(() => {
    if (!queryData.data) {
      return {
        rssData: [],
        exchangesData: [],
      };
    }

    return queryData.data?.reduce(
      (acc, source) => {
        if (source.category === 'exchanges_groups') {
          return {
            ...acc,
            exchangesData: [...acc.exchangesData, source],
          };
        } else if (source.category === 'rss_news') {
          return {
            ...acc,
            rssData: [...acc.rssData, source],
          };
        }
        return acc;
      },
      {
        rssData: [] as FeedNavDataType[],
        exchangesData: [] as FeedNavDataType[],
      }
    );
  }, [queryData.data]);

  return {
    ...queryData,
    rssData,
    exchangesData,
  };
};
