import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Text } from '@/atoms/Text/Text';
import ArrowRight from '@/icons/expand_right.svg?react';
import { NeedlLink } from '@/router';
import { getAppTitle } from '@/utils/getAppTitle';

type BreadCrumbType = Omit<React.HTMLAttributes<HTMLLIElement>, 'css'> & {
  children: React.ReactNode;
  hasNext: boolean;
  last: boolean;
  to: string;
  showRawLabel?: boolean;
};

const IconContainer = styled.span`
  ${tw`fill-current text-gray-500 w-4 h-4`}

  & svg {
    ${tw`w-full h-full`}
  }
`;

const StyledLink = styled(NeedlLink)`
  ${tw`p-1 text-gray-700 body-sm hover:underline hover:text-emerald-900`}
`;

export const Crumb = React.forwardRef<HTMLLIElement, BreadCrumbType>(
  ({ children, hasNext, last, to, showRawLabel = false, ...rest }, ref) => {
    const { state } = useLocation();
    const parsedLabel =
      typeof children === 'string' && !showRawLabel
        ? getAppTitle(children)
        : children;

    return (
      <li
        ref={ref}
        {...rest}
        className={`flex items-center space-x-2 ${
          showRawLabel ? '' : 'capitalize'
        }`}
      >
        {!last ? (
          <StyledLink
            to={{ pathname: to, state }}
            className='truncate max-w-xs'
          >
            {parsedLabel}
          </StyledLink>
        ) : (
          <Text as='span' bold className='p-1 truncate max-w-xs'>
            {parsedLabel}
          </Text>
        )}
        {hasNext && !last ? (
          <IconContainer>
            <ArrowRight />
          </IconContainer>
        ) : null}
      </li>
    );
  }
);
