// @ts-strict-ignore
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { OVERLAY_TYPES } from '@/components/AppOverlay';

export const useCurrentDocument = (): UseCurrentDocumentReturnType | null => {
  const previewRouteMatch = useRouteMatch<RouteParams>([
    '/preview/:previewSource/:deeplink',
  ]);

  const [overlayParams] = React.useState(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('overlay-type') !== OVERLAY_TYPES.Preview) {
      return null;
    }

    if (!params.has('overlay-deeplink') || !params.has('overlay-source')) {
      return null;
    }

    return {
      deeplink: params.get('overlay-deeplink'),
      source: params.get('overlay-source'),
    };
  });

  if (previewRouteMatch) {
    return {
      source: previewRouteMatch.params.previewSource,
      deeplink: previewRouteMatch.params.deeplink,
    };
  }

  if (overlayParams) {
    return overlayParams;
  }

  return null;
};

type UseCurrentDocumentReturnType = {
  deeplink: string;
  source: string;
};

type RouteParams = {
  previewSource: string;
  deeplink: string;
};
