import { useQuery } from 'react-query';

import { ML_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

const QUERY_KEY = 'ask-needl-messaging';

export const useFetchAskNeedlMessaging = () => {
  return useQuery([QUERY_KEY], fetcher, {
    select: (data) => data.results,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};

const fetcher = async () => {
  const response = await axiosInstance.get<AskNeedlMessagingResponse>(
    ML_API_ROUTES.askNeedlMessaging()
  );
  return response.data;
};

export const PLACEHOLDER_ASK_NEEDL_MESSAGES: Record<
  MessagingType,
  MessageInfoType[]
> = {
  all: Array.from({ length: 4 }, () => ({
    isLoading: true,
    question: '',
    icon: '',
  })),
  document: Array.from({ length: 0 }, () => ({
    isLoading: true,
    question: '',
    icon: '',
  })),
  feed: Array.from({ length: 2 }, () => ({
    isLoading: true,
    question: '',
    icon: '',
  })),
};

type AskNeedlMessagingResponse = {
  results: Record<MessagingType, MessageInfoType[]>;
};

export type MessageInfoType = {
  isLoading: boolean;
  question: string;
  icon: string;
};

export type MessagingType = 'all' | 'document' | 'feed';
