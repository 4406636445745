import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { CONNECT_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import { useFeatureFlag } from '@/hooks/query/useFeatureFlag';

import type { AppLibraryDataResponse } from './types';

const fetcher = ({ signal }: QueryFunctionContext) =>
  axiosInstance.get<AppLibraryDataResponse>(CONNECT_API_ROUTES.baseRoute(), {
    signal,
  });

const useAppLibraryData = () => {
  const {
    isLoading: isFeaturesLoading,
    isError: isFeaturesError,
    data: features,
  } = useFeatureFlag();

  const {
    isLoading: isIntegrationsDataLoading,
    isError: isIntegrationsError,
    data: integrationsData,
    ...queryData
  } = useQuery(['integrations'], fetcher, {
    staleTime: timeInMs.FIFTEEN_SECONDS,
  });

  const data = React.useMemo(() => {
    if (!integrationsData?.data?.results) {
      return integrationsData;
    }

    if (isFeaturesLoading || !features || !features?.is_sharepoint) {
      integrationsData.data.results = integrationsData.data.results.filter(
        (result) => result.label !== 'sharepoint'
      );
    }
    return integrationsData;
  }, [features, isFeaturesLoading, integrationsData]);

  const placeholderData = React.useMemo(
    () =>
      Array.from({ length: 5 }).map((_, i) => ({
        category: '',
        disabled: false,
        flag: false,
        label: `id-${i}`,
        private: false,
        tokenValid: false,
        value: '',
        isLoading: true,
      })),
    []
  );

  return React.useMemo(
    () => ({
      ...queryData,
      isLoading: isIntegrationsDataLoading || isFeaturesLoading,
      isError: isIntegrationsError || isFeaturesError,
      data,
      placeholderData,
    }),
    [
      data,
      isIntegrationsError,
      isIntegrationsDataLoading,
      isFeaturesError,
      isFeaturesLoading,
      placeholderData,
      queryData,
    ]
  );
};

const useRefetchAppLibraryData = (): (() => void) => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    queryClient.invalidateQueries('integrations');
  }, [queryClient]);
};

const usePrefetchAppLibraryData = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery('integrations', {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};

export {
  useAppLibraryData,
  usePrefetchAppLibraryData,
  useRefetchAppLibraryData,
};
