// @ts-strict-ignore
import * as React from 'react';

import { useUploadContext } from '@/components/FileUpload';
import type { UploadLocation } from '@/components/FileUpload/context/context';
import { HiddenInput } from '@/components/HiddenInput';

export const UploadInput = React.forwardRef<HTMLInputElement, UploadInputType>(
  ({ location }, ref) => {
    const { addUploadFiles } = useUploadContext();

    return (
      <HiddenInput
        ref={ref}
        type='file'
        id='fileUpload'
        name='fileUpload'
        multiple
        onChange={(e) => {
          addUploadFiles(e.target.files, location);
          e.target.value = '';
        }}
      />
    );
  }
);

type UploadInputType = {
  location: UploadLocation;
};
