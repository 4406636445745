import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const CommonDialog = function ({
  open,
  title,
  children,
  confirmationDialog = false,
  _disableBackdropClick = false,
  _disableEscapeKeyDown = false,
  handleClose = () => {
    return;
  },
  handleOk = () => {
    return;
  },
  ...other
}) {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth='xs'
      aria-labelledby='confirmation-dialog'
      open={open}
      disableBackdropClick={confirmationDialog}
      disableEscapeKeyDown={confirmationDialog}
      onClose={handleClose}
      {...other}
    >
      <DialogTitle id='confirmation-dialog'>
        {title}
        {!confirmationDialog && (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {confirmationDialog && (
        <DialogActions>
          <Button onClick={handleOk} color='primary'>
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

CommonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  confirmationDialog: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  handleOk: PropTypes.func,
  handleClose: PropTypes.func,
};
