import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

type Props = {
  basename?: string;
  mapping?: Record<string, string>;
  skipPathFragment?: string[];
};
type Return = { label: string; to: string }[];

type UseBreadcrumb = (props?: Props) => Return;

export const useBreadcrumb: UseBreadcrumb = ({
  basename = 'Home',
  mapping = {},
  skipPathFragment = [],
}: Props = {}) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const paths = pathname
    .replace(/\/$/, '')
    .split('?')[0]
    .split('/')
    .filter(Boolean);
  const pathFragmnets = path.split('/').filter(Boolean);

  return React.useMemo(
    () => [
      { label: basename, to: '/' },
      ...paths.reduce(
        (constructedPaths: Return, fragment: string, i: number) => {
          if (skipPathFragment.includes(fragment)) {
            return constructedPaths;
          }
          if (skipPathFragment.includes(pathFragmnets[i])) {
            return constructedPaths;
          }

          const to = `/${paths.slice(0, i + 1).join('/')}`;

          return [
            ...constructedPaths,
            {
              label:
                fragment in mapping
                  ? mapping[fragment]
                  : pathFragmnets[i] in mapping
                  ? mapping[pathFragmnets[i]]
                  : fragment,
              to,
            },
          ];
        },
        []
      ),
    ],
    [basename, mapping, pathFragmnets, paths, skipPathFragment]
  );
};
