import type { SearchOptions } from '@/types';

import { useQueryParams } from './useQueryParams';

export const useGetSearchType = () => {
  const [q] = useQueryParams();

  return (
    Array.isArray(q.search_type) ? q.search_type[0] : q.search_type
  ) as SearchOptions;
};
