import type { AxiosError } from 'axios';

import type {
  AskNeedlResponseType,
  SearchWithinState,
} from '@/components/ChatBot/hooks';

export type SessionState = {
  sessionId?: string;
  messages: Message[];
  promptEnabled?: boolean;
};

export type UserMessageState = {
  type: 'user';
  prompt: string;
  id: string;
  botMessageId: string;
};

export type BotMessageState = {
  type: 'bot';
  formattedData: string;
  data: AskNeedlResponseType;
  prompt: string;
  id: string;
  error?: AxiosError;
  streamingAnswer?: string;
  answerMarkdown?: string;
};

type Message = BotMessageState | UserMessageState;

export const isBotMessageState = (
  message: Message
): message is BotMessageState => message.type === 'bot';

export const isUserMessageState = (
  message: Message
): message is UserMessageState => message.type === 'user';

export type ChatPanelType = {
  searchWithin: SearchWithinState;
};
