export const getEntries = <T extends object>(object: T) => {
  if (object === null || object === undefined) {
    return [];
  }
  return Object.entries(object) as [keyof T, T[keyof T]][];
};

export const getKeys = <T extends object>(object: T) => {
  if (object === null || object === undefined) {
    return [];
  }
  return Object.keys(object) as (keyof T)[];
};

export const getValues = <T extends object>(object: T) => {
  if (object === null || object === undefined) {
    return [];
  }
  return Object.values(object) as T[keyof T][];
};
