import { usePreview } from '@/hooks/usePreview';
import { useQueryParams } from '@/hooks/useQueryParams';

export const usePreviewDocumentMatch = (): UsePreviewDocumentMatchType => {
  const isPreview = usePreview();
  const [_, queryParams] = useQueryParams();

  if (isPreview) {
    const { board_id, channel_id } = queryParams;
    return {
      ...(board_id && {
        board_id: typeof board_id === 'string' ? board_id : board_id[0],
      }),
      ...(channel_id && {
        channel_id: typeof channel_id === 'string' ? channel_id : channel_id[0],
      }),
    };
  }

  return null;
};

type UsePreviewDocumentMatchType = {
  board_id?: string;
  channel_id?: string;
} | null;
