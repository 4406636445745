import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

import { getIdToken } from './components/AuthProvider';
import { extractAppType } from './utils/appTypes';

const axiosWithRetryInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 0,
});

axiosRetry(axiosWithRetryInstance, {
  retries: 2,
  retryDelay: () => 300,
  retryCondition: (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 404) {
      return false;
    }

    return (
      isNetworkOrIdempotentRequestError(error) ||
      error?.response?.status === 403
    );
  },
});

/**
 * had to move it out
 * of React lifecycle, because
 * we have to make prefetch
 * requests before React lifecycle starts
 */
axiosWithRetryInstance.interceptors.request.use(
  async (config) => {
    const jwt = await getIdToken();

    config.headers.token = jwt;
    config.headers['app-type'] = extractAppType();

    return config;
  },
  (error) => Promise.reject(error)
);

export { axiosWithRetryInstance };
