import { Tooltip } from '@/components/Atoms/Tooltip/Tooltip';
import { IconButton } from '@/components/IconButton/IconButton';
import BrushIcon from '@/icons/new-chat-plus.svg?react';

// TODO : update this to new design
export const ClearButton = ({
  className,
  disabled,
  onClick,
}: ClearButtonType) => {
  return (
    <div className={className}>
      <Tooltip label='New Topic'>
        <IconButton
          label='new topic'
          disabled={disabled}
          onClick={onClick}
          size='xl'
        >
          <BrushIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export type ClearButtonType = {
  className?: string;
  disabled: boolean;
  onClick: () => void;
};
