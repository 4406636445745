// @ts-strict-ignore
import type { AxiosResponse } from 'axios';
import type * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

import { useFeedMetaData } from '@/hooks/query/useFeedMetaData';
import { useFetch } from '@/hooks/query/useFetch';
import { useSpellCorrection } from '@/hooks/query/useSpellCorrection';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import { useLocationMatch } from '@/hooks/useLocationMatch';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useSearchRoute } from '@/hooks/useSearchRoute';
import { getAppTitle } from '@/utils/getAppTitle';

type TrendingResponse = AxiosResponse<{ trending_terms: string[] }>;

type ReturnType = {
  /**
   * page title text
   */
  header: React.ReactNode;
  /**
   * page location
   */
  location: string;
  /**
   * text for tooltip
   */
  plainText: string;
  /**
   * sub header text
   */
  subHeader?: string[];
  /**
   * page type for specific rendering
   */
  type?: 'search' | 'trending';
};

export const useHeaderText = (): ReturnType => {
  const {
    isNeedlboxDataLoading,
    isPrivateAppDataLoading,
    isPublicAppDataLoading,
    isNeedlAIBoxDataLoading,
    isChannelsLoading,
    isNotebooksLoading,
    needlboxData,
    needlAIBoxData,
    privateAppData,
    publicAppData,
    feedData,
    channels,
    notebooks,
  } = useFeedMetaData();
  const searchRoute = useSearchRoute();
  const { notificationsFeedMatch } = useLocationMatch();

  const { mobile } = useResponsiveConstants();

  const {
    params: { category, source },
  } = useRouteMatch<{
    category: string;
    source: string;
  }>(['/search/:subCategory', '/:category/:source/:subCategory']) || {
    params: {},
  };

  const [query] = useQueryParams();
  const q = Array.isArray(query.q) ? query.q[0] : query.q;

  const isTrending = source === 'trending';

  const { data: spellCorrectionData, status: spellCorrectionStatus } =
    useSpellCorrection();

  const { data: trendingData, status: trendingStatus } =
    useFetch<TrendingResponse>({
      path: '/search/trending',
      enabled: isTrending,
      queryParams: { num_docs: 0, start_from: 0 },
    });

  if (notificationsFeedMatch) {
    return { header: '', plainText: '', location: 'Notifications' };
  }

  if (
    !searchRoute &&
    ((category === undefined && source === undefined) ||
      (category &&
        !['apps', 'needlbox', 'feeds', 'channels', 'notebooks'].includes(
          category
        )))
  ) {
    return { header: '', plainText: '', location: '' };
  }

  if (
    spellCorrectionStatus === 'loading' ||
    trendingStatus === 'loading' ||
    isNeedlboxDataLoading ||
    isPrivateAppDataLoading ||
    isPublicAppDataLoading ||
    isNeedlAIBoxDataLoading ||
    isChannelsLoading ||
    isNotebooksLoading
  ) {
    return { header: <Skeleton width={200} />, plainText: '', location: '' };
  }

  const needlWeb = query.search_type === 'needl_web';
  const sharedData = query.search_type === 'shared_data';

  const feedName =
    source === 'home' || !!searchRoute
      ? 'My Data'
      : feedData?.find((feed) => feed.board_id === source)?.board_name || '';

  const channelName =
    channels.find((channel) => channel.id === source)?.name || '';

  const DATA_MAP = {
    apps: [...privateAppData, ...publicAppData],
    needlbox: [...needlboxData, ...needlAIBoxData],
  };

  const filteredSource = DATA_MAP[category as keyof typeof DATA_MAP]?.filter(
    (app) => app.key === source
  );
  const sourceTitle =
    filteredSource?.length > 0
      ? getAppTitle(filteredSource[0].key, filteredSource[0].label)
      : source
      ? getAppTitle(source)
      : '';

  const location = needlWeb
    ? 'Needl Web'
    : sharedData
    ? 'Group Data'
    : feedName || channelName || sourceTitle;

  if (q) {
    if (mobile) {
      return {
        header: (
          <div className='flex flex-col'>
            <span className='line-clamp-1'>
              <em className='px-2'>{q}</em>
            </span>
          </div>
        ),
        type: 'search',
        plainText: `${q}`,
        location,
      };
    }

    if (spellCorrectionStatus === 'idle') {
      return {
        header: <Header location={location} query={q} />,
        type: 'search',
        plainText: `${q} in ${location}`,
        location,
      };
    }

    if (
      spellCorrectionStatus === 'success' &&
      spellCorrectionData?.data?.query_params
    ) {
      const { corrected_q } = spellCorrectionData.data.query_params;
      if (corrected_q === q) {
        return {
          header: <Header location={location} query={q} />,
          type: 'search',
          plainText: `${q} in ${location}`,
          location,
        };
      }
      return {
        header: <Header location={location} query={corrected_q} />,
        subHeader: [q as string],
        type: 'search',
        plainText: `${corrected_q} in ${location}`,
        location,
      };
    }
  }

  if (category === 'notebooks') {
    const notebookName =
      notebooks.find((notebook) => notebook.notebook_id === source)
        ?.notebook_name || '';

    return {
      header: notebookName,
      plainText: notebookName,
      location: '',
    };
  }

  if (isTrending) {
    if (trendingStatus === 'success') {
      return {
        header: location,
        subHeader: trendingData?.data.trending_terms,
        type: 'trending',
        plainText: location,
        location,
      };
    }
  }

  return {
    header: location,
    plainText: location,
    location,
  };
};

const Header = ({ query, location }: HeaderType) => {
  return (
    <>
      <SearchTermContainer>{query}</SearchTermContainer>{' '}
      <SearchLocationContainer>in {location}</SearchLocationContainer>
    </>
  );
};

const SearchTermContainer = styled.span`
  ${tw`block font-bold italic truncate pr-1`}
`;

const SearchLocationContainer = styled.span`
  ${tw`text-gray-500 sub-heading-normal-bold truncate`}
`;

type HeaderType = {
  query: string;
  location: string;
};
