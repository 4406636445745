import Skeleton from 'react-loading-skeleton';

import { Crumb } from '@/atoms/Crumb';

import { useBreadcrumb } from './hooks/useBreadcrumb';

export type BreadcrumbsType = {
  /**
   * baseroute name
   */
  basename?: string;
  /**
   * custom slug mapping
   */
  mapping?: Record<string, string>;
  /**
   * array of URL fragments that should be ignored from bread crumb
   */
  skipPathFragment?: string[];
  /**
   * show raw label
   */
  showRawLabel?: boolean;
};

export const Breadcrumb = ({
  basename,
  mapping,
  skipPathFragment,
  ...rest
}: BreadcrumbsType): JSX.Element => {
  const breadcrumbs = useBreadcrumb({ basename, mapping, skipPathFragment });

  return (
    <ol className='flex items-center space-x-2 flex-wrap'>
      {breadcrumbs.map(({ label, to }, index) => (
        <Crumb
          key={`${label}_${to}`}
          hasNext={breadcrumbs.length > 1}
          last={index === breadcrumbs.length - 1}
          to={to}
          {...rest}
        >
          {label || <Skeleton width={250} />}
        </Crumb>
      ))}
    </ol>
  );
};
