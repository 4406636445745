import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { SOURCE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import type { FeedNavDataType, UseFetchNavReturnType } from '@/types';

import { useFeatureFlag } from './useFeatureFlag';

const fetcher = async ({ signal }: QueryFunctionContext) => {
  return await axiosInstance.get<{ sources: FeedNavDataType[] }>(
    SOURCE_API_ROUTES.aiBox(),
    { signal }
  );
};

export const NAVIGATION_FEEDS = ['navigation', 'aibox'];

const useFetchAIBoxImpl = (): UseFetchNavReturnType => {
  const { data, isLoading, isError, isFetching, error } = useQuery(
    NAVIGATION_FEEDS,
    fetcher,
    {
      select: (data) => data.data.sources,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    }
  );

  return { data, isLoading, isError, isFetching, error };
};

export const useFetchAIBox = () => {
  const {
    isLoading: isFeaturesLoading,
    isError: isFeaturesError,
    data: features,
  } = useFeatureFlag();
  const {
    data: aiBoxData,
    isLoading: isAiBoxLoading,
    isError: isAiBoxError,
    isFetching,
  } = useFetchAIBoxImpl();

  const data = React.useMemo(() => {
    if (!aiBoxData) {
      return undefined;
    }

    return aiBoxData.filter((item) => {
      if (item.key === 'trending') {
        return features && features.is_trending_enabled;
      }

      return true;
    });
  }, [aiBoxData, features]);

  return {
    isLoading: isFeaturesLoading || isAiBoxLoading,
    isError: isFeaturesError || isAiBoxError,
    isFetching,
    data,
  };
};

export const usePrefetchAIBox = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(NAVIGATION_FEEDS, {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};
