import type { ButtonProps } from '@/components/Button/Button';
import { Button } from '@/components/Button/Button';
import { NEEDL_PAGES } from '@/utils/needl-pages';

export const ContactSalesButton = (props: ButtonProps) => {
  return (
    <a
      href={NEEDL_PAGES.CONTACT_SALES_FOR_PRICING}
      target='_blank'
      rel='noreferrer noopener'
    >
      <Button variant='primary' as='span' {...props}>
        Contact Sales
      </Button>
    </a>
  );
};
