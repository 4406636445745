import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';

import { IconButton } from '@/components/IconButton/IconButton';
import { ASK_NEEDL_NOT_ENABLED } from '@/features/ChatBotV2/constants';
import { useShowAskNeedlButton } from '@/features/ChatBotV2/hooks/useShowAskNeedlButton';
import { useToggleAskNeedl } from '@/features/ChatBotV2/hooks/useToggleAskNeedl';
import { useHeaderText } from '@/features/Feed/hooks/useHeaderText';
import { useLocationMatch } from '@/hooks/useLocationMatch';
import { getLogo } from '@/utils/getIcons';

export const AskNeedlButton = () => {
  const { toggleAskNeedl } = useToggleAskNeedl();

  const { notebooksMatch, connectMatch } = useLocationMatch();

  const {
    params: { source },
  } = useRouteMatch<{
    source: string;
  }>(['/:category/:source/:subCategory']) || {
    params: {},
  };

  const { plainText } = useHeaderText();

  const enableButtonLocations =
    ASK_NEEDL_NOT_ENABLED.includes(source ?? '') ||
    !!notebooksMatch ||
    !!connectMatch;

  const disableButton = enableButtonLocations ? false : plainText === '';

  const showAskNeedlButton = useShowAskNeedlButton();

  if (!showAskNeedlButton) {
    return null;
  }

  const handleClick = () => {
    toggleAskNeedl('ask-needl');
  };

  const AskNeedlChatIcon = getLogo('ask_needl_chat_icon');

  return (
    <FloatingContainer>
      <IconButton
        onClick={handleClick}
        hoverBg={false}
        disabled={disableButton}
        id='ask-needl-button'
      >
        <AskNeedlChatIcon className='!w-25 !h-25' />
      </IconButton>
    </FloatingContainer>
  );
};

const FloatingContainer = styled.div`
  ${tw`fixed bottom-10 right-10`}
`;
