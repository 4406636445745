// @ts-strict-ignore
import * as React from 'react';

const OverlayContext = React.createContext<OverlayContextType | null>(null);

export const OverlayContextProvider = ({ children }) => {
  return (
    <OverlayContext.Provider
      value={{
        isVisible: true,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};

export const useOverlayContext = () => {
  const context = React.useContext(OverlayContext);

  if (!context) {
    return null;
  }

  return context;
};

type OverlayContextType = {
  isVisible: boolean;
};
