import * as React from 'react';

import { useOverlayQueryParams } from '@/components/AppOverlay';
import { HideHubspot } from '@/components/HideHubspot';

import type { CustomFeedPage } from './constants';
import { CUSTOMFEED_PAGES } from './constants';
import {
  CreateAIFeed,
  CreateFeed,
  CustomizeTemplate,
  EditFeed,
  SelectFeedType,
  ViewFeedTemplate,
} from './Pages';
import { EditAIFeed } from './Pages/EditAIFeed';
import {
  AddFeedSuggestion,
  ReviewFeedSuggestion,
} from './Pages/FeedSuggestion';
import { SelectAIFeedType } from './Pages/SelectAIFeedType';

export const CustomFeedImpl = () => {
  const queryParams = useOverlayQueryParams();

  const page =
    queryParams && 'page' in queryParams
      ? (queryParams?.['page'] as CustomFeedPage)
      : null;

  switch (page) {
    case CUSTOMFEED_PAGES.SelectFeedType:
      return <SelectFeedType />;
    case CUSTOMFEED_PAGES.FeedTemplateReadonly:
      return <ViewFeedTemplate />;
    case CUSTOMFEED_PAGES.FeedTemplateCustomize:
      return <CustomizeTemplate />;
    case CUSTOMFEED_PAGES.CreateFeed:
      return <CreateFeed />;
    case CUSTOMFEED_PAGES.SelectAIFeedType:
      return <SelectAIFeedType />;
    case CUSTOMFEED_PAGES.CreateAIFeed:
      return <CreateAIFeed />;
    case CUSTOMFEED_PAGES.EditFeed:
      return <EditFeed />;
    case CUSTOMFEED_PAGES.EditAIFeed:
      return <EditAIFeed />;
    case CUSTOMFEED_PAGES.FeedSuggestion:
      return <AddFeedSuggestion />;
    case CUSTOMFEED_PAGES.ReviewFeedSuggestion:
      return <ReviewFeedSuggestion />;
    default:
      throw new Error('Unknown CustomFeed overlay page');
  }
};

export const CustomFeed = () => {
  return (
    <React.Suspense fallback={null}>
      <HideHubspot />
      <CustomFeedImpl />
    </React.Suspense>
  );
};
