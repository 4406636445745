import styled from 'styled-components';
import tw from 'twin.macro';

export const AnimatedNavbar = styled.nav<
  Pick<{ addDivider?: boolean }, 'addDivider'>
>`
  ${tw`flex items-center justify-center gap-x-3`}

  ${({ addDivider = true }) =>
    addDivider && [
      `&::after {
         content: '';`,
      tw`absolute -bottom-0.5 left-0 right-0 border border-gray-300`,
      `}`,
    ]}
`;
