export const NEEDL_PAGES = {
  HOME_URL: 'https://needl.ai',
  SUPPORT_URL: 'https://www.needl.ai/help',
  PRICING_PLANS_URL: 'https://www.needl.ai/pricing',
  RELEASE_NOTES_URL: 'https://releasenotes.needl.ai',
  TERMS_OF_SERVICE_URL: 'https://needl.ai/tos.html',
  TAKE_FEEDBACK_URL:
    'https://docs.google.com/forms/d/e/1FAIpQLSdlqkUPWWvgnzsfNjmmklwCSDGwXADoRH6uOnDgLmFzhI8vnQ/viewform',
  NO_RESULTS_INFO_URL: 'https://support.needl.ai/smart-search',
  SECURITY_URL: 'https://needl.ai/security',
  EXTENSION_CHROME_STORE_URL:
    'https://chrome.google.com/webstore/detail/add-to-needl/eelmiapllpahcalpjjljbhnjojcncfea',
  APP_STORE_URL: 'https://apps.apple.com/in/app/needl-ai/id1667440792',
  PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.needl.ai.needl',
  SECURITY_DETAILS_PAGE: 'https://www.needl.ai/security',
  REQUEST_DEMO_PAGE: 'https://meetings.hubspot.com/mpotdar',
  CONTACT_SALES_FOR_PRICING:
    'https://meetings.hubspot.com/mspotdar/pricing-discussion',
  PRIVACY_POLICY_URL: 'http://needl.ai/privacy.html',
  PULSE_FEEDBACK_URL: 'https://forms.gle/QkBGvvo9DKTSrNXN8',
  PULSE_SUPPORT_URL: 'https://support.needl.ai/needlpulse',
  MICROSOFT_AI_FIRST_MOVER:
    'https://www.microsoft.com/en-in/aifirstmovers/needlai',
  WHATSAPP_PRIVACY_POLICY_LINK: 'https://wa.me/tos/20210210',
};
