const CONNECT_BASE_ROUTE = '/connect';

export const CONNECT_API_ROUTES = {
  baseRoute: () => CONNECT_BASE_ROUTE,
  about: () => `${CONNECT_BASE_ROUTE}/about`,
  userId: () => `${CONNECT_BASE_ROUTE}/user_id`,
  userStatus: () => `${CONNECT_BASE_ROUTE}/user_status`,
  public: () => `${CONNECT_BASE_ROUTE}/public`,
  custom: (source: string) => `${CONNECT_BASE_ROUTE}/${source}/custom`,
  multiple: () => `${CONNECT_BASE_ROUTE}/public/multiple`,
  rss: () => `${CONNECT_BASE_ROUTE}/rss`,
  rssTopics: () => `${CONNECT_BASE_ROUTE}/rssTopics`,
  deleteCookie: () => `${CONNECT_BASE_ROUTE}/delete_cookie`,
  info: () => `${CONNECT_BASE_ROUTE}/info`,
  delink: () => `${CONNECT_BASE_ROUTE}/delink`,
  otp: (source: string) => `${CONNECT_BASE_ROUTE}/${source}/otp`,
  verifyOtp: (source: string) => `${CONNECT_BASE_ROUTE}/${source}/verify_otp`,
  verify: (source: string) => `${CONNECT_BASE_ROUTE}/${source}/verify`,
  sourceInfo: (source: string) => `${CONNECT_BASE_ROUTE}/${source}`,
  tokenVerified: (source: string) =>
    `${CONNECT_BASE_ROUTE}/${source}/token_verified`,
  getFeedTemplates: () => `${CONNECT_BASE_ROUTE}/getFeedTemplates`,
  setFeedTemplates: () => `${CONNECT_BASE_ROUTE}/setFeedTemplates`,
  notification: () => `${CONNECT_BASE_ROUTE}/notification`,
  status: (source: string) => `${CONNECT_BASE_ROUTE}/${source}/status`,
  commentNotification: () => `${CONNECT_BASE_ROUTE}/comment-notification`,
  summaryNotification: () => `${CONNECT_BASE_ROUTE}/summary-notification`,
} as const;
