import { usePrefetchNotificationsData } from '@/components/AppHeader/hooks/useNotificationsData';
import { usePrefetchCategories as usePrefetchChatbotCategories } from '@/components/ChatBot';
import { usePrefetchAppLibraryData } from '@/components/ConnectApps/Common/appLibraryData';
import { usePrefetchUploadConfig } from '@/components/FileUpload';
import { usePrefetchChannels } from '@/features/Channels/hooks/useFetchChannels';
import { usePrefetchOrganizationInfo } from '@/features/Channels/hooks/useFetchOrganization';
import { usePrefetchAllNotebooks } from '@/features/Notes/hooks/useFetchNotebooks';
import { usePrefetchCompanyFilter } from '@/features/PortfolioManager/hooks/useFetchCompanyFilter';
import { usePrefetchPricingMessaging } from '@/features/Pricing/hooks/useFetchMessaging';
import { usePrefetchSummarisationConfig } from '@/features/Summarisation';
import { usePrefetchPrivateApps } from '@/hooks/query/useFetchPrivateApps';

import { usePrefetchConfigUI } from './useFeatureConfig';
import { usePrefetchAIBox } from './useFetchAIBox';
import { usePrefetchCategories } from './useFetchCategories';
import { usePrefetchFeeds } from './useFetchFeeds';
import { usePrefetchNeedlbox } from './useFetchNeedlbox';
import { usePrefetchPublicApps } from './useFetchPublicApps';
import { usePrefetchUserOnboardingFlags } from './useFetchUserOnboardingFlags';
import { usePrefetchUserData } from './useUserData';
import { useUserStatusPrefetch } from './useUserStatus';

export const usePrefetchInitialData = () => {
  useUserStatusPrefetch();
  usePrefetchUserOnboardingFlags();
  usePrefetchAIBox();
  usePrefetchFeeds();
  usePrefetchNeedlbox();
  usePrefetchPublicApps();
  usePrefetchChannels();
  usePrefetchPrivateApps();
  usePrefetchAllNotebooks();
  usePrefetchAppLibraryData();
  usePrefetchNotificationsData();
  usePrefetchUserData();
  usePrefetchChatbotCategories();
  usePrefetchPricingMessaging();
  usePrefetchUploadConfig();
  usePrefetchSummarisationConfig();
  usePrefetchOrganizationInfo();
  usePrefetchCategories();
  usePrefetchCompanyFilter();
  usePrefetchConfigUI();
};
