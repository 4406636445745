import * as React from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';

import type { NavigateOptionsType, NeedlLinkToType } from '@/router';
import { extractRouterFields } from '@/router/utils/extractRouterFields';

export const NeedlNavLink = React.forwardRef<
  HTMLAnchorElement,
  NeedlNavLinkType
>(({ children, to, options = {}, ...rest }, ref) => {
  const { search: oldQueryParams, state: oldState } = useLocation();

  const { pathname, search, state } = extractRouterFields({
    ...options,
    to,
    oldQueryParams,
    oldState,
    newState: typeof to === 'string' ? {} : to.state,
  });

  return (
    <NavLink {...rest} ref={ref} to={{ pathname, search, state }}>
      {children}
    </NavLink>
  );
});

export type NeedlNavLinkType = Omit<NavLinkProps, 'to'> & {
  to: NeedlLinkToType;
  options?: NavigateOptionsType;
};
