import * as React from 'react';

import { OVERLAY_QUERY_PARAMS, useOverlayType } from '@/components/AppOverlay';

import { useParseOverlayQueryParams } from './useParseOverlayQueryParams';

export const useOverlayQueryParams = () => {
  const overlayType = useOverlayType();
  const overlayParams = useParseOverlayQueryParams();

  return React.useMemo(() => {
    if (!overlayType) {
      throw new Error('Overlay Type not found!');
    }

    const overlayTypeParamsSchema = OVERLAY_QUERY_PARAMS[overlayType];

    if (!overlayTypeParamsSchema) {
      throw new Error('Overlay Params Schema not found!');
    }

    try {
      return overlayTypeParamsSchema.validateSync(overlayParams);
    } catch {
      throw new Error('Invalid Overlay params supplied!');
    }
  }, [overlayParams, overlayType]);
};
