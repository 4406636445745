import * as React from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

import type { NavigateOptionsType, NeedlLinkToType } from '@/router';
import { extractRouterFields } from '@/router/utils/extractRouterFields';

export const NeedlLink = React.forwardRef<HTMLAnchorElement, NeedlLinkType>(
  ({ children, to, options = {}, ...rest }, ref) => {
    const { search: oldQueryParams, state: oldState } = useLocation();

    const { pathname, search, state } = extractRouterFields({
      ...options,
      to,
      oldQueryParams,
      oldState,
      newState: typeof to === 'string' ? {} : to.state,
    });

    return (
      <Link {...rest} ref={ref} to={{ pathname, search, state }}>
        {children}
      </Link>
    );
  }
);

export type NeedlLinkType = Omit<LinkProps, 'to'> & {
  to: NeedlLinkToType;
  options?: NavigateOptionsType;
};
