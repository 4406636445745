// @ts-strict-ignore
import * as React from 'react';

import { useQueryParams } from '@/hooks/useQueryParams';

export const useParseOverlayQueryParams = () => {
  const [_, queryParams] = useQueryParams();

  return React.useMemo(() => {
    const overlayParams = Object.entries(queryParams).reduce(
      (acc, [key, value]) => {
        if (!key.startsWith('overlay-')) {
          return acc;
        }

        return {
          ...acc,
          [key.replace('overlay-', '')]: value,
        };
      },
      {} as Record<string, string>
    );

    return overlayParams;
  }, [queryParams]);
};
