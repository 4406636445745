import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { SAVE_SUMMARY_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

const QUERY_KEY = 'SUMMARISATION_CONFIG';

const fetcher = ({ signal }: QueryFunctionContext) =>
  axiosInstance.get<SummarisationConfigResponse>(
    SAVE_SUMMARY_API_ROUTES.v2_config(),
    { signal }
  );

export const useFetchSummarisationConfig = () => {
  return useQuery(QUERY_KEY, fetcher, {
    select: (data) => data.data.results,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const usePrefetchSummarisationConfig = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEY, {
      queryFn: fetcher,
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  });
};

type SummarisationConfigResponse = {
  results: {
    sources: string[];
    filters: {
      [key: string]: {
        filterName: string;
        filterValues: string[];
      };
    };
  };
};
