import styled from 'styled-components';

import { CommonTextdecorationStyles } from '@/atoms/common';
import type { CommonTextdecorationType } from '@/types';

export const Text = styled.div<CommonTextdecorationType>(
  ({ size = 'sm', bold = false, dark = false, color = 'gray' }) => [
    ...CommonTextdecorationStyles({ bold, color, dark, size }),
    'word-break: break-word;',
  ]
);
