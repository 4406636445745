function onConversationsAPIReady(email) {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push([
    'identify',
    {
      email: email,
    },
  ]);
  _hsq.push(['trackPageView']);
  setTimeout(() => {
    window.HubSpotConversations.widget.refresh();
  }, 2000);
}

export const setHubspotIdentity = function (email) {
  if (window.HubSpotConversations) {
    onConversationsAPIReady(email);
  } else {
    window.hsConversationsOnReady = [() => onConversationsAPIReady(email)];
  }
};

export const deleteHubspotIdentity = async function () {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['revokeCookieConsent']);

  return new Promise((res) => {
    setTimeout(() => {
      document.cookie =
        'hubspotutk=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.HubSpotConversations?.clear({ resetWidget: true });
      res();
    }, 1000);
  });
};
