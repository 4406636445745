import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { useOverlayType } from '@/components/AppOverlay';
import { useShowAskNeedl } from '@/features/ChatBotV2/hooks/useShowAskNeedl';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useNavigate } from '@/router';

export const useAskNeedlPreviewToggle = () => {
  const { url } = useRouteMatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [_, queryParams] = useQueryParams();
  const overlayType = useOverlayType();
  const showAskNeedl = useShowAskNeedl();

  const isAskNeedlPreviewOpen =
    queryParams['overlay-preview_askneedl'] &&
    queryParams['overlay-preview_askneedl'] === 'true' &&
    showAskNeedl &&
    overlayType === 'preview';

  const openPreviewAskNeedl = React.useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!isAskNeedlPreviewOpen && showAskNeedl && overlayType === 'preview') {
      searchParams.set('overlay-preview_askneedl', 'true');

      navigate.push(
        {
          pathname: url,
          search: searchParams.toString(),
        },
        {
          location,
          ...location.state,
        },
        {
          removeOldQueryParams: true,
          removeOldState: true,
        }
      );
    }
  }, [
    location,
    navigate,
    url,
    isAskNeedlPreviewOpen,
    showAskNeedl,
    overlayType,
  ]);

  const closePreviewAskNeedl = React.useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    if (isAskNeedlPreviewOpen && showAskNeedl && overlayType === 'preview') {
      searchParams.delete('overlay-preview_askneedl');

      navigate.push(
        {
          pathname: url,
          search: searchParams.toString(),
        },
        {
          location,
          ...location.state,
        },
        {
          removeOldQueryParams: true,
          removeOldState: true,
        }
      );
    }
  }, [
    location,
    navigate,
    url,
    isAskNeedlPreviewOpen,
    showAskNeedl,
    overlayType,
  ]);

  return { isAskNeedlPreviewOpen, openPreviewAskNeedl, closePreviewAskNeedl };
};
