import { useFeatureFlag } from '@/hooks/query/useFeatureFlag';
import { useUserStatus } from '@/hooks/query/useUserStatus';

export const useNewUser = () => {
  const { data: features } = useFeatureFlag();
  const { userStatusData, userStatusLoading, userStatusFetching } =
    useUserStatus();

  return {
    isNewUser:
      features?.is_onboarding_v2 && userStatusData?.data?.user_status === 'new',
    isLoading: userStatusLoading || userStatusFetching,
  };
};
