const SETTINGS_BASE_ROUTE = '/settings';
const AUTH_BASE_ROUTE = '/auth';
const ONBOARDING_BASE_ROUTE = '/onboarding';
const CONNECT_BASE_ROUTE = '/connect';
const OAUTH_BASE_ROUTE = '/oauth';
const NEEDL_PULSE_BASE_ROUTE = '/pulse';
export const DOCUMENT_ROUTE = '/document';
export const IMAGE_ROUTE = '/image';
export const HOME_ROUTE = '/home';
export const NOTIFICATIONS_ROUTE = '/notifications';
export const EXTENSION_ROUTE = '/extension';

export const SETTINGS_ROUTES = {
  BASE_ROUTE: SETTINGS_BASE_ROUTE,
  STORAGE: `${SETTINGS_BASE_ROUTE}/storage`,
  TAGS: `${SETTINGS_BASE_ROUTE}/tags`,
  FEEDS: `${SETTINGS_BASE_ROUTE}/feeds`,
  CONTACTS: `${SETTINGS_BASE_ROUTE}/contacts`,
  CHANNELS: `${SETTINGS_BASE_ROUTE}/channels`,
  USER_SETTINGS: `${SETTINGS_BASE_ROUTE}/user-settings`,
  PORTFOLIOS: `${SETTINGS_BASE_ROUTE}/portfolios`,
  PAGE: `${SETTINGS_BASE_ROUTE}/:page`,
};

export const SETTINGS_STORAGE_ROUTES = {
  LARGE_FILES: `${SETTINGS_ROUTES.STORAGE}/large files`,
  EMPTY: `${SETTINGS_ROUTES.STORAGE}/empty`,
  APP: `${SETTINGS_ROUTES.STORAGE}/:app`,
  APP_LARGE_FILES: `${SETTINGS_ROUTES.STORAGE}/:app/large files`,
  APP_OTHERS_LARGE_FILES: `${SETTINGS_ROUTES.STORAGE}/:app/others/large files`,
  APP_TYPE_LARGE_FILES: `${SETTINGS_ROUTES.STORAGE}/:app/:type/large files`,
  APP_CONVERSATIONS: `${SETTINGS_ROUTES.STORAGE}/:app/conversations`,
  APP_NOTEBOOKS: `${SETTINGS_ROUTES.STORAGE}/:app/notebooks`,
  APP_CONTACTS: `${SETTINGS_ROUTES.STORAGE}/:app/contacts`,
  APP_OTHERS: `${SETTINGS_ROUTES.STORAGE}/:app/others`,
  APP_TYPE: `${SETTINGS_ROUTES.STORAGE}/:app/:type`,
};

export const SETTINGS_CONTACTS_ROUTES = {
  ID: `${SETTINGS_ROUTES.CONTACTS}/:id`,
  ID_MERGE: `${SETTINGS_ROUTES.CONTACTS}/:id/merge`,
  ID_MOVE_CONTACT_ID: `${SETTINGS_ROUTES.CONTACTS}/:id/move/:contactId`,
};

export const SETTINGS_FEEDS_ROUTES = {
  ROOT: `${SETTINGS_ROUTES.FEEDS}/root`,
  FOLDER_ID: `${SETTINGS_ROUTES.FEEDS}/:folderId`,
};

export const SETTINGS_CHANNELS_ROUTES = {
  SHARE_BOARD_ID: `${SETTINGS_ROUTES.CHANNELS}/share/:boardId`,
  SHARE_BOARD_ID_NEW: `${SETTINGS_ROUTES.CHANNELS}/share/:boardId/new`,
  CHANNEL_ID: `${SETTINGS_ROUTES.CHANNELS}/:channelId`,
  CHANNEL_ID_FEEDS: `${SETTINGS_ROUTES.CHANNELS}/:channelId/feeds`,
  CHANNEL_ID_MEMBERS: `${SETTINGS_ROUTES.CHANNELS}/:channelId/members`,
};

export const SETTINGS_PORTFOLIO_ROUTES = {
  PORTFOLIO_ID: `${SETTINGS_ROUTES.PORTFOLIOS}/:portfolioId`,
  CREATE_PORTFOLIO: `${SETTINGS_ROUTES.PORTFOLIOS}/create-portfolio`,
};

export const AUTH_ROUTES = {
  BASE_ROUTE: AUTH_BASE_ROUTE,
  SIGNIN: `${AUTH_BASE_ROUTE}/signin`,
  SIGNUP: `${AUTH_BASE_ROUTE}/signup`,
  FORGOT_PASSWORD: `${AUTH_BASE_ROUTE}/forgot-password`,
  RESET_PASSWORD: `${AUTH_BASE_ROUTE}/reset-password`,
};

export const ONBOARDING_ROUTES = {
  BASE_ROUTE: ONBOARDING_BASE_ROUTE,
  WELCOME: `${ONBOARDING_BASE_ROUTE}/welcome`,
  SOURCE: `${ONBOARDING_BASE_ROUTE}/source`,
};

export const CONNECT_ROUTES = {
  BASE_ROUTE: CONNECT_BASE_ROUTE,
  APP: `${CONNECT_BASE_ROUTE}/app`,
  PAGE: `${CONNECT_BASE_ROUTE}/:page`,
  WEBSITE: `${CONNECT_BASE_ROUTE}/website`,
  WEBSITE_FOLLOWED: `${CONNECT_BASE_ROUTE}/website/followed`,
};

export const OAUTH_ROUTES = {
  BASE_ROUTE: OAUTH_BASE_ROUTE,
  REDIRECT: `${OAUTH_BASE_ROUTE}/redirect`,
};

const SOURCE_NAV_MANAGER_BASE_ROUTE = '/managers';
export const SOURCE_NAV_MANAGER_ROUTES = {
  BASE_ROUTE: SOURCE_NAV_MANAGER_BASE_ROUTE,
  ASSISTANTS_ROUTE: `${SOURCE_NAV_MANAGER_BASE_ROUTE}/assistants`,
};

export const ASSISTANTS_ROUTES = {
  BASE_ROUTE: SOURCE_NAV_MANAGER_ROUTES.ASSISTANTS_ROUTE,
  DASHBOARD: `${SOURCE_NAV_MANAGER_ROUTES.ASSISTANTS_ROUTE}/dashboard`,
  ACTIVITY: `${SOURCE_NAV_MANAGER_ROUTES.ASSISTANTS_ROUTE}/activity`,
  BUILDER: `${SOURCE_NAV_MANAGER_ROUTES.ASSISTANTS_ROUTE}/builder`,
};

export const NEEDL_PULSE_ROUTES = {
  BASE_ROUTE: NEEDL_PULSE_BASE_ROUTE,
  SIGN_IN_PAGE_ROUTE: `${NEEDL_PULSE_BASE_ROUTE}/auth/signin`,
  WHATSAPP_VERIFICATION_PAGE_ROUTE: `${NEEDL_PULSE_BASE_ROUTE}/whatsapp-verification`,
  UPDATE_PORTFOLIO_PAGE_ROUTE: `${NEEDL_PULSE_BASE_ROUTE}/update-portfolio`,
  PROFILE_PAGE_ROUTE: `${NEEDL_PULSE_BASE_ROUTE}/settings/user-settings`,
};

export const REDIRECT_BASE_ROUTE = '/redirect';

export const REDIRECT_ROUTES = {
  DOCUMENT: `${REDIRECT_BASE_ROUTE}/document`,
};
