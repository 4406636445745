import * as React from 'react';

import {
  type AskNeedlContextType,
  useAskNeedlContext,
} from '@/features/ChatBotV2/context';

/**
 * Refer the [State Slicing HOCs](https://github.com/ai-needl/needl-ai/blob/master/src/frontend/README.md#creating-state-slicing-hocs-for-efficient-rendering) for more details on why we need this.
 */
export function withAskNeedlStateSlice<RequiredProp, DerivedProp>(
  Component: React.ComponentType<DerivedProp>,
  callback: (
    props: RequiredProp,
    state: AskNeedlContextType
  ) => JSX.IntrinsicAttributes<DerivedProp> &
    React.PropsWithRef<DerivedProp & { children?: React.ReactNode }>
) {
  const MemoComponent = React.memo(Component);

  /**
   * resolve this issue later!
   * https://github.com/ai-needl/needl-ai/pull/13817#discussion_r1289551329
   */
  const Wrapper = (props: RequiredProp) => {
    const state = useAskNeedlContext();
    const slicedState = callback(props, state);

    return <MemoComponent {...slicedState} />;
  };

  return React.memo(Wrapper);
}
