// @ts-strict-ignore
import moment from 'moment';
import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { CHANNELS_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import type { ChannelType } from '@/features/Channels/common';

export type ResponseType = {
  channels: ChannelType[];
};

type useChannelNameReturnType = {
  isLoading: boolean;
  validateChannelName: (input: string) => string;
};

export const QUERY_KEY = 'channels';
const fetchChannels = ({ signal }: QueryFunctionContext) => {
  return axiosInstance.get<ResponseType>(CHANNELS_API_ROUTES.index(), {
    signal,
  });
};

export const useFetchChannels = () => {
  const { data, isLoading, isError, isFetching } = useQuery(
    [QUERY_KEY],
    fetchChannels,
    {
      staleTime: timeInMs.FIFTEEN_SECONDS,
      select: (data) => {
        const results = data.data.channels;
        const currentEpoch = moment().unix();
        const currentDate = moment.unix(currentEpoch);

        return results.map((result) => {
          const resultDate = moment.unix(result.created_at);
          return {
            ...result,
            isNew: resultDate.isSame(currentDate, 'day'),
          };
        });
      },
    }
  );

  return { isLoading, isError, isFetching, channels: data ?? [] };
};

export const useInvalidateFetchChannels = (): (() => void) => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    queryClient.invalidateQueries([QUERY_KEY]);
  }, [queryClient]);
};

export const usePrefetchChannels = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery([QUERY_KEY], {
      queryFn: fetchChannels,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};

export const useChannelName = (
  defaultValue: string
): useChannelNameReturnType => {
  const { isLoading, channels } = useFetchChannels();
  const channelNames = channels.map(({ name }) => name);

  const validateChannelName = React.useCallback(
    (inputValue) => {
      const inputChannelName = inputValue.trim();
      let error = '';
      if (!inputChannelName) {
        error = 'Group Name is required';
      } else if (!/^[-\w ]*[a-zA-Z0-9]+[-\w ]*$/.test(inputChannelName)) {
        error =
          'Sorry, only letters (a-z), numbers (0-9), hyphen (-) and underscore (_) are allowed.';
      } else if (
        channelNames.some(
          (channelName) =>
            channelName !== defaultValue &&
            channelName.toLowerCase() === inputChannelName.toLowerCase()
        )
      ) {
        error = 'Group Name already exists in your organization!';
      }
      return error;
    },
    [channelNames, defaultValue]
  );
  return { isLoading, validateChannelName };
};
