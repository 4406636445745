import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Button } from '@/components/Button/Button';
import { Modal, ModalBody, ModalFooter } from '@/components/Modal/Modal';
import { ModalHeader } from '@/components/Modal/ModalHeader';

export interface ConfirmModalType {
  primaryLoading?: boolean;
  /**
   * disable Primary Button
   */
  disablePrimaryButton?: boolean;
  /**
   * title icon
   */
  icon?: React.ReactNode;
  /**
   * icon variant
   */
  iconVariant?: 'error' | 'info' | 'standard' | 'success' | 'warn';
  /**
   * loading state
   */
  loading?: boolean;
  /**
   * Whether to close the modal dialog when the mask (area outside the modal) is clicked
   */
  closeOnOutsideClick?: boolean;
  /**
   * modal content
   */
  modalContent: React.ReactNode;
  /**
   * modal footer
   */
  modalFooter?: JSX.Element;
  /**
   * modal title
   */
  modalHeading: string;
  /**
   * modal dismiss
   */
  onDismiss?: () => void;
  /**
   * handler for the primary button.
   */
  onPrimarySubmit?: (e: React.MouseEvent) => void;
  /**
   * handler for the secondary button.
   */
  onSecondarySubmit?: (e: React.MouseEvent) => void;
  /**
   * text for the primary button
   */
  primaryButtonText?: string;
  /**
   * text for the secondary button
   */
  secondaryButtonText?: string;
  /**
   * show/hide modal
   */
  showModal: boolean;
}

const Icon = styled.div<Pick<ConfirmModalType, 'icon' | 'iconVariant'>>(
  ({ iconVariant }) => [
    tw`fill-current h-6 w-6 text-gray-700`,
    iconVariant === 'success' && tw`text-emerald-900`,
    iconVariant === 'info' && tw`text-blue-900`,
    iconVariant === 'warn' && tw`text-yellow-200`,
    iconVariant === 'error' && tw`text-red-400`,
  ]
);

const SpinnerContainer = styled.div`
  ${tw`flex justify-center items-center`}
`;

const StyledModalContent = styled(ModalBody)`
  ${tw`body-sm whitespace-pre-line`}
`;

const ModalWrapper = styled.div`
  ${tw`text-gray-700 flex-col justify-between items-center w-full`}
`;

export const ConfirmModal: React.FunctionComponent<ConfirmModalType> = ({
  disablePrimaryButton,
  icon,
  iconVariant,
  loading = false,
  closeOnOutsideClick,
  modalContent,
  modalHeading,
  onDismiss,
  onPrimarySubmit,
  onSecondarySubmit,
  primaryButtonText,
  secondaryButtonText,
  showModal,
  primaryLoading = false,
}) => {
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <Modal
      ariaLabel='confirm modal'
      showDialog={showModal}
      onDismiss={onDismiss}
      closeOnOutsideClick={closeOnOutsideClick}
      initialFocusRef={buttonRef}
    >
      {loading ? (
        <SpinnerContainer style={{ height: '175px' }}>
          <CircularProgress />
        </SpinnerContainer>
      ) : (
        <ModalWrapper>
          <ModalHeader
            title={modalHeading}
            onDismiss={onDismiss}
            icon={
              icon ? (
                <Icon icon={icon} iconVariant={iconVariant}>
                  {icon}
                </Icon>
              ) : null
            }
          />
          <StyledModalContent>{modalContent}</StyledModalContent>
          <ModalFooter>
            {secondaryButtonText && onSecondarySubmit ? (
              <Button
                variant='secondary'
                size='medium'
                id='confirm-modal-secondary-button'
                onClick={onSecondarySubmit}
                label={secondaryButtonText}
              />
            ) : null}
            {primaryButtonText && onPrimarySubmit ? (
              <Button
                variant='primary'
                size='medium'
                id='confirm-modal-primary-button'
                onClick={onPrimarySubmit}
                loading={primaryLoading}
                label={primaryButtonText}
                aria-label='primary button'
                ref={buttonRef}
                {...(disablePrimaryButton && { disabled: true })}
              />
            ) : null}
          </ModalFooter>
        </ModalWrapper>
      )}
    </Modal>
  );
};
