import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { CONNECT_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { useNewUser } from '@/features/Feed/hooks/useNewUser';
import type { UserStatusType } from '@/types';

const fetcher = ({ signal }: QueryFunctionContext) =>
  axiosInstance.get<UserStatusType>(CONNECT_API_ROUTES.userStatus(), {
    signal,
  });

const QUERY_KEY = ['user_status'];

const useUserStatus = () => {
  const { data, isLoading, isFetching } = useQuery(QUERY_KEY, fetcher, {
    staleTime: Infinity,
  });

  return {
    userStatusData: data,
    userStatusFetching: isFetching,
    userStatusLoading: isLoading,
  };
};

const useRefetchUserStatus = (): (() => Promise<void> | undefined) => {
  const queryClient = useQueryClient();
  const { isNewUser, isLoading } = useNewUser();

  return () => {
    if (isLoading) {
      return;
    }
    if (!isNewUser) {
      return;
    }
    return queryClient.invalidateQueries(QUERY_KEY, { refetchInactive: true });
  };
};

const useUserStatusPrefetch = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEY, {
      queryFn: fetcher,
      staleTime: Infinity,
    });
  }, [queryClient]);
};

export { useUserStatus, useUserStatusPrefetch, useRefetchUserStatus };
