import Markdown from 'react-markdown';

import { useScrollIntoView } from '@/components/ChatBot/hooks';

export const BotStreamedMessage = ({
  streamingAnswer,
}: {
  streamingAnswer: string;
}) => {
  return (
    <p className='whitespace-pre-wrap' ref={useScrollIntoView()}>
      <Markdown>{streamingAnswer.replace('[#R', '[R')}</Markdown>
    </p>
  );
};

export const BotError = () => {
  return <p className='text-gray-900'>Uh-oh! Something went wrong</p>;
};
