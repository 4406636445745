import { DialogContent, DialogOverlay } from '@reach/dialog';
import type { Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { useOverlayState } from '@/components/AppOverlay';
import { useShiftOverlay } from '@/components/AppOverlay/hooks/useShfitOverlay';
import { IconButton } from '@/components/IconButton/IconButton';
import { useOverlayWidthContext } from '@/components/Preview/hooks/useOverlayWidthDetails';
import { useNavActionDispatch } from '@/context/NavContext';
import { useResponsiveConstants } from '@/hooks/responsive/useResponsiveConstants';
import { usePrevious } from '@/hooks/usePrevious';
import CloseIcon from '@/icons/close-sm.svg?react';

const transition: Transition = {
  type: 'spring',
  damping: 25,
  stiffness: 200,
};

const StyledOverlay = styled(DialogOverlay)<{
  size: OverlaySize;
  widthPercentage: string;
}>`
  ${tw`fixed shadow-2xl top-0 bottom-0 right-0 left-0 h-full bg-transparent [isolation:isolate]`}
  ${({ size, widthPercentage }) => {
    const leftWidth = 100 - parseInt(widthPercentage);
    switch (size) {
      case 'large':
        return tw`lg:left-[calc(100%-min(75%,1088px))]`;
      case 'medium':
        return tw`lg:left-[calc(100%-min(50%,864px))]`;
      case 'small':
        return tw`left-[calc(100%-382px)]`;
      case 'variable':
        return {
          left: `calc(${leftWidth}vw)`,
        };
    }
  }}
`;

const AnimatedOverlay = motion(StyledOverlay);

const StyledIconButton = styled(IconButton)`
  ${tw`hidden lg:block p-2 absolute rounded-r-none rounded-l top-11 -left-10 bg-gray-300 shadow-2xl`}
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${tw`w-6 h-6 text-gray-700 fill-current`}
`;

const StyledDialogContent = styled(DialogContent)<{ size: OverlaySize }>`
  ${tw`fixed top-0 right-0 w-full h-full bg-white shadow-2xl overflow-auto mx-auto lg:max-w-none lg:mx-0`}
  ${({ size }) => {
    switch (size) {
      case 'large':
        return tw`lg:max-w-[1088px]`;
      case 'medium':
        return tw`lg:max-w-[864px]`;
      case 'small':
        return tw`lg:max-w-[382px]`;
      case 'variable':
        return tw`w-full`;
    }
  }}
`;

const CustomDialogContent = React.forwardRef<
  HTMLDivElement,
  CustomDialogContentType
>(({ children, size = 'medium' }, ref) => {
  const { showOverlay } = useOverlayState();

  return (
    <StyledDialogContent size={size} ref={ref}>
      {showOverlay ? children : null}
    </StyledDialogContent>
  );
});

export const ResizableHandle = styled.div`
  ${tw`absolute shadow-sm z-50 top-0 -left-3 w-6 h-full cursor-ew-resize`}

  &:active,
  &:focus {
    ${tw`shadow-lg`}
  }

  @media (hover: none) {
    &:active,
    &:focus {
      ${tw`shadow-lg`}
    }
  }

  &:focus-visible {
    ${tw`shadow-lg`}
  }
`;

export const Overlay: React.FunctionComponent<OverlayPropsType> = ({
  children,
  showDialog,
  onDismiss,
  size = 'medium',
  hideCloseButton = false,
}) => {
  const { tabletAndBelow } = useResponsiveConstants();
  const navDispatch = useNavActionDispatch();
  const previouslyOpen = usePrevious(showDialog);
  const dialogRef = React.useRef<HTMLDivElement | null>(null);
  const shiftOverlay = useShiftOverlay();
  const {
    overlayWidthVWDetails,
    handleOverlayMouseDown,
    shiftWidth,
    setShiftWidth,
  } = useOverlayWidthContext();

  React.useEffect(() => {
    if (showDialog) {
      navDispatch({ type: 'close' });
    } else if (previouslyOpen && !showDialog) {
      navDispatch({ type: 'open', source: 'overlay' });
    }
  }, [navDispatch, showDialog, previouslyOpen]);

  React.useEffect(() => {
    const updateOffset = () => {
      if (dialogRef.current && shiftOverlay) {
        //For shifting the askNeedl part out of the screen when closed
        //Width of askNeedl container is min(382px, 40%) of the parent
        setShiftWidth(
          Math.min(40, (382 / dialogRef.current.offsetWidth) * 100)
        );
      }
    };

    const resetOffset = () => {
      setShiftWidth(0);
    };

    updateOffset();

    window.addEventListener('resize', updateOffset);

    return () => {
      resetOffset();
      window.removeEventListener('resize', updateOffset);
    };
  }, [setShiftWidth, shiftOverlay]);

  if (tabletAndBelow) {
    return null;
  }

  return (
    <AnimatePresence>
      {showDialog ? (
        <AnimatedOverlay
          isOpen
          dangerouslyBypassScrollLock
          allowPinchZoom
          initial={{ opacity: 0, x: '100%' }}
          animate={{
            opacity: 1,
            x: `${shiftWidth}%`,
            transition,
          }}
          exit={{ opacity: 0, x: '100%', transition }}
          dangerouslyBypassFocusLock
          size={size}
          widthPercentage={`${overlayWidthVWDetails.current}`}
        >
          {!hideCloseButton ? (
            <StyledIconButton
              size='large'
              circular={false}
              background
              onClick={onDismiss}
            >
              <StyledCloseIcon />
            </StyledIconButton>
          ) : null}
          {size === 'variable' &&
            overlayWidthVWDetails.max > overlayWidthVWDetails.min && (
              <ResizableHandle
                onMouseDown={handleOverlayMouseDown}
                onTouchStart={handleOverlayMouseDown}
              />
            )}
          <CustomDialogContent ref={dialogRef} size={size}>
            {children}
          </CustomDialogContent>
        </AnimatedOverlay>
      ) : null}
    </AnimatePresence>
  );
};

type CustomDialogContentType = {
  /**
   * children node
   */
  children: React.ReactNode;
  /**
   * size of the overlay
   */
  size?: OverlaySize;
};

export type OverlayPropsType = {
  /**
   * children node
   */
  children: React.ReactNode;
  /**
   * dismiss overlay
   */
  onDismiss: () => void;
  /**
   * whether to show the overlay
   */
  showDialog: boolean;
  /**
   * size of the overlay
   */
  size?: OverlaySize;
  /**
   * whether to hide the close button
   */
  hideCloseButton?: boolean;
};
export type OverlaySize = 'large' | 'medium' | 'small' | 'variable';
