import type * as React from 'react';
import tw, { styled } from 'twin.macro';

export type AnimatedNavItemType = {
  selected: boolean;
  disabled?: boolean;
  replace?: boolean;
  count?: number | string;
  textDark?: boolean;
  children?: React.ReactNode;
  variant?: 'default' | 'gray';
};

export const ItemStyles = ({
  selected,
  disabled,
  textDark,
  variant,
}: AnimatedNavItemType) => [
  tw`px-2 pt-2 pb-1 mb-1 relative sub-heading-sm-bold  bg-white transition-colors duration-300 block before:absolute before:inset-0 before:-bottom-1 before:transition-colors before:duration-300 before:-z-10`,
  textDark ? tw`text-gray-900` : tw`text-gray-500`,
  selected && tw`text-gray-900`,
  disabled ? tw`text-gray-300` : tw`hover:before:bg-gray-100 hover:bg-gray-100`,
  variant === 'gray' && tw`bg-gray-100`,
];

export const NumberOfTabResult = styled.span`
  ${tw`ml-2 inline-block relative body-xs-bold text-gray-700 bg-white transition-colors duration-300`}
`;

export const SelectedIndicator = styled.div`
  ${tw`absolute left-0 right-0 -bottom-1 h-0.5 bg-gray-900 mx-2`}
`;
