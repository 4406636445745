import { DialogContent, DialogOverlay } from '@reach/dialog';
import type { HTMLMotionProps, Transition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

type ModalType = {
  /**
   * aria-label
   */
  ariaLabel: string;
  /**
   * children node
   */
  children: React.ReactNode;
  /**
   * css classes
   */
  className?: string;
  /**
   * initial focus ref for dialog
   */
  initialFocusRef?: React.MutableRefObject<HTMLElement | null>;
  /**
   * Whether to close the modal dialog when the mask (area outside the modal) is clicked
   */
  closeOnOutsideClick?: boolean;
  /**
   * modal toggle event
   */
  onDismiss?: () => void;
  /**
   * will the modal be displayed or not
   */
  showDialog: boolean;
  /**
   * modal size
   */
  size?: 'default' | 'large' | 'small' | 'xl';
  /**
   * css inline styles
   */
  style?: React.CSSProperties;
};

type ModalOverlayType = Pick<
  ModalType,
  | 'children'
  | 'className'
  | 'closeOnOutsideClick'
  | 'initialFocusRef'
  | 'onDismiss'
  | 'style'
>;

type ModalContentType = Pick<
  ModalType,
  'ariaLabel' | 'children' | 'className' | 'size' | 'style'
>;

const StyledOverlay = styled(DialogOverlay)`
  ${tw`fixed top-0 right-0 bottom-0 left-0 h-screen w-screen bg-black bg-opacity-60 [isolation:isolate]`}
`;
const AnimatedOverlay = motion(StyledOverlay);

const StyledDialogContent = styled(DialogContent)<Pick<ModalType, 'size'>>`
  ${tw`bg-white rounded-lg shadow-fab fixed top-1/2 left-1/2 outline-none`}
  ${({ size }) => size === 'default' && tw`w-modal-default`}
  ${({ size }) => size === 'small' && tw`w-modal-sm`}
  ${({ size }) => size === 'large' && tw`w-modal-lg`}
  ${({ size }) => size === 'xl' && tw`w-modal-xl`}
`;
const AnimatedDialogContent = motion(StyledDialogContent);

const ModalBody = styled.div`
  ${tw`px-6 py-2`}
`;

const ModalFooter = styled.div<{ divide?: boolean }>`
  ${tw`flex justify-end space-x-6 items-center px-6 py-4`}
  ${({ divide }) => divide && tw`border-solid border-gray-300 border-t`}
`;

const transition: Transition = {
  type: 'spring',
  damping: 25,
  stiffness: 200,
};

const ContentAnimation: Pick<
  HTMLMotionProps<'div'>,
  'animate' | 'exit' | 'initial'
> = {
  initial: {
    opacity: 0,
    x: '-50%',
    y: 'calc(-50% - 24px)',
  },
  animate: {
    opacity: 1,
    x: '-50%',
    y: '-50%',
    transition,
  },
  exit: {
    opacity: 0,
    x: '-50%',
    y: 'calc(-50% + 24px)',
    transition,
  },
};

const ModalOverlay = ({
  children,
  className = '',
  closeOnOutsideClick = true,
  onDismiss,
  initialFocusRef,
  style = {},
}: ModalOverlayType): JSX.Element => {
  return (
    <AnimatedOverlay
      className={className}
      style={style}
      onDismiss={closeOnOutsideClick ? onDismiss : () => null}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition,
      }}
      exit={{
        opacity: 0,
        transition: {
          ...transition,
          delay: 0.15,
        },
      }}
      initialFocusRef={initialFocusRef ? initialFocusRef : undefined}
    >
      {children}
    </AnimatedOverlay>
  );
};

const ModalContent = ({
  ariaLabel,
  children,
  className = '',
  size = 'default',
  style = {},
}: ModalContentType): JSX.Element => {
  return (
    <AnimatedDialogContent
      aria-label={ariaLabel}
      className={className}
      size={size}
      style={style}
      {...ContentAnimation}
    >
      {children}
    </AnimatedDialogContent>
  );
};

const Modal: React.FunctionComponent<ModalType> = ({
  ariaLabel,
  children,
  className,
  closeOnOutsideClick,
  initialFocusRef,
  showDialog,
  style,
  size,
  onDismiss,
}) => {
  return (
    <AnimatePresence>
      {showDialog ? (
        <ModalOverlay
          onDismiss={onDismiss}
          closeOnOutsideClick={closeOnOutsideClick}
          initialFocusRef={initialFocusRef}
        >
          <ModalContent
            ariaLabel={ariaLabel}
            className={className}
            size={size}
            style={style}
          >
            {children}
          </ModalContent>
        </ModalOverlay>
      ) : null}
    </AnimatePresence>
  );
};

export type { ModalType };
export {
  ContentAnimation,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
};
