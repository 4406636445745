import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { CATEGORIES_API_ROUTE } from '@/api-routes';
import { axiosInstance } from '@/axios';
import { useFeatureFlag } from '@/hooks/query/useFeatureFlag';

const QUERY_KEY = 'all-categories-info';

export const useFetchCategories = () => {
  const {
    data: features,
    isLoading: isFeaturesLoading,
    isError: isFeaturesError,
  } = useFeatureFlag();

  const {
    isLoading: isCategoriesDataLoading,
    isError: isCategoriesError,
    data: categoriesData,
    ...queryData
  } = useQuery([QUERY_KEY], fetcher, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const data = React.useMemo(() => {
    if (!categoriesData?.data?.results) {
      return categoriesData;
    }
    if (isFeaturesLoading || !features || !features?.is_sharepoint) {
      categoriesData.data.results = categoriesData.data.results.map(
        (category) => {
          if (category.key === 'drives') {
            category.sources = category.sources.filter(
              (source) => source.key !== 'sharepoint'
            );
          }
          return category;
        }
      );
    }
    return categoriesData;
  }, [features, isFeaturesLoading, categoriesData]);

  return React.useMemo(
    () => ({
      ...queryData,
      isLoading: isCategoriesDataLoading || isFeaturesLoading,
      isError: isCategoriesError || isFeaturesError,
      data,
    }),
    [
      data,
      isCategoriesError,
      isCategoriesDataLoading,
      isFeaturesError,
      isFeaturesLoading,
      queryData,
    ]
  );
};

export const usePrefetchCategories = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEY, {
      queryFn: fetcher,
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  }, [queryClient]);
};

const fetcher = async () => {
  return axiosInstance.get<ResponseType>(CATEGORIES_API_ROUTE.index());
};

export type Source = {
  key: string;
  label: string;
  connected?: boolean;
  hasData?: boolean;
};

export type Category = {
  key: string;
  label: string;
  sources: Source[];
};

type ResponseType = {
  results: Category[];
};
