import * as React from 'react';
import ReactHotToast from 'react-hot-toast';

import { trackEvent } from '@/analytics/trackEvent';
import { useFeedbackMutation } from '@/components/ChatBot/hooks';
import { CommonErrorToast } from '@/utils/toasts';

export const useBotMessageHook = ({
  messageId,
  prompt,
  category,
}: BotMessageType) => {
  const [selectedFeedback, setSelectedFeedback] = React.useState<
    'correct' | 'incorrect' | null
  >(null);
  const feedbackMutation = useFeedbackMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (e.nativeEvent instanceof SubmitEvent) {
      const value = e.nativeEvent.submitter?.getAttribute('value');
      trackEvent('ask_needl_feedback', {
        feedback: value,
        question: prompt,
        ...(category && { category }),
      });
      feedbackMutation.mutate(
        {
          feedback: value ?? '',
          message_id: messageId,
        },
        {
          onSuccess: () => {
            ReactHotToast.success('Thanks for your feedback!');
          },
          onError: () => {
            CommonErrorToast();
          },
        }
      );
    } else {
      CommonErrorToast();
    }
  };

  const handleCopy = () => {
    ReactHotToast.success('Copied to clipboard!', { duration: 1000 });
  };

  return {
    handleSubmit,
    handleCopy,
    selectedFeedback,
    setSelectedFeedback,
    feedbackMutation,
  };
};

type BotMessageType = {
  messageId: string;
  prompt: string;
  category?: string;
};
