import * as yup from 'yup';

import type { OverlayType } from '@/components/AppOverlay';
import { OVERLAY_TYPES } from '@/components/AppOverlay';
import { useQueryParams } from '@/hooks/useQueryParams';

export const useOverlayType = () => {
  const [_, queryParams] = useQueryParams();

  if (queryParams['overlay-type']) {
    try {
      return yup
        .string<OverlayType>()
        .oneOf(Object.values(OVERLAY_TYPES))
        .validateSync(queryParams['overlay-type']);
    } catch {
      return null;
    }
  }

  return null;
};
