import type { AxiosResponse } from 'axios';
import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { USER_API_ROUTES } from '@/api-routes/user';
import { axiosInstance } from '@/axios';
import { timeInMs } from '@/constants/time';
import {
  deleteHubspotIdentity,
  setHubspotIdentity,
} from '@/utils/hubspotUtils';

const QUERY_KEY = ['user_data'];

const fetcher = ({ signal }: QueryFunctionContext) =>
  axiosInstance.get<UserResponse>(USER_API_ROUTES.baseRoute(), { signal });

export const useUserData = () => {
  return useQuery<AxiosResponse<UserResponse>, unknown, UserType>(
    QUERY_KEY,
    fetcher,
    {
      select: (user) => {
        const payload = user.data.results;

        return {
          email: payload.email_id,
          name: payload.username,
          industry: payload.industry,
          profession: payload.profession,
          whatsapp_number: payload.whatsapp_number,
        };
      },
      onSuccess: async (data) => {
        try {
          await deleteHubspotIdentity();
          setHubspotIdentity(data.email);
        } catch (e) {
          console.error('Error at hubspot', e);
        }
      },
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const usePrefetchUserData = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEY, {
      queryFn: fetcher,
      staleTime: timeInMs.FIFTEEN_SECONDS,
    });
  }, [queryClient]);
};

export const useInvalidateUserData = () => {
  const queryClient = useQueryClient();

  return React.useCallback(() => {
    return queryClient.invalidateQueries(QUERY_KEY, { refetchInactive: true });
  }, [queryClient]);
};

export type UserType = {
  email: string;
  name: string;
  industry: string;
  profession: string;
  whatsapp_number: string;
};

type UserResponseType = {
  email_id: string;
  username: string;
  industry: string;
  profession: string;
  whatsapp_number: string;
};
type UserResponse = { results: UserResponseType };
