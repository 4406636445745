import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const StyledSVG = styled.svg`
  ${tw`animate-spin w-5 h-5`}
  & .loader-track {
    fill: #d9d9d9;
    fill-opacity: 0.3;
  }
  & .loader-segment {
    fill: #ffffff;
  }
`;

const CircularLoader = React.forwardRef<
  SVGSVGElement,
  Omit<React.HTMLAttributes<SVGSVGElement>, 'children' | 'css'>
>((props, ref) => {
  return (
    <StyledSVG
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      role='progressbar'
      ref={ref}
      {...props}
    >
      <path
        d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z'
        className='loader-track'
        fillRule='evenodd'
        clipRule='evenodd'
      />
      <mask id='path-2-inside-1' className='loader-segment'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.8421 2.04379C10.5654 2.01484 10.2844 2 10 2C5.62962 2 2.07775 5.50449 2.00126 9.85661H0.00100708C0.0777022 4.39987 4.52503 0 10 0C10.2836 0 10.5645 0.0118067 10.8421 0.0349554V2.04379Z'
        />
      </mask>
      <path
        d='M10.8421 2.04379L10.7381 3.03837C11.0198 3.06784 11.3009 2.97641 11.5114 2.78677C11.7219 2.59714 11.8421 2.32712 11.8421 2.04379H10.8421ZM2.00126 9.85661V10.8566C2.54669 10.8566 2.99152 10.4195 3.0011 9.87418L2.00126 9.85661ZM0.00100708 9.85661L-0.998894 9.84255C-1.00266 10.1102 -0.898975 10.3681 -0.711051 10.5587C-0.523127 10.7493 -0.266645 10.8566 0.00100708 10.8566L0.00100708 9.85661ZM10.8421 0.0349554H11.8421C11.8421 -0.485116 11.4435 -0.918374 10.9252 -0.961587L10.8421 0.0349554ZM10.9461 1.04922C10.6349 1.01666 10.3192 1 10 1V3C10.2496 3 10.4958 3.01302 10.7381 3.03837L10.9461 1.04922ZM10 1C5.08322 1 1.08748 4.94253 1.00141 9.83903L3.0011 9.87418C3.06803 6.06644 6.17602 3 10 3V1ZM2.00126 8.85661H0.00100708V10.8566H2.00126V8.85661ZM10 -1C3.97745 -1 -0.914525 3.83983 -0.998894 9.84255L1.00091 9.87066C1.06993 4.9599 5.07261 1 10 1V-1ZM10.9252 -0.961587C10.62 -0.987037 10.3114 -1 10 -1V1C10.2558 1 10.509 1.01065 10.759 1.0315L10.9252 -0.961587ZM9.8421 0.0349554V2.04379H11.8421V0.0349554H9.8421Z'
        className='loader-segment'
        mask='url(#path-2-inside-1)'
      />
    </StyledSVG>
  );
});

const PrimaryCircularLoader = styled(CircularLoader)`
  & .loader-track {
    ${tw`fill-current text-gray-600 text-opacity-100!`}
    fill-opacity: 0.1;
  }
  & .loader-segment {
    ${tw`fill-current text-emerald-900 text-opacity-100!`}
  }
`;

export { CircularLoader, PrimaryCircularLoader };
