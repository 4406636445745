import { useOverlayType } from '@/components/AppOverlay';
import { useAskNeedlPreviewToggle } from '@/features/ChatBotV2/hooks/useAskNeedlPreviewToggle';
import { useShowAskNeedl } from '@/features/ChatBotV2/hooks/useShowAskNeedl';
import { useQueryParams } from '@/hooks/useQueryParams';

export const useShiftOverlay = () => {
  const { isAskNeedlPreviewOpen } = useAskNeedlPreviewToggle();
  const overlayType = useOverlayType();
  const showAskNeedl = useShowAskNeedl();
  const [_, queryParams] = useQueryParams();

  const isComments =
    queryParams['overlay-comments'] &&
    queryParams['overlay-comments'] === 'true';

  return (
    !isAskNeedlPreviewOpen &&
    !isComments &&
    showAskNeedl &&
    overlayType === 'preview'
  );
};
