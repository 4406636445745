import type { match } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

type MatchType = match<
  Partial<{
    category: string;
    source: string;
    docId: string;
  }>
> | null;

export const useLocationMatch = (): Record<string, MatchType> => {
  const anyTabMatch = useRouteMatch<{ source: string }>(
    '/:category/:source/:subcategory'
  );
  const chatsTabMatch = useRouteMatch<{ source: string }>(
    '/:category/:source/chats'
  );
  const sourcePreviewMatch = useRouteMatch<{ category: string }>(
    '/preview/:category/:deeplink'
  );
  const customFeedMatch = useRouteMatch('/custom-feed');
  const documentViewerMatch = useRouteMatch('/document');
  const imageViewerMatch = useRouteMatch('/image');
  const storageMatch = useRouteMatch('/integrations');
  const searchFeedMatch = useRouteMatch('/search');
  const notificationsFeedMatch = useRouteMatch('/notifications');
  const notebooksMatch = useRouteMatch('/notebooks');
  const feedsMatch = useRouteMatch('/feeds');
  const feedsHomeMatch = useRouteMatch('/feeds/home');
  const channelsMatch = useRouteMatch('/channels');
  const appsMatch = useRouteMatch('/apps');
  const connectMatch = useRouteMatch('/connect');

  return {
    chatsTabMatch,
    anyTabMatch,
    sourcePreviewMatch,
    customFeedMatch,
    documentViewerMatch,
    imageViewerMatch,
    storageMatch,
    searchFeedMatch,
    notificationsFeedMatch,
    notebooksMatch,
    feedsMatch,
    feedsHomeMatch,
    channelsMatch,
    appsMatch,
    connectMatch,
  };
};
