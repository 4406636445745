import * as React from 'react';
import type { QueryFunctionContext } from 'react-query';
import { useQuery, useQueryClient } from 'react-query';

import { UPLOAD_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';

const QUERY_KEY = ['upload_config'];

const fetcher = async ({ signal }: QueryFunctionContext) => {
  const response = await axiosInstance.get<QueryData>(
    UPLOAD_API_ROUTES.baseRoute(),
    { signal }
  );

  return response.data;
};

export const useUploadConfig = () => {
  return useQuery(QUERY_KEY, fetcher, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const usePrefetchUploadConfig = () => {
  const queryClient = useQueryClient();

  React.useEffect(() => {
    queryClient.prefetchQuery(QUERY_KEY, fetcher, {
      staleTime: Infinity,
      cacheTime: Infinity,
    });
  }, [queryClient]);
};

type QueryData = {
  /**
   * file size limit in MB
   */
  limit: number;
};
