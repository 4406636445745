import * as mixpanel from 'mixpanel-browser';
import { Userpilot } from 'userpilot';

import { shouldRunAnalytics } from './shouldRunAnalytics';
import { mixpanelCallback } from './utils/mixpanelCallback';

export const trackEvent = (
  event: string,
  properties?: mixpanel.Dict,
  optionsOrCallback?: mixpanel.Callback | mixpanel.RequestOptions
): void => {
  if (import.meta.env.DEV && !import.meta.env.TEST) {
    // eslint-disable-next-line no-console
    console.log('trackEvent', event, properties);
  }

  shouldRunAnalytics(() => {
    mixpanel.track(event, properties, optionsOrCallback, (response) =>
      mixpanelCallback(response, event)
    );

    Userpilot.track(event, properties);
  });
};
