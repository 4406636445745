// @ts-strict-ignore
import * as React from 'react';

import {
  OVERLAY_TYPES,
  useParseOverlayQueryParams,
} from '@/components/AppOverlay';
import {
  type PreviewParamsType,
  PreviewParamsYupSchema,
} from '@/components/Preview/OverlayPreview/constants';
import { useOverlayContext } from '@/context/OverlayContext';

import type { ParamsType } from './useFetchQueryParams';

export function useFetchOverlayParams(): ParamsType {
  const isOverlay = useOverlayContext();

  const overlayParams = useParseOverlayQueryParams() as PreviewParamsType;

  const validatedParams = React.useMemo(() => {
    if (isOverlay && overlayParams.type === OVERLAY_TYPES.Preview) {
      if (!overlayParams) {
        throw new Error('Overlay Params Schema not found!');
      }
      try {
        return PreviewParamsYupSchema.validateSync(overlayParams);
      } catch {
        throw new Error('Invalid Overlay params supplied!');
      }
    } else {
      return null;
    }
  }, [isOverlay, overlayParams]);

  const allOverlayParams = React.useMemo(
    () => ({
      type: validatedParams?.type ?? '',
      deeplink: validatedParams?.deeplink ?? '',
      board_id: validatedParams?.board_id ?? '',
      channel_id: validatedParams?.channel_id ?? '',
      global_channel_id: validatedParams?.global_channel_id ?? '',
      comments: validatedParams?.comments ?? '',
      comment_id: validatedParams?.comment_id ?? '',
      source:
        validatedParams?.category === 'links'
          ? 'links'
          : validatedParams?.source,
      category_tab: validatedParams?.category_tab ?? '',
    }),
    [validatedParams]
  );

  if (!isOverlay) {
    return null;
  }

  return allOverlayParams;
}
