import type { AxiosResponse } from 'axios';

import { useQueryParams } from '@/hooks/useQueryParams';
import { useSearchRoute } from '@/hooks/useSearchRoute';

import { useFetch } from './useFetch';

type SpellCorrectionResponse = AxiosResponse<{
  query_params: { corrected_q: string };
}>;

export const useSpellCorrection = () => {
  const searchRoute = useSearchRoute();
  const [query] = useQueryParams();

  return useFetch<SpellCorrectionResponse>({
    enabled: !!query.q && !!searchRoute,
    queryParams: {
      q: query.q,
      spell_correct: query.spell_correct,
      num_docs: 0,
      start_from: 0,
    },
  });
};
