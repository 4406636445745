import { useFetchAppParams } from './useFetchAppParams';
import { useFetchOverlayParams } from './useFetchOverlayParams';

export function useFetchQueryParams(): ParamsType {
  const overlayParams = useFetchOverlayParams();
  const appParams = useFetchAppParams();

  if (overlayParams) {
    return overlayParams;
  }

  if (appParams) {
    return appParams;
  }

  throw new Error('Invalid state, No Preview Params found!');
}

export type ParamsType = {
  board_id: string;
  channel_id: string;
  notebook_id?: string;
  isDefault?: boolean;
  type?: string;
  deeplink?: string;
  source?: string;
  global_channel_id?: string;
  comments?: string;
  comment_id?: string;
  redirect?: URL;
  category_tab?: string;
  subCategory?: string;
};
