import Skeleton from 'react-loading-skeleton';

import { IconContainer } from '@/components/Atoms/IconContainer/IconContainer';
import { DefaultContainer } from '@/features/ChatBotV2/components/MessageWrapper';
import type { MessageInfoType } from '@/features/ChatBotV2/hooks/useFetchAskNeedlMessaging';
import { getLogo } from '@/utils/getIcons';

export const PlaceholderCard = ({
  handleClick,
  message,
}: ClickableCardType) => {
  const Icon = getLogo(message.icon);

  return (
    <div
      data-feedcard
      role='presentation'
      onClick={handleClick}
      className='cursor-pointer'
    >
      <DefaultContainer className='p-4 space-y-3 w-48 min-h-[120px] hover:border-emerald-500 hover:bg-gray-200 shadow-sm'>
        <IconContainer className='w-4 h-4'>
          {message.icon ? <Icon /> : null}
        </IconContainer>
        {message.isLoading ? (
          <Skeleton />
        ) : (
          <div className='text-sm'>{message.question}</div>
        )}
      </DefaultContainer>
    </div>
  );
};

type ClickableCardType = {
  handleClick: () => void;
  message: MessageInfoType;
};
