import * as React from 'react';

export const HiddenInput = React.forwardRef<HTMLInputElement, HiddenInputProps>(
  (props, ref) => {
    return <input ref={ref} hidden {...props} />;
  }
);

type HiddenInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'hidden'
>;
