import axios from 'axios';

import { getIdToken } from './components/AuthProvider';
import { extractAppType } from './utils/appTypes';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 0,
});

/**
 * instance for sending logs without auth, no headers
 */
const axiosDebugInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 0,
});

/**
 * had to move it out
 * of React lifecycle, because
 * we have to make prefetch
 * requests before React lifecycle starts
 */
axiosInstance.interceptors.request.use(
  async (config) => {
    const jwt = await getIdToken();

    config.headers.token = jwt;
    config.headers['app-type'] = extractAppType();

    return config;
  },
  (error) => Promise.reject(error)
);

export { axiosInstance, axiosDebugInstance };
