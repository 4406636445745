import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { CHANNELS_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import type {
  ChannelMemberType,
  ChannelType,
} from '@/features/Channels/common';
import { useNewChannel } from '@/hooks/query/useChannel';

type ResponseType = {
  channel: ChannelType;
};

const QUERY_KEY = 'channel';
const ONE_MIN = 60 * 1000;

const fetchChannelInfo = (channelId: string, signal?: AbortSignal) => {
  return axiosInstance.get<ResponseType>(
    CHANNELS_API_ROUTES.channelInfo(channelId),
    { signal }
  );
};

export const useFetchChannel = (channelId: string) => {
  const isNewChannel = useNewChannel(channelId);

  // TODO: Change type here
  const placeholderData: ChannelMemberType[] = React.useMemo(
    () =>
      Array.from({ length: 7 }, (_, i) => i).map((i) => ({
        isLoading: true,
        user_id: `${i}`,
        role: '',
        username: '',
        email_id: '',
      })),
    []
  );

  const { data, isLoading, isError } = useQuery(
    [QUERY_KEY, channelId],
    ({ signal }) => fetchChannelInfo(channelId, signal),
    {
      enabled: !isNewChannel && !!channelId,
      retry: false,
      staleTime: ONE_MIN,
    }
  );

  const channel = data?.data?.channel;
  return { isLoading, isError, channel, placeholderData };
};

export const usePrefetchChannelInfo = () => {
  const queryClient = useQueryClient();

  return (channelId: string) => {
    queryClient.prefetchQuery(
      [QUERY_KEY, channelId],
      ({ signal }) => fetchChannelInfo(channelId, signal),
      { staleTime: ONE_MIN }
    );
  };
};

export const useInvalidateFetchChannel = () => {
  const queryClient = useQueryClient();

  return (channelId: string) => {
    queryClient.invalidateQueries([QUERY_KEY, channelId]);
  };
};
