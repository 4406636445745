import * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export type IconButtonType = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'css'
> & {
  /**
   * Button size
   */
  size?: 'large' | 'medium' | 'small' | 'xl' | 'xs';
  /**
   * is button color dark? (gray-700)
   */
  dark?: boolean;
  /**
   * Button disabled
   */
  disabled?: boolean;
  /**
   * should the button have no hover effect
   */
  disableHover?: boolean;
  /**
   * children
   */
  children?: React.ReactNode;
  /**
   * circular button
   */
  circular?: boolean;
  /**
   * has white background
   */
  background?: boolean;
  /**
   * button label (accessibility)
   */
  label?: string;
  /**
   * should the SVG be filled with current color
   */
  fill?: boolean;
  /**
   * background on hover
   */
  hoverBg?: boolean;
};

type StyledButtonType = Pick<
  IconButtonType,
  'background' | 'circular' | 'disabled' | 'disableHover' | 'hoverBg' | 'size'
>;

const IconContainer = styled.span<
  Pick<IconButtonType, 'dark' | 'disabled' | 'fill' | 'size'>
>`
  ${({ disabled }) => (disabled ? tw`text-gray-400` : tw`text-gray-500`)}
  ${({ dark, disabled }) =>
    dark && (disabled ? tw`text-gray-500` : tw`text-gray-700`)}
  & svg,
  & img {
    display: block;
    ${({ fill }) => fill && tw`fill-current`}
    ${({ size }) =>
      size === 'xs'
        ? tw`w-4 h-4`
        : size === 'small'
        ? tw`w-5 h-5`
        : size === 'xl'
        ? tw`w-9 h-9`
        : tw`w-6 h-6`}
  }
`;

const StyledButton = styled.button<StyledButtonType>(
  ({ disabled, size, circular, background, disableHover, hoverBg }) => [
    tw`flex items-center ring-emerald-900`,
    !disabled &&
      tw`hover:bg-gray-200 active:bg-gray-300 focus-visible:outline-none focus-visible:bg-gray-200 focus-visible:ring-2`,
    circular ? tw`rounded-full` : tw`rounded-lg`,
    size === 'large' ? tw`p-2` : tw`p-1.5`,
    disabled &&
      tw`cursor-not-allowed disabled:outline-none disabled:ring-0 disabled:opacity-75`,
    background && tw`bg-white`,
    !background &&
      !disableHover &&
      tw`transition transform-gpu duration-150 ease-in`,
    !hoverBg &&
      tw`hover:bg-transparent active:bg-transparent focus-visible:outline-none focus-visible:bg-transparent focus-visible:ring-2`,
  ]
);

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonType>(
  (
    {
      children,
      size = 'medium',
      disabled,
      disableHover = false,
      className = '',
      style = {},
      circular = true,
      background = false,
      label,
      dark = true,
      fill = true,
      hoverBg = true,
      ...rest
    },
    ref
  ) => {
    return (
      <StyledButton
        disabled={disabled}
        size={size}
        ref={ref}
        className={className}
        style={style}
        circular={circular}
        background={background}
        disableHover={disableHover}
        hoverBg={hoverBg}
        {...(label && { 'aria-label': label })}
        {...(disabled && { 'aria-disabled': true })}
        {...rest}
      >
        <IconContainer
          data-icon-container
          tabIndex={-1}
          dark={dark}
          size={size}
          fill={fill}
          disabled={disabled}
          aria-hidden
        >
          {children}
        </IconContainer>
      </StyledButton>
    );
  }
);
