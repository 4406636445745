import * as React from 'react';

import { getPlatform } from '@/utils/platform';

export const useDevices = () => {
  const platform = getPlatform();
  const RN_PLATFORM = ['android', 'ios'].includes(window.RN_PLATFORM ?? '');

  const isIOS = ['iphone', 'ipad', 'ipod'].includes(platform.toLowerCase()); // Mac iOs

  const phoneOrTablet =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      platform
    );

  const mobileDevice = isIOS || phoneOrTablet || RN_PLATFORM;

  return React.useMemo(
    () => ({
      isIOS,
      phoneOrTablet,
      mobileDevice,
      native: RN_PLATFORM,
      platform: window.RN_PLATFORM,
    }),
    [RN_PLATFORM, isIOS, mobileDevice, phoneOrTablet]
  );
};
