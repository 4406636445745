import { Alert as ReachAlert } from '@reach/alert';
import type * as React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { Button } from '@/components/Button/Button';
import { IconButton } from '@/components/IconButton/IconButton';
import WarningIcon from '@/icons/alert.svg?react';
import AlertInfoIcon from '@/icons/alert_info.svg?react';
import CloseIcon from '@/icons/close-lg.svg?react';
import TickIcon from '@/icons/tick.svg?react';

export type AlertTypes = 'default' | 'icon';
export type AlertVariants = 'default' | 'error' | 'info' | 'success' | 'warn';

export type AlertType = Omit<React.HTMLAttributes<HTMLDivElement>, 'css'> & {
  /**
   * Alert type
   */
  alertType: AlertTypes;
  dismissHandler?: () => void;
  primaryButtonLabel?: string;
  primaryButtonHandler?: () => void;
  /**
   * variant type
   */
  variant: AlertVariants;
};

const AlertContainer = styled(ReachAlert)<Pick<AlertType, 'variant'>>(
  ({ variant }) => [
    tw`px-3 py-2`,

    tw`bg-gray-300 border rounded text-gray-700`,

    variant === 'success' && tw`bg-green-100 border-green-900 text-emerald-900`,
    variant === 'info' && tw`bg-blue-100 border-blue-900 text-blue-900`,
    variant === 'warn' &&
      tw`bg-yellow-100 text-secondary-yellow-900 border-yellow-900 `,
    variant === 'error' && tw`bg-red-100 border-red-900 text-secondary-red-900`,
  ]
);

const IconContainer = styled.div<Pick<AlertType, 'variant'>>`
  ${tw`p-2 rounded-full`}
  ${({ variant }) => variant === 'default' && tw`bg-gray-400`}
  ${({ variant }) => variant === 'error' && tw`bg-red-300`}
  ${({ variant }) => variant === 'info' && tw`bg-blue-300`}
  ${({ variant }) => variant === 'success' && tw`bg-green-300`}
  ${({ variant }) => variant === 'warn' && tw`bg-yellow-300`}
`;

const Icon = styled.div<Pick<AlertType, 'variant'>>`
  ${tw`w-5 h-5 fill-current`}

  ${({ variant }) => variant === 'default' && tw`text-gray-700`}
  ${({ variant }) => variant === 'error' && tw`text-secondary-red-900`}
  ${({ variant }) => variant === 'info' && tw`text-secondary-blue-900`}
  ${({ variant }) => variant === 'success' && tw`text-emerald-900`}
  ${({ variant }) => variant === 'warn' && tw`text-secondary-yellow-900`}

  svg {
    ${tw`w-full h-full`}
  }
`;

const StyledCloseIcon = styled(CloseIcon)<Pick<AlertType, 'variant'>>`
  ${({ variant }) => variant === 'default' && tw`text-gray-700`}
  ${({ variant }) => variant === 'error' && tw`text-secondary-red-900`}
  ${({ variant }) => variant === 'info' && tw`text-secondary-blue-900`}
  ${({ variant }) => variant === 'success' && tw`text-emerald-900`}
  ${({ variant }) => variant === 'warn' && tw`text-secondary-yellow-900`}
`;

const getIcon = (variant: AlertVariants) => {
  switch (variant) {
    case 'default':
    case 'error':
    case 'info':
      return <AlertInfoIcon />;
    case 'warn':
      return <WarningIcon />;
    case 'success':
      return <TickIcon />;
  }
};

export const Alert: React.FunctionComponent<AlertType> = ({
  alertType,
  children,
  dismissHandler,
  primaryButtonLabel,
  primaryButtonHandler,
  variant,
  ...rest
}) => {
  return (
    <AlertContainer variant={variant} {...rest}>
      <div className='flex gap-x-2 items-center'>
        {alertType !== 'default' ? (
          <IconContainer variant={variant}>
            <Icon variant={variant}>{getIcon(variant)}</Icon>
          </IconContainer>
        ) : null}
        <span className='flex-grow'>{children}</span>
        {dismissHandler ? (
          <IconButton
            size='small'
            onClick={dismissHandler}
            className={primaryButtonHandler ? 'self-start' : ''}
          >
            <StyledCloseIcon variant={variant} />
          </IconButton>
        ) : null}
      </div>
      {primaryButtonHandler ? (
        <div className='mt-1 px-1'>
          <Button
            onClick={primaryButtonHandler}
            className='bg-white ml-auto'
            size='small'
            variant='secondary'
          >
            {primaryButtonLabel}
          </Button>
        </div>
      ) : null}
    </AlertContainer>
  );
};
