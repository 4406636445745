import appDetails from './appDetails.json';
import { capitalize } from './capitalize';

const isKey = (key: string): key is keyof typeof appDetails =>
  key in appDetails;

const parse = (label: string): string => {
  const title = label.replace(/[_.]/gi, ' ');
  return capitalize(title);
};

export const getAppTitle = (source: string, label?: string): string => {
  if (isKey(source)) {
    return appDetails[source].title;
  }
  return parse(label || source);
};

export const getAppNames = () => {
  const appNames: Record<string, string> = {};
  for (const app in appDetails) {
    if (isKey(app)) {
      appNames[app] = appDetails[app].title;
    }
  }
  return appNames;
};

export const getAppGroupTitle = (source: string) => {
  if (isKey(source)) {
    return appDetails[source].group;
  }
  return '';
};
