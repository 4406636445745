import { ErrorBoundary } from 'react-error-boundary';

import { ErrorBoundary as ErrorFallback } from '@/components/ErrorBoundaries/ErrorBoundary';
import { type OverlaySize, Overlay } from '@/components/Overlay';
import { OverlayPreview } from '@/components/Preview/OverlayPreview';
import { ChatBotV2 } from '@/features/ChatBotV2';
import { CustomFeed } from '@/features/CustomFeedV2';
import { PortfolioOverlay } from '@/features/PortfolioManager/Components/PortfolioOverlay';

import { type OverlayType, OVERLAY_TYPES } from './constants';
import { useOverlayState, useOverlayType } from './hooks';

export const AppOverlay = () => {
  const { showOverlay, closeOverlay } = useOverlayState();

  const overlayType = useOverlayType();

  const { content, size, hideCloseButton } = getContentDetails({
    overlayType,
  });

  return (
    <Overlay
      showDialog={showOverlay}
      onDismiss={closeOverlay}
      size={size}
      hideCloseButton={hideCloseButton}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>{content}</ErrorBoundary>
    </Overlay>
  );
};

const getContentDetails = ({ overlayType }: GetContentDetails) => {
  switch (overlayType) {
    case OVERLAY_TYPES.CustomFeed:
      return {
        content: <CustomFeed />,
        size: 'medium' as OverlaySize,
      };
    case OVERLAY_TYPES.Preview:
      return {
        content: <OverlayPreview />,
        size: 'variable' as OverlaySize,
      };
    case OVERLAY_TYPES.AskNeedl:
      return {
        content: <ChatBotV2 />,
        size: 'variable' as OverlaySize,
        hideCloseButton: true,
      };
    case OVERLAY_TYPES.Portfolio:
      return {
        content: <PortfolioOverlay />,
      };
    default:
      console.error('Unknown overlay type');
      return {
        content: null,
        size: 'medium' as OverlaySize,
      };
  }
};

type GetContentDetails = {
  overlayType: OverlayType | null;
};
