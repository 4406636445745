import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorBoundary as Error } from '@/components/ErrorBoundaries/ErrorBoundary';

const PreviewImpl = React.lazy(() => import('./OverlayPreview'));

export const OverlayPreview = () => {
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <React.Suspense fallback={null}>
        <PreviewImpl />
      </React.Suspense>
    </ErrorBoundary>
  );
};
