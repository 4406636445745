import type { Value } from '@udecode/plate';
import { useMutation } from 'react-query';

import { axiosInstance } from '@/axios';
import type { FormattedData } from '@/components/ChatBot/utils';
import type { CreateNotebookResponseType } from '@/features/Notes/hooks/useCreateNotebook';
import { useCreateNotes } from '@/features/Notes/hooks/useCreateNotes';
import { useRefetchAllNotebooks } from '@/features/Notes/hooks/useFetchNotebooks';

export const useSnipeNote = () => {
  const createNoteMutation = useCreateNotes();
  const refetchAllNotebooks = useRefetchAllNotebooks();

  return useMutation(
    async ({ promt, conversation }: MutationData) => {
      const data = await axiosInstance.put<CreateNotebookResponseType>(
        '/notebook',
        { name: 'bot_placeholder' },
        { params: { botNotebook: true } }
      );

      const notebookId = data?.data?.results?.notebookId;

      return createNoteMutation.mutateAsync({
        title: promt,
        notebooks: [{ notebook_id: notebookId }],
        document: JSON.stringify(generatePlateDocument(conversation)),
      });
    },
    {
      onSettled: async () => {
        await refetchAllNotebooks();
      },
    }
  );
};

export const generatePlateDocument = (conversation: FormattedData[]): Value => {
  return conversation
    .map(
      ({ answer, citations, prompt }, i, arr) =>
        [
          { children: [{ text: prompt, bold: true }], type: 'h2' },
          { children: [{ text: '' }], type: 'p' },
          { children: [{ text: answer }], type: 'p' },
          { children: [{ text: '' }], type: 'p' },
          ...(citations.length > 0
            ? [
                { children: [{ text: 'Citations:' }], type: 'p' },
                ...citations.map((citation) => {
                  const [number, text] = citation.split(':: ');

                  let isURL = false;
                  try {
                    const url = new URL(text);
                    if (url.protocol === 'http:' || url.protocol === 'https:') {
                      isURL = true;
                    }
                  } catch (_error) {
                    isURL = false;
                  }

                  let citationText: { text: string }[] | Value;

                  if (isURL) {
                    citationText = [
                      { type: 'a', url: text, children: [{ text }] },
                    ];
                  } else {
                    citationText = [{ text }];
                  }

                  return {
                    children: [
                      { text: `${number}: ` },
                      ...citationText,
                      { text: '' },
                    ],
                    type: 'p',
                  };
                }),
              ]
            : []),
          ...(i !== arr.length - 1
            ? [{ children: [{ text: '' }], type: 'p' }]
            : []),
        ] as Value
    )
    .reduce((previous, current) => [...previous, ...current], []);
};

type MutationData = {
  conversation: FormattedData[];
  promt: string;
};
