import { Slide } from '@material-ui/core';
import React, { forwardRef } from 'react';

export const Transition = forwardRef(function Transition(
  // eslint-disable-next-line react/prop-types
  { direction, ...props },
  ref
) {
  return <Slide direction={direction} ref={ref} {...props} />;
});
