export const attachOverlayParams = (
  newQueryParams: string,
  oldQueryParams: string
) => {
  const newQueryParamsObj = new URLSearchParams(newQueryParams);
  const oldQueryParamsObj = new URLSearchParams(oldQueryParams);

  for (const [key, value] of oldQueryParamsObj.entries()) {
    if (key.startsWith('overlay-') && !newQueryParamsObj.has(key)) {
      newQueryParamsObj.set(key, value);
    }
  }

  return newQueryParamsObj.toString();
};
