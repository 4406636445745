import tw, { styled } from 'twin.macro';

import { useScrollIntoView } from '@/components/ChatBot/hooks';

export const MessageWrapper = ({
  avatar,
  prompt,
  variant = 'default',
}: MessageWrapperType) => {
  const Wrapper = variant === 'bot' ? BotContainer : DefaultContainer;
  return (
    <div
      className={`flex gap-x-2 items-start w-full
     ${variant === 'bot' ? 'flex-row' : 'flex-row-reverse'}
    `}
      ref={useScrollIntoView()}
    >
      <div className='flex-shrink-0'>{avatar}</div>
      {prompt ? (
        <Wrapper role='listitem'>
          <div className='w-full h-full px-4 py-2 text-sm'>{prompt}</div>
        </Wrapper>
      ) : null}
    </div>
  );
};

const BotContainer = styled.div`
  ${tw`bg-emerald-100 rounded-2xl`}
`;

export const DefaultContainer = styled.div`
  ${tw`border border-gray-300 bg-white rounded-2xl`}
`;

type MessageWrapperType = {
  avatar: React.ReactNode;
  prompt: Element | React.ReactNode | string;
  variant?: 'bot' | 'default';
};
