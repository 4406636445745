import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import styled from 'styled-components';
import tw from 'twin.macro';

import { trackEvent } from '@/analytics/trackEvent';
import { ErrorBoundary as Error } from '@/components/ErrorBoundaries/ErrorBoundary';
import { Modal } from '@/components/Modal/Modal';
import { PortalProvider } from '@/context/PortalContext';
import { useFeatureFlag } from '@/hooks/query/useFeatureFlag';
import { useFeedRouteExact } from '@/hooks/useFeedRoute';
import { useGetConnectPageHeader } from '@/hooks/useGetConnectHeader';
import { useGetSettingsHeader } from '@/hooks/useGetSettingsHeader';
import { useGetParams, useGetSourceFromId } from '@/hooks/useGetSourceFromId';
import { useSettingsRouteExact } from '@/hooks/useSettingsRoute';
import NeedlBotIcon from '@/icons/needl-bot.svg?react';
import { useNavigate } from '@/router';

import BETA from './beta.svg?react';
import { Container } from './components';
import { useCategories, useFeedCategories } from './hooks';

export const ChatBot = ({ mobile }: ChatBotType) => {
  const popperRef = React.useRef<HTMLButtonElement>(null);
  const [showModal, setShowModal] = React.useState(() => {
    const search = new URLSearchParams(window.location.search);
    return search.has('ask-needl');
  });

  const navigate = useNavigate();
  const stableNavigate = React.useRef(navigate);

  const { data: features, isLoading: featuresLoading } = useFeatureFlag();

  const { subCategory } = useGetParams();

  const { isLoading: isCategoriesLoading } = useCategories();
  const { isLoading: isFeedCategoriesLoading } = useFeedCategories();

  const isFeedRoute = useFeedRouteExact();
  const isSettingsRoute = useSettingsRouteExact();
  const { name } = useGetSourceFromId();
  const settingsHeader = useGetSettingsHeader();
  const connectPageHeader = useGetConnectPageHeader();

  React.useEffect(() => {
    stableNavigate.current = navigate;
  }, [navigate]);

  React.useEffect(() => {
    import('./components/Container/Container');
  }, []);

  React.useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    if (search.has('ask-needl') && (isFeedRoute || isSettingsRoute)) {
      search.delete('ask-needl');
      stableNavigate.current.replace(
        {
          pathname: window.location.pathname,
          search: search.toString(),
        },
        null
      );
    }
  }, [isFeedRoute, isSettingsRoute]);

  const handlePopperClick = () => {
    if (settingsHeader || connectPageHeader) {
      trackEvent('ask_needl_clicked', {
        origin: settingsHeader || connectPageHeader,
      });
    } else {
      trackEvent('ask_needl_clicked', {
        tabname: subCategory,
        feedname: name || 'home',
      });
    }
    setShowModal((prev) => !prev);
  };

  const handleClose = () => {
    setShowModal(false);
    trackEvent('ask_needl_closed');
  };

  if (featuresLoading || (features && !features.is_chat_bot)) {
    return null;
  }

  return (
    <>
      <Button
        id='ask-needl-button'
        disabled={isCategoriesLoading || isFeedCategoriesLoading}
        ref={popperRef}
        onClick={handlePopperClick}
        mobile={mobile}
      >
        <BetaContainer>
          <BETA id='ask-needl-beta' />
        </BetaContainer>
        {!mobile ? <Highlight /> : null}
        {mobile ? <NeedlBotIcon /> : null}
        {!mobile ? <span>Ask Needl</span> : null}
      </Button>
      <Modal
        showDialog={showModal && !isCategoriesLoading ? true : false}
        closeOnOutsideClick
        onDismiss={handleClose}
        ariaLabel='Ask Needl Chat Bot'
        size='xl'
      >
        <PortalProvider>
          <ErrorBoundary FallbackComponent={Error}>
            <React.Suspense
              fallback={<div className='bg-gray-100 h-[80vh] rounded-md' />}
            >
              <Container handleClose={handleClose} />
            </React.Suspense>
          </ErrorBoundary>
        </PortalProvider>
      </Modal>
    </>
  );
};

const Highlight = () => {
  return (
    <svg
      viewBox='0 0 13 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='absolute -top-4 -left-4 overflow-visible'
    >
      <g className='animate-highlight-wave rotate-[15deg] origin-[14px_14px]'>
        <path
          d='M2.13672 5.73633L5.36016 7.7635'
          stroke='#367D8D'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <path
          d='M1 16.449L4.76897 15.906'
          stroke='#367D8D'
          strokeLinecap='round'
        />
        <path
          d='M11.1016 0.968262L11.1348 4.776'
          stroke='#367D8D'
          strokeLinecap='round'
        />
      </g>
    </svg>
  );
};

const BetaContainer = styled.span`
  ${tw`sm:absolute sm:-top-2 sm:-right-4`}
`;

const Button = styled.button<Pick<ChatBotType, 'mobile'>>`
  ${tw`relative flex-shrink-0 flex gap-1 sm:gap-0 items-center sub-heading-xs-bold text-emerald-900 disabled:opacity-75 transition duration-150 ease-out`}
  ${({ mobile }) =>
    mobile
      ? tw`p-1.5 rounded-full hover:bg-gray-200`
      : tw`rounded pl-3 pr-4 py-1.5 ring-2 ring-emerald-900 gap-2 bg-emerald-100`}

  svg:not([id='ask-needl-beta']) {
    ${({ mobile }) => (mobile ? tw`w-6 h-6` : tw`w-5 h-5`)}
  }

  span {
    ${tw`block flex-shrink-0`}
  }
`;

type ChatBotType = { mobile?: boolean };
