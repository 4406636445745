import * as React from 'react';

import { useFetchPublicApps } from './useFetchPublicApps';

export const useGetPublicSource = () => {
  const { data, isError, isLoading } = useFetchPublicApps();
  const getPublicSource = React.useCallback(
    (sourceId: string) => {
      if (isError || isLoading || !sourceId) {
        return { key: '', label: '' };
      }
      return data?.find(({ key }) => key === sourceId);
    },
    [data, isError, isLoading]
  );
  return { getPublicSource };
};
