import * as yup from 'yup';

type PreviewParamsType = {
  type: 'preview';
  source: string;
  category: string;
  deeplink: string;
  access_key?: string;
  comments?: string;
  comment_id?: string;
  board_id?: string;
  channel_id?: string;
  global_channel_id?: string;
  category_tab?: string;
};

const PreviewParamsYupSchema = yup.object<PreviewParamsType>().shape({
  type: yup.string().oneOf(['preview']).required(),
  source: yup.string().required(),
  category: yup.string().required(),
  deeplink: yup.string().required(),
  access_key: yup.string().optional(),
  comments: yup.string().optional(),
  comment_id: yup.string().optional(),
  board_id: yup.string().optional(),
  channel_id: yup.string().optional(),
  global_channel_id: yup.string().optional(),
  category_tab: yup.string().optional(),
  // new params will be added here
});

export { PreviewParamsYupSchema };
export type { PreviewParamsType };
