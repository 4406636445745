import { motion } from 'framer-motion';
import type * as React from 'react';
import { styled } from 'twin.macro';

import type { AnimatedNavItemType } from '@/components/Atoms/AnimatedNavLink/common';
import {
  ItemStyles,
  NumberOfTabResult,
  SelectedIndicator,
} from '@/components/Atoms/AnimatedNavLink/common';

export type AnimatedNavButtonType = AnimatedNavItemType &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const StyledButton = styled.button<AnimatedNavButtonType>(ItemStyles);

const AnimatedIndicator = motion(SelectedIndicator);

export const AnimatedNavButton: React.FunctionComponent<
  AnimatedNavButtonType
> = ({
  children,
  selected,
  count,
  disabled = false,
  textDark = true,
  replace = false,
  variant,
  ...rest
}) => {
  return (
    <StyledButton
      replace={replace}
      selected={selected}
      textDark={textDark}
      disabled={disabled}
      variant={variant}
      {...rest}
    >
      {children}
      {count ? <NumberOfTabResult>{count}</NumberOfTabResult> : null}
      {selected ? (
        <AnimatedIndicator
          transition={{ type: 'spring', damping: 50, stiffness: 600, mass: 2 }}
          layoutId='nav-selected'
        />
      ) : null}
    </StyledButton>
  );
};
