import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { PageTitle } from '@/components/Atoms/PageTitle/PageTitle';
import { Spinner } from '@/components/Atoms/Spinner';
import { ErrorBoundary as Error } from '@/components/ErrorBoundaries/ErrorBoundary';

const PortfolioInfo = React.lazy(
  () => import('../Pages/PortfolioDetails/PortfolioInfo')
);

export const PortfolioOverlay = () => {
  return (
    <ErrorBoundary FallbackComponent={Error}>
      <React.Suspense fallback={<Spinner />}>
        <div className='m-6 gap-6'>
          <PageTitle>Create Portfolio</PageTitle>
          <div className='m-6'>
            <PortfolioInfo />
          </div>
        </div>
      </React.Suspense>
    </ErrorBoundary>
  );
};
