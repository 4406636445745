import * as React from 'react';

type PortalContextType = [
  HTMLElement | null,
  React.Dispatch<React.SetStateAction<HTMLElement | null>>
];

const PortalContext = React.createContext<PortalContextType | null>(null);

export const PortalProvider: React.FunctionComponent = ({ children }) => {
  const [element, setElement] = React.useState<HTMLElement | null>(null);

  return (
    <PortalContext.Provider value={[element, setElement]}>
      {children}
    </PortalContext.Provider>
  );
};

export const usePortal = () => {
  const value = React.useContext(PortalContext);

  if (!value) {
    throw new Error('usePortal must be used within a PortalProvider');
  }

  return value;
};
