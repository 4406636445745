import * as React from 'react';

import type { CustomFeedNavDataType } from '@/types';

import { useFetchFeeds } from './query/useFetchFeeds';
import { useGetSearchId } from './useGetSearchId';

type ReturnType = {
  getBoard: (boardId: string) => CustomFeedNavDataType;
  getBoardFromFeedName: (boardName: string) => CustomFeedNavDataType;
};

export const useGetBoard = (): ReturnType => {
  const { data: feeds, isError, isLoading } = useFetchFeeds();

  const getBoard = React.useCallback(
    (boardId: string): CustomFeedNavDataType => {
      if (isError || isLoading) {
        return { board_id: '', board_name: '' };
      }

      return (
        feeds?.find(({ board_id }) => board_id === boardId) ?? {
          board_id: '',
          board_name: '',
        }
      );
    },
    [feeds, isError, isLoading]
  );

  const getBoardFromFeedName = React.useCallback(
    (boardName: string): CustomFeedNavDataType => {
      if (isError || isLoading) {
        return { board_id: '', board_name: '' };
      }

      return (
        feeds?.find(({ board_name }) => board_name === boardName) ?? {
          board_id: '',
          board_name: '',
        }
      );
    },
    [feeds, isError, isLoading]
  );

  return { getBoard, getBoardFromFeedName };
};

export const useBoard = (): CustomFeedNavDataType => {
  const { data: feeds, isError, isLoading } = useFetchFeeds();
  const { board_id: boardId } = useGetSearchId(); // group feed

  return React.useMemo(() => {
    if (isError || isLoading || !boardId) {
      return { board_id: '', board_name: '' };
    }
    return (
      feeds?.find(({ board_id }) => board_id === boardId) ?? {
        board_id: '',
        board_name: '',
      }
    );
  }, [boardId, feeds, isError, isLoading]);
};

export const useHomeBoard = (): CustomFeedNavDataType => {
  const { getBoardFromFeedName } = useGetBoard();

  return getBoardFromFeedName('home');
};
