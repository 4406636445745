import {
  useAppFeedMatch,
  useChannelFeedMatch,
  useHomeFeedMatch,
  useNeedlBoxFeedMatch,
  useUserFeedMatch,
} from '@/components/AppHeader/hooks/useCurrentFeedOption';
import { usePreviewDocumentMatch } from '@/hooks/usePreviewDocumentMatch';

export const useBoardFromURL = (): UseSearchFromURLReturn => {
  const appPagesMatch = useAppFeedMatch();
  const feedPagesMatch = useUserFeedMatch();
  const channelPagesMatch = useChannelFeedMatch();
  const needlBoxPagesMatch = useNeedlBoxFeedMatch();
  const homeFeedPageMatch = useHomeFeedMatch();
  const previewPageDocumentMatch = usePreviewDocumentMatch();

  if (homeFeedPageMatch || needlBoxPagesMatch || appPagesMatch) {
    return {
      board_id: 'home',
      source: appPagesMatch?.params.source || needlBoxPagesMatch?.params.source,
    };
  }

  if (feedPagesMatch) {
    return {
      board_id: feedPagesMatch.params.source,
    };
  }

  if (channelPagesMatch) {
    return {
      channel_id: channelPagesMatch.params.source,
    };
  }

  if (previewPageDocumentMatch) {
    return {
      board_id: previewPageDocumentMatch.board_id,
      channel_id: previewPageDocumentMatch.channel_id,
    };
  }

  return null;
};

type UseSearchFromURLReturn = {
  board_id?: string;
  channel_id?: string;
  source?: string;
} | null;
