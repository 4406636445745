import { CircularProgress } from '@material-ui/core';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

const CommonStyle = css`
  ${tw`flex justify-center items-center`}
`;

const SpinnerContainer = styled.div`
  ${CommonStyle}
`;

const FullScreenContainer = styled.div`
  ${CommonStyle}
  ${tw`h-screen`}
`;

const ModalSpinnerContainer = styled.div`
  ${CommonStyle}
  ${tw`h-40`}
`;

export const Spinner = (): JSX.Element => (
  <SpinnerContainer>
    <CircularProgress />
  </SpinnerContainer>
);

export const FullScreenSpinner = (): JSX.Element => (
  <FullScreenContainer>
    <CircularProgress />
  </FullScreenContainer>
);

export const ModalSpinner = (): JSX.Element => (
  <ModalSpinnerContainer>
    <CircularProgress />
  </ModalSpinnerContainer>
);
