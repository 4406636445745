import { useMutation } from 'react-query';

import { NOTE_API_ROUTES } from '@/api-routes';
import { axiosInstance } from '@/axios';
import type { Notebook } from '@/features/Notes/types';

export const useCreateNotes = () => {
  return useMutation(({ notebooks, document, title }: MutationData) => {
    return Promise.all(
      notebooks.map(async ({ notebook_id }) => {
        const note = await axiosInstance.put<CreateNoteResponse>(
          NOTE_API_ROUTES.createNote(notebook_id),
          { name: title }
        );
        const noteId = note.data.results.id;

        return axiosInstance.post(NOTE_API_ROUTES.noteSnip(noteId), {
          document,
        });
      })
    );
  });
};

type MutationData = {
  notebooks: Pick<Notebook, 'notebook_id'>[];
  title: string;
  document: string;
};

export type CreateNoteResponse = { results: { id: string } };
