export const getCombinedQueryParams = (
  oldQueryParams: string,
  newQueryParams: string
) => {
  const oldQueryParamsObj = new URLSearchParams(oldQueryParams);
  const newQueryParamsObj = new URLSearchParams(newQueryParams);

  for (const [key, value] of newQueryParamsObj.entries()) {
    if (!value || value === 'undefined' || value === 'null') {
      oldQueryParamsObj.delete(key);
    } else {
      oldQueryParamsObj.set(key, value);
    }
  }

  return oldQueryParamsObj.toString();
};
